import { useSearchParams } from 'react-router-dom'
import { useGetAllUsers } from 'usersV2/api/useQueries'

export default function UserList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const toggleEditModal = (id?: number) => {
        if (!id) {
            searchParams.delete('user_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('user_id', id.toString())
        return setSearchParams(searchParams)
    }
    const usersQuery = useGetAllUsers({})
    const users: any[] = usersQuery.data?.users || []

    return (
        <div className='bg-bg1 dark:bg-darkbg1 shadow-md border border-lightgrey dark:border-darkgrey rounded text-sm leading-none'>
            <div className='grid grid-cols-[1fr_2fr_1fr_1fr_1fr_2fr_1fr] p-2 gap-2 bg-accent1 text-white dark:bg-darkbg2 text-offwhite font-bold rounded-t'>
                <p>ID</p>
                <p>Email</p>
                <p>First Name</p>
                <p>Last Name</p>
                <p>Type</p>
                <p>Roles</p>
                <p>Created</p>
            </div>
            {users.map((user) => (
                <div
                    key={`user-${user.id}`}
                    onClick={() => toggleEditModal(user.id)}
                    className='cursor-pointer grid grid-cols-[1fr_2fr_1fr_1fr_1fr_2fr_1fr] p-2 gap-2 border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark"hover:bg-darkbg2'
                >
                    <p>{user.id}</p>
                    <p>{user.email}</p>
                    <p>{user.first_name}</p>
                    <p>{user.last_name}</p>
                    <p>{user.type}</p>
                    <p className='capitalize'>{JSON.parse(user.roles).join(', ')}</p>
                    <p>{user.created_at}</p>
                </div>
            ))}
        </div>
    )
}
