import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn, useDebounce } from 'helpers'
import FilterToolBar from 'procurement/components/FilterToolBar'
import { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useGetTaxExemptions } from 'taxExemptions/api/useQueries'
import {
    caRegionalTaxExemptionOptions,
    caTaxExemptionOptions,
    usTaxExemptionOptions,
} from 'taxExemptions/components/TaxExemptionSelector'
import { formatReadableExemption } from 'taxExemptions/helpers'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}).format

export default function TaxExemptionList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const searchParam = searchParams.get('search') || ''
    const page = searchParams.get('page') || 1
    const statusesString = searchParams.get('statuses') || ''
    const exemptionsString = searchParams.get('exemptions') || ''
    const hasNoFormsString = searchParams.get('has_no_forms') || ''
    const [initRender, setInitRender] = useState(true)
    const [search, setSearch] = useState(searchParam)
    const debouncedSearch = useDebounce(search.trim(), 400)

    const statusOptions = [
        { label: 'Accepted', value: 'Accepted' },
        { label: 'Initialized', value: 'Initialized' },
        { label: 'Under Review', value: 'Under_Review' },
        { label: 'Waiting For Customer', value: 'Waiting_For_Customer' },
        { label: 'Rejected', value: 'Rejected' },
    ]
    const exemptionTypeOptions = [...usTaxExemptionOptions, ...caRegionalTaxExemptionOptions, ...caTaxExemptionOptions]

    const statuses = decodeURIComponent(statusesString)
        .split(',')
        .filter((v) => v)
    const exemptions = decodeURIComponent(exemptionsString)
        .split(',')
        .filter((v) => v)
    const has_forms = !hasNoFormsString || decodeURIComponent(hasNoFormsString) === 'false' ? true : false

    const taxExemptionQuery = useGetTaxExemptions({
        filters: { statuses, exemptions, has_forms, page },
        search: debouncedSearch,
    })
    const taxExemptions = taxExemptionQuery.data?.taxExemptions || []

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: statusOptions?.map((option) => {
                    return {
                        value: option.value,
                        label: option.label,
                        icon: undefined,
                    }
                }),
                title: 'Statuses',
                field: 'statuses',
                values: statuses,
                searchToggle: false,
                editSearchParams: true,
            },
            {
                component: FilterBarSelectorButton,
                options: exemptionTypeOptions?.map((option) => {
                    return {
                        value: option.value,
                        label: option.label,
                        icon: undefined,
                    }
                }),
                title: 'Exemptions',
                field: 'exemptions',
                values: exemptions,
                searchToggle: true,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('statuses')
                prev.delete('exemptions')
                prev.set('page', '1')
                return prev
            }),
    }

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
            setSearchParams((prev: any) => {
                prev.set('page', 1)
                return prev
            })
        }
    }, [debouncedSearch])
    useEffect(() => {
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    function handlePageIncrease() {
        if (taxExemptions?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', '1')
                return prev
            })
        }
    }
    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('search')
            prev.set('page', '1')
            return prev
        })
        setSearch('')
    }

    return (
        <div className='rounded border border-lightgrey dark:border-darkgrey text-sm'>
            <div className='sticky top-[50px] bg-bg1 dark:bg-darkbg1'>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    handleClearSearch={handleClearSearch}
                    filterBarObject={companyFilterBarObject}
                >
                    <FetchingSpinner isFetching={taxExemptionQuery.isPending} />
                    <Button
                        className={cn(has_forms && 'border-accent2')}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => {
                            setSearchParams((prev: URLSearchParams) => {
                                has_forms ? prev.set('has_no_forms', 'true') : prev.delete('has_no_forms')
                                prev.set('page', '1')
                                return prev
                            })
                        }}
                    >
                        Forms Submitted
                    </Button>
                    {companyFilterBarObject.params.length &&
                        companyFilterBarObject.params.map((param: any) => {
                            return (
                                <param.component
                                    key={param.title + param.field}
                                    searchToggle={param.searchToggle}
                                    editSearchParams={param.editSearchParams}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={companyFilterBarObject.setFunction}
                                />
                            )
                        })}
                </FilterToolBar>

                <div className=' bg-accent1 text-white dark:bg-darkbg2 dark:text-offwhite text-xs font-bold grid grid-cols-4'>
                    <p className='p-2'>Exemption Type</p>
                    <p className='p-2'>Customer Name</p>
                    <p className='p-2'>Created At</p>
                    <p className='p-2'>Status</p>
                </div>
            </div>
            {taxExemptions.map((exemption: any) => (
                <Link
                    to={`/customers/tax-exemptions/${exemption.id}?${searchParams.toString()}`}
                    draggable={false}
                    state={{ previousLocationPathname: location.pathname }}
                    key={exemption.id}
                    className='cursor-pointer border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkgrey dark:text-sm grid grid-cols-4'
                >
                    <p className='p-2'>
                        {exemption.exemption_type
                            ?.split(',')
                            .map((exemptionType: string) => formatReadableExemption(exemptionType))
                            .join(', ')}
                    </p>
                    <p className='p-2'>{exemption.calculated_full_name}</p>
                    <p className='p-2'>{dateFormatter(new Date(exemption.created_at))}</p>
                    <p className='p-2 capitalize'>{exemption.status.replaceAll('_', ' ').toLowerCase()}</p>
                </Link>
            ))}

            {/* Empty row so pagination buttons do not cover table */}
            {taxExemptions?.length > 20 && <div className='h-[36px]'></div>}

            <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                <>
                    <button
                        disabled={page == 1}
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handleFirstPage()
                        }}
                    >
                        <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                    </button>
                    <button
                        disabled={page == 1}
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handlePageDecrease()
                        }}
                    >
                        <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                    </button>
                </>
                <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                <button
                    className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                    onClick={() => {
                        handlePageIncrease()
                    }}
                    disabled={taxExemptionQuery.isLoading || taxExemptionQuery.isFetching || taxExemptions?.length < 50}
                >
                    <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                </button>
            </div>
        </div>
    )
}
