import { Dispatch, SetStateAction, useState } from 'react'
import Input from '../../procurement/components/Input'
import { FaTimes } from 'react-icons/fa'
import Textarea from '../../procurement/components/Textarea'
import Select from '../../procurement/components/Select'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { vFetch } from '../../helpers'
import { useRoadmaps } from '../api/useQueries'

export default function CreateProject({
    setQuickModal,
}: {
    setQuickModal: Dispatch<SetStateAction<undefined | string>>
}) {
    const defaultProject = {
        title: '',
        description: '',
        status: 'backlog',
        project_lead: '',
        start_date: 'low',
        target_date: '0',
        pinned: '',
    }
    const [project, setProject] = useState({ ...defaultProject })
    const allUsersQuery = useAllUsersQuery()
    const users: UserInit[] = allUsersQuery?.data?.users || []
    const roadmapsQuery = useRoadmaps()
    const roadmaps = roadmapsQuery?.data || []

    const queryClient = useQueryClient()
    const createProjectMutation = useMutation({
        mutationFn: () =>
            vFetch('/v1/development/projects', {
                method: 'POST',
                body: JSON.stringify({
                    ...project,
                    roadmaps: [],
                    project_lead: project.project_lead || undefined,
                }),
            }),
        onSuccess: () => {
            setQuickModal(undefined)
            setProject({ ...defaultProject })
            return queryClient.invalidateQueries({ queryKey: ['development', 'projects'] })
        },
    })

    const handleCreateProject = async () => {
        createProjectMutation.mutate()
    }

    return (
        <div className='fixed min-w-[500px] pb-4 bg-white dark:bg-darkaccent z-50 bottom-0 left-[50%] translate-x-[-50%] rounded border border-darkgrey border-b-0 rounded-b-none'>
            <div className='p-2 rounded flex justify-between items-center bg-lightgrey dark:bg-darkness'>
                <h3 className='font-bold text-[14px] leading-tight'>New Project</h3>
                <button onClick={() => setQuickModal(undefined)} className=''>
                    <FaTimes className='text-red dark:text-lightred' />
                </button>
            </div>
            <div className='flex flex-col gap-2 p-4'>
                <Input
                    label='Title'
                    id='title'
                    name='title'
                    onChange={({ target }) => setProject({ ...project, [target.name]: target.value })}
                    type='text'
                    autoFocus={true}
                    value={project.title}
                />
                <Textarea
                    label='Description'
                    id='description'
                    name='description'
                    onChange={({ target }) => setProject({ ...project, [target.name]: target.value })}
                    value={project.description}
                />
                <div className='flex gap-2'>
                    <Select
                        label='Roadmaps'
                        id='roadmaps'
                        name='roadmaps'
                        onChange={({ target }) => setProject({ ...project, [target.name]: target.value })}
                        value={''}
                    >
                        <option value=''>No roadmap</option>
                        {roadmaps.map((roadmap: any) => (
                            <option value={roadmap.id}>{roadmap.title}</option>
                        ))}
                    </Select>
                    <Select
                        label='Project Lead'
                        id='project_lead'
                        name='project_lead'
                        onChange={({ target }) => setProject({ ...project, [target.name]: target.value })}
                        value={project.project_lead}
                    >
                        <option value=''>No assignee</option>
                        {users
                            .filter((user) => user.roles.includes('developer'))
                            .map((user) => (
                                <option value={user.id}>
                                    {user.first_name} {user.last_name}
                                </option>
                            ))}
                    </Select>
                </div>
                <div className='grid grid-cols-4 max-w-[500px] gap-2'>
                    <Select
                        label='Status'
                        id='status'
                        name='status'
                        onChange={({ target }) => setProject({ ...project, [target.name]: target.value })}
                        value={project.status}
                    >
                        <option className='dark:bg-darkness/90' value='backlog'>
                            Backlog
                        </option>
                        <option className='dark:bg-darkness/90' value='planned'>
                            Planned
                        </option>
                        <option className='dark:bg-darkness/90' value='paused'>
                            Paused
                        </option>
                        <option className='dark:bg-darkness/90' value='in progress'>
                            In Progress
                        </option>
                        <option className='dark:bg-darkness/90' value='completed'>
                            Completed
                        </option>
                        <option className='dark:bg-darkness/90' value='canceled'>
                            Canceled
                        </option>
                    </Select>
                    <Input
                        label='Start Date'
                        id='start_date'
                        name='start_date'
                        onChange={({ target }) => setProject({ ...project, [target.name]: target.value })}
                        type='date'
                        value={project.start_date}
                    />
                    <Input
                        label='Target Date'
                        id='target_date'
                        name='target_date'
                        onChange={({ target }) => setProject({ ...project, [target.name]: target.value })}
                        type='date'
                        value={project.target_date}
                    />
                    <Input
                        label='Pinned'
                        id='pinned'
                        name='pinned'
                        onChange={({ target }) =>
                            setProject({ ...project, [target.name]: (target as HTMLInputElement).checked })
                        }
                        type='checkbox'
                        className="appearance-none font-bold text-[14px] flex items-center justify-center relative w-full h-8 after:content-['Unpinned'] checked:after:content-['Pinned'] checked:!bg-blue checked:!text-white dark:checked:!bg-accent dark:checked:!text-darkaccent"
                        value={project.pinned}
                    />
                </div>
            </div>
            <button
                onClick={handleCreateProject}
                className='block h-fit mx-auto bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
            >
                Create Project
            </button>
        </div>
    )
}
