import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AiFillCaretRight } from 'react-icons/ai'
import { TailSpin } from 'react-loading-icons'
import Chart from 'chart.js/auto'

import './SalesScreen.css'
import SalesCalendar from './Components/SalesCalendar'
import {
    DayMap,
    MonthMap,
    closePopout,
    dayMap,
    formatMoney,
    getBusinessDays,
    monthMap,
    parseResObject,
    sortByAlphanumeric,
    userShopifyRepName,
    vFetch,
} from '../helpers'
import { OrderInit } from '../orders/orders.types'
import { UserInit } from '../users/users.types'
import {
    RepGoals,
    SalesGoalInit,
    SalesPeriod,
    defaultRepGoals,
    defaultSalesGoal,
    defaultSalesPeriod,
} from './sales.types'
import { quickDateRange } from './sales.helpers'
import { useAllUsersQuery } from 'users/api/useQueries'

export default function SalesScreen() {
    const { user, settings } = useSelector<any, any>((state) => state)
    const lightOptions = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: 'orange',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }
    const darkOptions = {
        scales: {
            x: {
                grid: {
                    color: '#FFFFFF00',
                },
                ticks: {
                    color: '#eeefea',
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: (context: any) => {
                        const zeroLine = context.tick.value
                        const barColor = zeroLine === 0 ? '#FFFFFF40' : '#FFFFFF10'
                        return barColor
                    },
                },
                ticks: {
                    color: '#eeefea',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: '#42EFD0',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }
    const repYearlyGoalChart = useRef<any>(null)
    const [repYearlyGoalChartHolder, setRepYearlyGoalChartHolder] = useState<any>({})
    const [showRepYearlyGoalsDetails, setShowRepYearlyGoalsDetails] = useState<boolean>(false)
    const repQuarterlyGoalsPie = useRef<any>(null)
    const [repQuarterlyGoalsPieHolder, setRepQuarterlyGoalsPieHolder] = useState<any>({})
    const [showRepQuarterlyGoalsDetails, setShowRepQuarterlyGoalsDetails] = useState<boolean>(false)
    const repQuarterlyGoalsChart = useRef<any>(null)
    const [repQuarterlyGoalsChartHolder, setRepQuarterlyGoalsChartHolder] = useState<any>({})
    const repMonthlyGoalsChart = useRef<any>(null)
    const [repMonthlyGoalsPieHolder, setRepMonthlyGoalsPieHolder] = useState<any>({})
    const [showRepMonthlyGoalsDetails, setShowRepMonthlyGoalsDetails] = useState<boolean>(false)
    const repMonthlyGoalsPie = useRef<any>(null)
    const [repMonthlyGoalsChartHolder, setRepMonthlyGoalsChartHolder] = useState<any>({})
    const repSalesChart = useRef<any>(null)
    const [repSalesChartHolder, setRepSalesChartHolder] = useState<any>({})
    const teamGoalChart = useRef<any>(null)
    const [teamGoalChartHolder, setTeamGoalChartHolder] = useState<any>({})
    const navigate = useNavigate()
    const mFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format
    const [loading, setLoading] = useState(false)
    const [managerMode, setManagerMode] = useState(false)

    const quarters = [1, 2, 3, 4]

    const [goals, setGoals] = useState<SalesGoalInit[]>([])
    const [repGoals, setRepGoals] = useState<RepGoals>({ ...defaultRepGoals })
    const [teamGoal, setTeamGoal] = useState<SalesGoalInit>({ ...defaultSalesGoal })
    const [orders, setOrders] = useState<OrderInit[]>([])
    const [filteredOrders, setFilteredOrders] = useState<OrderInit[]>([])
    const [total, setTotal] = useState(0)

    const [allTeamOrders, setAllTeamOrders] = useState<any>({})
    const [filteredATO, setFilteredATO] = useState<any>({})
    const [totalATO, setTotalATO] = useState<any>({ total: 0, percentDifference: 0 })

    const [timeFilter, setTimeFilter] = useState<string>('Month To Date')
    const [showTimeFilters, setShowTimeFilters] = useState<boolean>(false)
    const [showSpecificTimeFilters, setShowSpecificTimeFilters] = useState<string>('MTD')
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState<Date>(new Date())

    const [repName, setRepName] = useState<string>('')

    const usersQuery = useAllUsersQuery()
    const reps: any[] = (usersQuery.data?.users || [])
        .filter((u: any) =>
            user.type === 'Admin' || user.type === 'SuperAdmin' ? u.roles.includes('sales') : u.id === user.id
        )
        .filter((u: any) => u.type !== 'Archived')

    const [days, setDays] = useState<number>(7)
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [month, setMonth] = useState<number>(new Date().getMonth())
    const [quarter, setQuarter] = useState<number>(quarters[Math.ceil((new Date().getMonth() + 1) / 3)])
    const years = []
    for (let i = new Date().getFullYear(); i >= 2019 && years.length < 10; i--) years.push(i)

    const months: any[] = [
        { name: 'January', orders: [] as OrderInit[] },
        { name: 'February', orders: [] as OrderInit[] },
        { name: 'March', orders: [] as OrderInit[] },
        { name: 'April', orders: [] as OrderInit[] },
        { name: 'May', orders: [] as OrderInit[] },
        { name: 'June', orders: [] as OrderInit[] },
        { name: 'July', orders: [] as OrderInit[] },
        { name: 'August', orders: [] as OrderInit[] },
        { name: 'September', orders: [] as OrderInit[] },
        { name: 'October', orders: [] as OrderInit[] },
        { name: 'November', orders: [] as OrderInit[] },
        { name: 'December', orders: [] as OrderInit[] },
    ]
    const [showMonths, setShowMonths] = useState<number[]>([])

    const yearly = showSpecificTimeFilters === 'YTD' || showSpecificTimeFilters === 'year'
    const quarterly = showSpecificTimeFilters === 'quarter'
    const mtd = showSpecificTimeFilters === 'MTD'
    const monthly = showSpecificTimeFilters === 'month'
    const lastXDays = showSpecificTimeFilters.match(/last \d+ days/) !== null
    const byDate = showSpecificTimeFilters === 'date'
    const endDateIsToday =
        endDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0] ||
        lastXDays ||
        mtd ||
        showSpecificTimeFilters === 'ytd'

    const prevST = new Date(
        yearly ? startDate.getFullYear() - 1 : startDate.getFullYear(),
        quarterly ? startDate.getMonth() - 3 : monthly || mtd ? startDate.getMonth() - 1 : startDate.getMonth(),
        lastXDays
            ? startDate.getDate() - (days + 1)
            : byDate
              ? startDate.getDate() - Math.ceil((endDate.getTime() - startDate.getTime()) / 86400000)
              : 1
    )
    const prevET = new Date(
        yearly ? endDate.getFullYear() - 1 : endDate.getFullYear(),
        quarterly ? endDate.getMonth() - 3 : mtd ? endDate.getMonth() - 1 : endDate.getMonth(),
        lastXDays ? endDate.getDate() - (days + 1) : monthly ? 0 : byDate ? startDate.getDate() - 1 : endDate.getDate(),
        endDateIsToday ? new Date().getHours() : 23,
        endDateIsToday ? new Date().getMinutes() : 59,
        endDateIsToday ? new Date().getSeconds() : 59,
        endDateIsToday ? new Date().getMilliseconds() : 999
    )

    const prevYST = new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate())
    const prevYET = new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999)

    const previousPeriod: SalesPeriod = {
        ...defaultSalesPeriod,
        orders: orders.filter(
            (order) =>
                new Date(order.created_at).getTime() >= prevST.getTime() &&
                new Date(order.created_at).getTime() <= prevET.getTime()
        ),
    }
    previousPeriod.total = previousPeriod.orders.reduce((acc: number, cur: OrderInit) => acc + cur.total_price, 0)
    previousPeriod.totalDif = Number(((total / previousPeriod.total - 1) * 100).toFixed(2))
    previousPeriod.volume = previousPeriod.orders.length
    previousPeriod.volDif = Number(((filteredOrders.length / previousPeriod.volume - 1) * 100).toFixed(2))
    previousPeriod.avgTotal = Number((previousPeriod.total / previousPeriod.volume).toFixed(2))
    previousPeriod.avgTotDif = Number(((total / filteredOrders.length / previousPeriod.avgTotal - 1) * 100).toFixed(2))

    const lastYearPeriod: SalesPeriod = {
        ...defaultSalesPeriod,
        orders: orders.filter(
            (order) =>
                new Date(order.created_at).getTime() >= prevYST.getTime() &&
                new Date(order.created_at).getTime() <= prevYET.getTime()
        ),
    }
    lastYearPeriod.total = lastYearPeriod.orders.reduce((acc: number, cur: OrderInit) => {
        return acc + cur.total_price
    }, 0)
    lastYearPeriod.totalDif = Number(((total / lastYearPeriod.total - 1) * 100).toFixed(2))
    lastYearPeriod.volume = lastYearPeriod.orders.length
    lastYearPeriod.volDif = Number(((filteredOrders.length / lastYearPeriod.volume - 1) * 100).toFixed(2))
    lastYearPeriod.avgTotal = Number((lastYearPeriod.total / lastYearPeriod.volume).toFixed(2))
    lastYearPeriod.avgTotDif = Number(((total / filteredOrders.length / lastYearPeriod.avgTotal - 1) * 100).toFixed(2))

    useEffect(() => {
        user.type === 'Admin' || user.type === 'SuperAdmin' ? setManagerMode(true) : setManagerMode(false)

        const listenToWindow = (e: any) => closePopout(e, ['js-time-filters'], setShowTimeFilters)
        window.addEventListener('click', listenToWindow)

        const listenForResize = () => {
            if (repQuarterlyGoalsChart) repQuarterlyGoalsChart.current.style.width = '432px'
            if (repMonthlyGoalsChart) repMonthlyGoalsChart.current.style.width = '100%'
        }
        window.addEventListener('resize', listenForResize)

        vFetch(`/v1/sales`, {
            cb: (res: any) => {
                if (res.success) {
                    const stripDate = (ISOString: string) =>
                        ISOString.split('T')[0]
                            .split('-')
                            .map((v) => Number(v))
                    setGoals(
                        res.goals.map((g: any) => {
                            return {
                                ...g,
                                start_date: new Date(
                                    stripDate(g.start_date)[0],
                                    stripDate(g.start_date)[1] - 1,
                                    stripDate(g.start_date)[2]
                                ),
                                end_date: new Date(
                                    stripDate(g.end_date)[0],
                                    stripDate(g.end_date)[1] - 1,
                                    stripDate(g.end_date)[2],
                                    23,
                                    59,
                                    59,
                                    999
                                ),
                            }
                        })
                    )
                }
            },
        })

        return () => {
            window.removeEventListener('click', listenToWindow)
            window.removeEventListener('resize', listenForResize)
        }
    }, [])

    useEffect(() => {
        setRepName(reps[0]?.shopify_rep_name)
    }, [reps.length])

    useEffect(() => {
        if (repQuarterlyGoalsChart.current) repQuarterlyGoalsChart.current.style.width = '432px'
        if (repMonthlyGoalsChart.current) repMonthlyGoalsChart.current.style.width = '100%'
        if (repYearlyGoalChartHolder.id || repYearlyGoalChartHolder.id === 0) {
            repYearlyGoalChartHolder.options = settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : ''
            repYearlyGoalChartHolder.data.datasets[0] = {
                data: repYearlyGoalChartHolder.data.datasets[0].data,
                backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
            }
            repYearlyGoalChartHolder.update()
        }
        if (repQuarterlyGoalsPieHolder.id || repQuarterlyGoalsPieHolder.id === 0) {
            repQuarterlyGoalsPieHolder.options = settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : ''
            repQuarterlyGoalsPieHolder.data.datasets[0] = {
                data: repQuarterlyGoalsPieHolder.data.datasets[0].data,
                backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
            }
            repQuarterlyGoalsPieHolder.update()
        }
        if (repQuarterlyGoalsChartHolder.id || repQuarterlyGoalsChartHolder.id === 0) {
            repQuarterlyGoalsChartHolder.options = settings.theme === 'dark' ? darkOptions : lightOptions
            repQuarterlyGoalsChartHolder.data.datasets[0] = {
                data: repQuarterlyGoalsChartHolder.data.datasets[0].data,
                backgroundColor: Object.values(repGoals.quarterly).map((g) =>
                    g.goal
                        ? Number(g.goal.total_delta) >= 0
                            ? settings.theme === 'dark'
                                ? 'lightgreen'
                                : 'green'
                            : 'rgb(150, 0, 0)'
                        : 'black'
                ),
            }
            repQuarterlyGoalsChartHolder.update()
        }
        if (repMonthlyGoalsPieHolder.id || repMonthlyGoalsPieHolder.id === 0) {
            repMonthlyGoalsPieHolder.options = settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : ''
            repMonthlyGoalsPieHolder.data.datasets[0] = {
                data: repMonthlyGoalsPieHolder.data.datasets[0].data,
                backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
            }
            repMonthlyGoalsPieHolder.update()
        }
        if (repMonthlyGoalsChartHolder.id || repMonthlyGoalsChartHolder.id === 0) {
            repMonthlyGoalsChartHolder.options = settings.theme === 'dark' ? darkOptions : lightOptions
            repMonthlyGoalsChartHolder.data.datasets[0] = {
                data: repMonthlyGoalsChartHolder.data.datasets[0].data,
                backgroundColor: Object.values(repGoals.monthly).map((g) =>
                    g.goal
                        ? Number(g.goal.total_delta) >= 0
                            ? settings.theme === 'dark'
                                ? 'lightgreen'
                                : 'green'
                            : 'rgb(150, 0, 0)'
                        : 'black'
                ),
            }
            repMonthlyGoalsChartHolder.update()
        }
        if (teamGoalChartHolder.id || teamGoalChartHolder.id === 0) {
            teamGoalChartHolder.options = settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : ''
            teamGoalChartHolder.data.datasets[0] = {
                data: teamGoalChartHolder.data.datasets[0].data,
                backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
            }
            teamGoalChartHolder.update()
        }
        if (repSalesChartHolder.id || repSalesChartHolder.id === 0) {
            repSalesChartHolder.options = settings.theme === 'dark' ? darkOptions : lightOptions
            repSalesChartHolder.data.datasets[0] = {
                data: repSalesChartHolder.data.datasets[0].data,
                backgroundColor: settings.theme === 'dark' ? ['lightgreen'] : ['green'],
            }
            repSalesChartHolder.update()
        }
    }, [settings.theme])

    useEffect(() => {
        if (reps.length) {
            setLoading(true)
            vFetch(
                `/v1/orders/limited/draft-orders?names=${reps.map((r) => userShopifyRepName(r))}
            &start_date=${
                new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()).toISOString().split('T')[0]
            }
            &end_date=${
                new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()).toISOString().split('T')[0]
            }
            &prevST=${new Date(prevST.getFullYear(), prevST.getMonth(), prevST.getDate()).toISOString().split('T')[0]}
            &prevET=${new Date(prevET.getFullYear(), prevET.getMonth(), prevET.getDate()).toISOString().split('T')[0]}
            &prevYST=${
                new Date(prevYST.getFullYear(), prevYST.getMonth(), prevYST.getDate()).toISOString().split('T')[0]
            }
            &prevYET=${
                new Date(prevYET.getFullYear(), prevYET.getMonth(), prevYET.getDate()).toISOString().split('T')[0]
            }`,
                {
                    cb: (oRes: any) => {
                        if (oRes.success) {
                            const resOrders = oRes.orders
                                .map((o: any) => parseResObject(o))
                                .sort((a: OrderInit, b: OrderInit) =>
                                    sortByAlphanumeric(new Date(a.created_at), new Date(b.created_at))
                                )
                            const ATO: any = {}
                            const filteredATO: any = {}
                            resOrders.forEach((o: any) => {
                                if (!ATO[o.representative_name])
                                    ATO[o.representative_name] = { name: o.representative_name, orders: [] }
                                if (!filteredATO[o.representative_name])
                                    filteredATO[o.representative_name] = { name: o.representative_name, orders: [] }
                                ATO[o.representative_name].orders.push(o)
                                if (
                                    new Date(o.created_at).getTime() >= startDate.getTime() &&
                                    new Date(o.created_at).getTime() <= endDate.getTime()
                                )
                                    filteredATO[o.representative_name].orders.push(o)
                            })
                            Object.keys(ATO).forEach((r) => {
                                ATO[r].orders.sort((a: OrderInit, b: OrderInit) =>
                                    sortByAlphanumeric(
                                        new Date(a.created_at).getTime(),
                                        new Date(b.created_at).getTime()
                                    )
                                )
                                ATO[r].total = Number(
                                    ATO[r].orders
                                        .reduce((acc: number, cur: OrderInit) => acc + Number(cur.total_price), 0)
                                        .toFixed(2)
                                )
                                ATO[r].volume = ATO[r].orders.length
                                ATO[r].average_order = Number((ATO[r].total / ATO[r].volume).toFixed(2))

                                filteredATO[r].orders.sort((a: OrderInit, b: OrderInit) =>
                                    sortByAlphanumeric(
                                        new Date(a.created_at).getTime(),
                                        new Date(b.created_at).getTime()
                                    )
                                )
                                filteredATO[r].total = Number(
                                    filteredATO[r].orders
                                        .reduce((acc: number, cur: OrderInit) => acc + Number(cur.total_price), 0)
                                        .toFixed(2)
                                )
                                filteredATO[r].volume = filteredATO[r].orders.length
                                filteredATO[r].average_order = Number(
                                    (filteredATO[r].total / filteredATO[r].volume).toFixed(2)
                                )
                            })

                            setAllTeamOrders(ATO)
                            setFilteredATO(filteredATO)
                            const repOrders = resOrders.filter((o: any) => o.representative_name === repName)
                            setOrders(repOrders)
                            setFilteredOrders(
                                repOrders.filter(
                                    (order: any) =>
                                        new Date(order.created_at).getTime() >= startDate.getTime() &&
                                        new Date(order.created_at).getTime() <= endDate.getTime()
                                )
                            )
                            setLoading(false)
                        }
                    },
                }
            )
        }
    }, [reps.length, startDate, endDate])

    useEffect(() => {
        if (Object.keys(allTeamOrders).length && allTeamOrders[repName]) {
            setOrders(allTeamOrders[repName].orders)
            setFilteredOrders(
                allTeamOrders[repName].orders.filter(
                    (order: OrderInit) =>
                        new Date(order.created_at).getTime() >= startDate.getTime() &&
                        new Date(order.created_at).getTime() <= endDate.getTime()
                )
            )
        } else {
            setOrders([])
            setFilteredOrders([])
        }
    }, [repName, allTeamOrders])

    useEffect(() => {
        const salesTotalPrice: any = {}
        const salesOrderVolume: any = {}
        const repTotal = filteredOrders.reduce((acc: number, cur: OrderInit) => {
            return acc + cur.total_price
        }, 0)

        setTotal(repTotal)

        months.forEach((m, i) => (months[month] = { ...months[month], orders: [] }))

        for (let order of filteredOrders.slice().reverse()) {
            months[new Date(order.created_at).getMonth()].orders.unshift(order)
            const ymd = order.created_at.toISOString().split('T')[0]
            salesTotalPrice[ymd]
                ? (salesTotalPrice[ymd] += order.total_price)
                : (salesTotalPrice[ymd] = order.total_price)
            salesOrderVolume[ymd] ? (salesOrderVolume[ymd] += 1) : (salesOrderVolume[ymd] = 1)
        }

        if (teamGoal.total) {
            if (!teamGoalChartHolder.id && teamGoalChartHolder.id !== 0) {
                setTeamGoalChartHolder(
                    new Chart(teamGoalChart.current, {
                        type: 'pie',
                        data: {
                            labels: ['Sales', 'Remaining'],
                            datasets: [
                                {
                                    data: [totalATO.total, teamGoal.total - totalATO.total],
                                    backgroundColor:
                                        settings.theme === 'dark'
                                            ? ['lightgreen', 'orange']
                                            : ['green', 'rgb(0, 60, 90)'],
                                    hoverOffset: 4,
                                },
                            ],
                        },
                        options: settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : {},
                    })
                )
            } else {
                teamGoalChartHolder.data.datasets[0] = {
                    data: [totalATO.total, Math.max(0, teamGoal.total - totalATO.total)],
                    backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
                }
                teamGoalChartHolder.update()
            }
        }
        if (!repSalesChartHolder.id && repSalesChartHolder.id !== 0) {
            setRepSalesChartHolder(
                new Chart(repSalesChart.current, {
                    type: 'bar',
                    data: {
                        labels: Object.keys(salesTotalPrice),
                        datasets: [
                            {
                                data: Object.values(salesTotalPrice),
                                backgroundColor: ['rgb(238,239,234)'],
                            },
                        ],
                    },
                    options: settings.theme == 'dark' ? darkOptions : lightOptions,
                })
            )
        } else {
            repSalesChartHolder.data.labels = Object.keys(salesTotalPrice) //.map(d => [parseInt(d.split("-")[2]), months[parseInt(d.split("-")[1])-1].name.slice(0,3), d.split("-")[0].slice(2, 4)].join(" "));
            repSalesChartHolder.data.datasets[0] = {
                data: Object.values(salesTotalPrice),
                backgroundColor: settings.theme === 'dark' ? ['lightgreen'] : ['green'],
            }
            repSalesChartHolder.update()
        }
    }, [filteredOrders])

    useEffect(() => {
        const allTeamTotal = Object.values(filteredATO)
            .map((r: any) => r && r.orders.reduce((acc: number, cur: OrderInit) => acc + Number(cur.total_price), 0))
            .reduce((acc: number, cur: number) => acc + cur, 0)
        const allTeamAverageVolume =
            Object.values(filteredATO)
                .map((r: any) => r.orders.length)
                .reduce((acc: number, cur: number) => acc + cur, 0) / reps.length

        setTotalATO({
            ...totalATO,
            percentDifference: Number(
                (
                    (filteredOrders.reduce((acc: number, cur: OrderInit) => acc + Number(cur.total_price), 0) /
                        (allTeamTotal / reps.length) -
                        1) *
                    100
                ).toFixed(2)
            ),
            total: allTeamTotal,
            averageVolume: allTeamAverageVolume,
            avgVolDif: Number(((filteredOrders.length / allTeamAverageVolume - 1) * 100).toFixed(2)),
        })
    }, [repName, filteredOrders])

    useEffect(() => {
        const foundGoal1 = goals.find(
            (g) =>
                g.start_date.getTime() === startDate.getTime() &&
                g.end_date.getTime() === endDate.getTime() &&
                g.assigned_to === null
        )
        if (foundGoal1) return setTeamGoal(foundGoal1)

        const foundGoal2 = goals.find(
            (g) =>
                g.start_date.getTime() === new Date(startDate.getFullYear(), startDate.getMonth(), 1).getTime() &&
                g.end_date.getTime() ===
                    new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59, 999).getTime() &&
                g.assigned_to === null
        )
        if (foundGoal2) return setTeamGoal(foundGoal2)

        const foundGoal3 = goals.find(
            (g) =>
                g.start_date.getTime() ===
                    new Date(startDate.getFullYear(), Math.floor(startDate.getMonth() / 3) * 3, 1).getTime() &&
                g.end_date.getTime() ===
                    new Date(
                        startDate.getFullYear(),
                        (Math.ceil(startDate.getMonth() / 3) + 1) * 3,
                        0,
                        23,
                        59,
                        59,
                        999
                    ).getTime() &&
                g.assigned_to === null
        )
        if (foundGoal3) return setTeamGoal(foundGoal3)

        const foundGoal4 = goals.find(
            (g) =>
                g.start_date.getTime() === new Date(startDate.getFullYear(), 0, 1).getTime() &&
                g.end_date.getTime() === new Date(startDate.getFullYear() + 1, 0, 0, 23, 59, 59, 999).getTime() &&
                g.assigned_to === null
        )
        if (foundGoal4) return setTeamGoal(foundGoal4)

        setTeamGoal(defaultSalesGoal)
    }, [goals, startDate, endDate])

    useEffect(() => {
        const empty = (name: string) => {
            return { name, actual: 0 }
        }
        const tempRepGoals: RepGoals = {
            ...defaultRepGoals,
            all: goals.filter(
                (g) =>
                    g.assigned_to === repName &&
                    g.start_date.getFullYear() >= startDate.getFullYear() &&
                    g.end_date.getFullYear() <= endDate.getFullYear()
            ),
        }
        Object.keys(tempRepGoals.monthly).forEach(
            (key) => ((tempRepGoals.monthly as any)[key] = empty((tempRepGoals.monthly as any)[key].name))
        )
        Object.keys(tempRepGoals.quarterly).forEach(
            (key) => ((tempRepGoals.quarterly as any)[key] = empty((tempRepGoals.quarterly as any)[key].key))
        )
        tempRepGoals.yearly = tempRepGoals.all.find(
            (g) =>
                g.start_date.getTime() === new Date(startDate.getFullYear(), 0, 1).getTime() &&
                g.end_date.getTime() === new Date(startDate.getFullYear(), 11, 31, 23, 59, 59, 999).getTime()
        )
        tempRepGoals.all
            .filter(
                (g) =>
                    g.start_date.getDate() === 1 &&
                    g.end_date.getTime() ===
                        new Date(g.start_date.getFullYear(), g.start_date.getMonth() + 1, 0, 23, 59, 59, 999).getTime()
            )
            .forEach((g) => ((tempRepGoals.monthly as any)[g.start_date.getMonth()].goal = g))
        tempRepGoals.all
            .filter(
                (g) =>
                    g.start_date.getDate() === 1 &&
                    [0, 1, 2, 3].includes(g.start_date.getMonth() / 3) &&
                    g.end_date.getTime() ===
                        new Date(g.start_date.getFullYear(), g.start_date.getMonth() + 3, 0, 23, 59, 59, 999).getTime()
            )
            .forEach((g) => ((tempRepGoals.quarterly as any)[g.start_date.getMonth() / 3].goal = g))

        if (tempRepGoals.all.length === 0) {
            setRepGoals(tempRepGoals)
        } else {
            vFetch(
                `/v1/orders/limited/draft-orders/yearly-rep-sales?rep_name=${repName}&start_date=${startDate.getFullYear()}-01-01&end_date=${startDate.getFullYear()}-12-31`,
                {
                    cb: (res: any) => {
                        if (res.success) {
                            if (tempRepGoals.yearly)
                                tempRepGoals.yearly.actual = res.orders.reduce(
                                    (acc: number, cur: OrderInit) => acc + cur.total_price,
                                    0
                                )
                            for (let month of Object.keys(tempRepGoals.monthly)) {
                                ;(tempRepGoals.monthly as any)[month].actual = res.orders
                                    .filter(
                                        (o: any) =>
                                            new Date(o.created_at).getTime() >=
                                                new Date(startDate.getFullYear(), Number(month), 1).getTime() &&
                                            new Date(o.created_at).getTime() <=
                                                new Date(
                                                    startDate.getFullYear(),
                                                    Number(month) + 1,
                                                    0,
                                                    23,
                                                    59,
                                                    59,
                                                    999
                                                ).getTime()
                                    )
                                    .reduce((acc: number, cur: OrderInit) => acc + cur.total_price, 0)
                                ;(tempRepGoals.quarterly as any)[Math.floor(Number(month) / 3)].actual += res.orders
                                    .filter(
                                        (r: any) =>
                                            new Date(r.created_at).getTime() >=
                                                new Date(startDate.getFullYear(), Number(month), 1).getTime() &&
                                            new Date(r.created_at).getTime() <=
                                                new Date(
                                                    startDate.getFullYear(),
                                                    Number(month) + 1,
                                                    0,
                                                    23,
                                                    59,
                                                    59,
                                                    999
                                                ).getTime()
                                    )
                                    .reduce((acc: number, cur: OrderInit) => acc + cur.total_price, 0)
                            }
                            setRepGoals(tempRepGoals)
                        }
                    },
                }
            )
        }
    }, [managerMode, goals, repName, startDate, endDate])

    useEffect(() => {
        if (repGoals.yearly) {
            if (!repYearlyGoalChartHolder.id && repYearlyGoalChartHolder.id !== 0) {
                setRepYearlyGoalChartHolder(
                    new Chart(repYearlyGoalChart.current, {
                        type: 'pie',
                        data: {
                            labels: ['Sales', 'Remaining'],
                            datasets: [
                                {
                                    data: [repGoals.yearly.actual, repGoals.yearly.total - repGoals.yearly.actual],
                                    backgroundColor:
                                        settings.theme === 'dark'
                                            ? ['lightgreen', 'orange']
                                            : ['green', 'rgb(0, 60, 90)'],
                                    hoverOffset: 4,
                                },
                            ],
                        },
                        options: settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : {},
                    })
                )
            } else {
                repYearlyGoalChartHolder.data.datasets[0] = {
                    data: [repGoals.yearly.actual, Math.max(repGoals.yearly.total - repGoals.yearly.actual, 0)],
                    backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
                }
                repYearlyGoalChartHolder.update()
            }
        }
        if (repGoals.quarterly && Object.values(repGoals.quarterly).some((q) => q.goal)) {
            if (!repQuarterlyGoalsPieHolder.id && repQuarterlyGoalsPieHolder !== 0) {
                setRepQuarterlyGoalsPieHolder(
                    new Chart(repQuarterlyGoalsPie.current, {
                        type: 'pie',
                        data: {
                            labels: ['Sales', 'Remaining'],
                            datasets: [
                                {
                                    data: (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].goal
                                        ? [
                                              (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].actual,
                                              (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].goal
                                                  .total -
                                                  (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)]
                                                      .actual,
                                          ]
                                        : [],
                                    backgroundColor:
                                        settings.theme === 'dark'
                                            ? ['lightgreen', 'orange']
                                            : ['green', 'rgb(0, 60, 90)'],
                                    hoverOffset: 4,
                                },
                            ],
                        },
                        options: settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : {},
                    })
                )
            } else {
                repQuarterlyGoalsPieHolder.data.datasets[0] = {
                    data: (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].goal
                        ? [
                              (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].actual,
                              Math.max(
                                  (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].goal.total -
                                      (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].actual,
                                  0
                              ),
                          ]
                        : [],
                    backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
                }
                repQuarterlyGoalsPieHolder.update()
            }
            if (!repQuarterlyGoalsChartHolder.id && repQuarterlyGoalsChartHolder.id !== 0) {
                setRepQuarterlyGoalsChartHolder(
                    new Chart(repQuarterlyGoalsChart.current, {
                        type: 'bar',
                        data: {
                            labels: Object.values(quarters).map((q) => 'Q' + q),
                            datasets: [
                                {
                                    data: Object.values(repGoals.quarterly).map((g) =>
                                        g.goal ? g.goal.total_delta : 0
                                    ),
                                    backgroundColor: Object.values(repGoals.quarterly).map((g) =>
                                        g.goal
                                            ? Number(g.goal.total_delta) >= 0
                                                ? settings.theme === 'dark'
                                                    ? 'lightgreen'
                                                    : 'green'
                                                : 'rgb(150, 0, 0)'
                                            : 'black'
                                    ),
                                },
                            ],
                        },
                        options: settings.theme == 'dark' ? darkOptions : lightOptions,
                    })
                )
            } else {
                repQuarterlyGoalsChartHolder.data.datasets[0] = {
                    data: Object.values(repGoals.quarterly).map((g) => (g.goal ? g.goal.total_delta : 0)),
                    backgroundColor: Object.values(repGoals.quarterly).map((g) =>
                        g.goal
                            ? Number(g.goal.total_delta) >= 0
                                ? settings.theme === 'dark'
                                    ? 'lightgreen'
                                    : 'green'
                                : 'rgb(150, 0, 0)'
                            : 'black'
                    ),
                }
                repQuarterlyGoalsChartHolder.update()
            }
        }
        if (repGoals.monthly && Object.values(repGoals.monthly).some((m) => m.goal)) {
            if (!repMonthlyGoalsPieHolder.id && repMonthlyGoalsPieHolder.id !== 0) {
                setRepMonthlyGoalsPieHolder(
                    new Chart(repMonthlyGoalsPie.current, {
                        type: 'pie',
                        data: {
                            labels: ['Sales', 'Remaining'],
                            datasets: [
                                {
                                    data: (repGoals.monthly as any)[startDate.getMonth()].goal
                                        ? [
                                              (repGoals.monthly as any)[startDate.getMonth()].actual,
                                              Math.max(
                                                  (repGoals.monthly as any)[startDate.getMonth()].goal.total -
                                                      (repGoals.monthly as any)[startDate.getMonth()].actual,
                                                  0
                                              ),
                                          ]
                                        : [],
                                    backgroundColor:
                                        settings.theme === 'dark'
                                            ? ['lightgreen', 'orange']
                                            : ['green', 'rgb(0, 60, 90)'],
                                    hoverOffset: 4,
                                },
                            ],
                        },
                        options: settings.theme === 'dark' ? { borderColor: 'rgb(29, 33, 45)' } : {},
                    })
                )
            } else {
                repMonthlyGoalsPieHolder.data.datasets[0] = {
                    data: [
                        (repGoals.monthly as any)[startDate.getMonth()].actual,
                        (repGoals.monthly as any)[startDate.getMonth()].goal
                            ? Math.max(
                                  (repGoals.monthly as any)[startDate.getMonth()].goal.total -
                                      (repGoals.monthly as any)[startDate.getMonth()].actual,
                                  0
                              )
                            : 0,
                    ],
                    backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
                }
                repMonthlyGoalsPieHolder.update()
            }
            if (!repMonthlyGoalsChartHolder.id && repMonthlyGoalsChartHolder.id !== 0) {
                setRepMonthlyGoalsChartHolder(
                    new Chart(repMonthlyGoalsChart.current, {
                        type: 'bar',
                        data: {
                            labels: Object.values(months).map((m) => m.name.slice(0, 3)),
                            datasets: [
                                {
                                    data: Object.values(repGoals.monthly).map((g) => (g.goal ? g.goal.total_delta : 0)),
                                    backgroundColor: Object.values(repGoals.monthly).map((g) =>
                                        g.goal
                                            ? Number(g.goal.total_delta) >= 0
                                                ? settings.theme === 'dark'
                                                    ? 'lightgreen'
                                                    : 'green'
                                                : 'rgb(150, 0, 0)'
                                            : 'black'
                                    ),
                                },
                            ],
                        },
                        options: settings.theme == 'dark' ? darkOptions : lightOptions,
                    })
                )
            } else {
                repMonthlyGoalsChartHolder.data.datasets[0] = {
                    data: Object.values(repGoals.monthly).map((g) => (g.goal ? g.goal.total_delta : 0)),
                    backgroundColor: Object.values(repGoals.monthly).map((g) =>
                        g.goal
                            ? Number(g.goal.total_delta) >= 0
                                ? settings.theme === 'dark'
                                    ? 'lightgreen'
                                    : 'green'
                                : 'rgb(150, 0, 0)'
                            : 'black'
                    ),
                }
                repMonthlyGoalsChartHolder.update()
            }
        }
    }, [repGoals, startDate])

    useEffect(() => {
        if (teamGoalChartHolder.id && teamGoal && teamGoal.total) {
            teamGoalChartHolder.data.datasets[0] = {
                data: [totalATO.total, Math.max(0, teamGoal.total - totalATO.total)],
                backgroundColor: settings.theme === 'dark' ? ['lightgreen', 'orange'] : ['green', 'rgb(0, 60, 90)'],
            }
            teamGoalChartHolder.update()
        }
    }, [teamGoal])

    useEffect(() => {
        setQuarter(Math.ceil((month + 1) / 3))
    }, [month])

    function handleQuickDateRange(y: number, q?: number, m?: number) {
        const [start, end] = quickDateRange(y, q, m)
        setStartDate(start)
        setEndDate(end)
    }

    return (
        <>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Sales</h1>
            <div className='sticky top-[51px] flex justify-between items-center dark:text-offwhite bg-white dark:bg-darkaccent border-[1px] border-darkgrey gap-[16px] p-[16px] rounded mb-[16px] zIndex-5'>
                <h1 className='text-[24px] font-bold'>
                    {startDate
                        .toDateString()
                        .split(' ')
                        .map((dateItem) =>
                            dayMap[dateItem as keyof DayMap]
                                ? `${dayMap[dateItem as keyof DayMap]}, `
                                : monthMap[dateItem as keyof MonthMap]
                                  ? `${monthMap[dateItem as keyof MonthMap]}`
                                  : Number(dateItem) < 32
                                    ? `${Number(dateItem)},`
                                    : dateItem
                        )
                        .join(' ')}
                    {startDate.toDateString() !== endDate.toDateString()
                        ? endDate
                              .toDateString()
                              .split(' ')
                              .map((dateItem) =>
                                  dayMap[dateItem as keyof DayMap]
                                      ? ` - ${dayMap[dateItem as keyof DayMap]}, `
                                      : monthMap[dateItem as keyof MonthMap]
                                        ? `${monthMap[dateItem as keyof MonthMap]}`
                                        : Number(dateItem) < 32
                                          ? `${Number(dateItem)},`
                                          : dateItem
                              )
                              .join(' ')
                        : ''}
                </h1>
                <div className='flex gap-[16px]'>
                    {user.type !== 'Client' && (
                        <select
                            value={repName}
                            className='dark:bg-darkaccent border-[1px] dark:border-grey'
                            onChange={({ target }) => setRepName(target.value)}
                        >
                            {reps.map((r) => (
                                <option value={userShopifyRepName(r)} className=''>
                                    {userShopifyRepName(r)}
                                </option>
                            ))}
                        </select>
                    )}
                    <div className='relative flex js-time-filters'>
                        <button
                            onClick={() => {
                                setShowTimeFilters(!showTimeFilters)
                            }}
                            className='bg-white hover:bg-offwhite dark:bg-darkaccent dark:hover:bg-darkness dark:text-offwhite shadow-md text-black w-[130px] px-[8px] py-[2px] rounded border-[1px] border-[lightgrey] dark:border-darkgrey'
                        >
                            {timeFilter}
                        </button>
                        {showTimeFilters && (
                            <div className='flex absolute top-[calc(100%+16px)] right-[0px]'>
                                {showSpecificTimeFilters !== 'YTD' &&
                                    showSpecificTimeFilters !== 'MTD' &&
                                    showSpecificTimeFilters !== 'date' && (
                                        <div>
                                            <div className='flex flex-col gap-[16px] bg-white dark:bg-darkaccent border-[1px] border-darkgrey p-[16px] min-w-[150px] shadow-small'>
                                                {showSpecificTimeFilters.match(/last \d+ days/) && (
                                                    <div>
                                                        <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                            <label>Days</label>
                                                            <input
                                                                className='text-black dark:text-offwhite dark:bg-darkness w-[110px] px-[4px]'
                                                                placeholder={String(days)}
                                                                value={days}
                                                                max={10000}
                                                                onChange={({ target }) => {
                                                                    if (
                                                                        Number(target.value) <= 9999999 ||
                                                                        Number(target.value) == 0
                                                                    ) {
                                                                        setDays(Math.max(0, Number(target.value)))
                                                                        setTimeFilter(`Last ${target.value || 0} Days`)
                                                                        setShowSpecificTimeFilters(
                                                                            `last ${target.value || 0} days`
                                                                        )
                                                                        setStartDate(
                                                                            new Date(
                                                                                new Date().getFullYear(),
                                                                                new Date().getMonth(),
                                                                                new Date().getDate() -
                                                                                    Number(target.value)
                                                                            )
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                            <button
                                                                onClick={() => {
                                                                    setTimeFilter('Last 7 Days')
                                                                    setShowSpecificTimeFilters('last 7 days')
                                                                    setDays(7)
                                                                    setStartDate(
                                                                        new Date(
                                                                            new Date().getFullYear(),
                                                                            new Date().getMonth(),
                                                                            new Date().getDate() - 7
                                                                        )
                                                                    )
                                                                }}
                                                            >
                                                                7 days
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    setTimeFilter('Last 30 Days')
                                                                    setShowSpecificTimeFilters('last 30 days')
                                                                    setDays(30)
                                                                    setStartDate(
                                                                        new Date(
                                                                            new Date().getFullYear(),
                                                                            new Date().getMonth(),
                                                                            new Date().getDate() - 30
                                                                        )
                                                                    )
                                                                }}
                                                            >
                                                                30 days
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    setTimeFilter('Last 90 Days')
                                                                    setShowSpecificTimeFilters('last 90 days')
                                                                    setDays(90)
                                                                    setStartDate(
                                                                        new Date(
                                                                            new Date().getFullYear(),
                                                                            new Date().getMonth(),
                                                                            new Date().getDate() - 90
                                                                        )
                                                                    )
                                                                }}
                                                            >
                                                                90 days
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {(showSpecificTimeFilters === 'year' ||
                                                    showSpecificTimeFilters === 'quarter' ||
                                                    showSpecificTimeFilters === 'month') && (
                                                    <div>
                                                        <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                            <label>Year</label>
                                                            <select
                                                                className='dark:bg-darkness'
                                                                value={year}
                                                                onChange={({ target }) => {
                                                                    setYear(Number(target.value))
                                                                    showSpecificTimeFilters === 'year'
                                                                        ? (() => {
                                                                              setTimeFilter(target.value)
                                                                              handleQuickDateRange(Number(target.value))
                                                                          })()
                                                                        : showSpecificTimeFilters === 'quarter'
                                                                          ? (() => {
                                                                                setTimeFilter(
                                                                                    `${target.value} Q${quarter}`
                                                                                )
                                                                                handleQuickDateRange(
                                                                                    Number(target.value),
                                                                                    quarter
                                                                                )
                                                                            })()
                                                                          : (() => {
                                                                                setTimeFilter(
                                                                                    `${target.value} ${months[
                                                                                        month
                                                                                    ].name.slice(0, 3)}`
                                                                                )
                                                                                handleQuickDateRange(
                                                                                    Number(target.value),
                                                                                    undefined,
                                                                                    month
                                                                                )
                                                                            })()
                                                                }}
                                                            >
                                                                {years.map((year) => (
                                                                    <option value={year}>{year}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {showSpecificTimeFilters === 'year' && (
                                                            <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                                <button
                                                                    onClick={() => {
                                                                        handleQuickDateRange(new Date().getFullYear())
                                                                        setYear(new Date().getFullYear())
                                                                        setTimeFilter(String(new Date().getFullYear()))
                                                                    }}
                                                                >
                                                                    This Year
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {showSpecificTimeFilters === 'quarter' && (
                                                    <div>
                                                        <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                            <label>Quarter</label>
                                                            <select
                                                                className='dark:bg-darkness w-[100%]'
                                                                value={quarter}
                                                                onChange={({ target }) => {
                                                                    handleQuickDateRange(year, Number(target.value))
                                                                    setQuarter(parseInt(target.value))
                                                                    setMonth((Number(target.value) - 1) * 3)
                                                                    setTimeFilter(`${year} Q${target.value}`)
                                                                }}
                                                            >
                                                                {quarters.map((q) => (
                                                                    <option value={q}>Q{q}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                            <button
                                                                onClick={() => {
                                                                    handleQuickDateRange(
                                                                        new Date().getFullYear(),
                                                                        Math.ceil((new Date().getMonth() + 1) / 3)
                                                                    )
                                                                    setYear(new Date().getFullYear())
                                                                    setMonth(new Date().getMonth())
                                                                    setQuarter(
                                                                        Math.ceil((new Date().getMonth() + 1) / 3)
                                                                    )
                                                                    setTimeFilter(
                                                                        `${new Date().getFullYear()} Q${Math.ceil(
                                                                            (new Date().getMonth() + 1) / 3
                                                                        )}`
                                                                    )
                                                                }}
                                                            >
                                                                This Quarter
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {showSpecificTimeFilters === 'month' && (
                                                    <div>
                                                        <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                            <label>Month</label>
                                                            <select
                                                                className='dark:bg-darkness w-[100%]'
                                                                value={month}
                                                                onChange={({ target }) => {
                                                                    const value = Number(target.value)
                                                                    handleQuickDateRange(year, undefined, value)
                                                                    setMonth(value)
                                                                    setTimeFilter(
                                                                        `${year} ${months[value].name.slice(0, 3)}`
                                                                    )
                                                                }}
                                                            >
                                                                {months.map((m, i) => (
                                                                    <option value={i}>{months[i].name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                            <button
                                                                onClick={() => {
                                                                    handleQuickDateRange(
                                                                        new Date().getFullYear(),
                                                                        undefined,
                                                                        new Date().getMonth()
                                                                    )
                                                                    setYear(new Date().getFullYear())
                                                                    setQuarter(
                                                                        Math.ceil((new Date().getMonth() + 1) / 3)
                                                                    )
                                                                    setMonth(new Date().getMonth())
                                                                    setTimeFilter(
                                                                        `${new Date().getFullYear()} ${months[
                                                                            new Date().getMonth()
                                                                        ].name.slice(0, 3)}`
                                                                    )
                                                                }}
                                                            >
                                                                This Month
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                <div>
                                    <div className='flex flex-col justify-start gap-[4px] bg-white dark:bg-darkaccent shadow-small rounded [&>button]:text-left [&>button:hover]:bg-offwhite [&>button:hover]:dark:bg-darkgrey  [&>button]:px-[16px] [&>button]:py-[4px] [&>button]:whitespace-nowrap [&>button]:relative'>
                                        <button
                                            onClick={() => {
                                                setShowSpecificTimeFilters('MTD')
                                                setTimeFilter('Month To Date')
                                                setStartDate(
                                                    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                                                )
                                                setEndDate(
                                                    new Date(
                                                        new Date().getFullYear(),
                                                        new Date().getMonth(),
                                                        new Date().getDate(),
                                                        23,
                                                        59,
                                                        59
                                                    )
                                                )
                                            }}
                                        >
                                            Month To Date
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowSpecificTimeFilters('YTD')
                                                setTimeFilter('Year To Date')
                                                setStartDate(new Date(new Date().getFullYear(), 0, 1))
                                                setEndDate(
                                                    new Date(
                                                        new Date().getFullYear(),
                                                        new Date().getMonth(),
                                                        new Date().getDate(),
                                                        23,
                                                        59,
                                                        59
                                                    )
                                                )
                                            }}
                                        >
                                            Year To Date
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowSpecificTimeFilters(`last ${days} days`)
                                                setTimeFilter(`Last ${days} Days`)
                                                setStartDate(
                                                    new Date(
                                                        new Date().getFullYear(),
                                                        new Date().getMonth(),
                                                        new Date().getDate() - days
                                                    )
                                                )
                                                setEndDate(
                                                    new Date(
                                                        new Date().getFullYear(),
                                                        new Date().getMonth(),
                                                        new Date().getDate(),
                                                        23,
                                                        59,
                                                        59
                                                    )
                                                )
                                            }}
                                        >
                                            <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                                &lt;
                                            </span>
                                            Last X Days
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowSpecificTimeFilters('year')
                                                setTimeFilter(String(year))
                                                handleQuickDateRange(year)
                                            }}
                                        >
                                            <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                                &lt;
                                            </span>
                                            By Year
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowSpecificTimeFilters('quarter')
                                                setTimeFilter(`${year} Q${quarter}`)
                                                handleQuickDateRange(year, quarter)
                                            }}
                                        >
                                            <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                                &lt;
                                            </span>
                                            By Quarter
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowSpecificTimeFilters('month')
                                                setTimeFilter(`${year} ${months[month].name.slice(0, 3)}`)
                                                handleQuickDateRange(year, undefined, month)
                                            }}
                                        >
                                            <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                                &lt;
                                            </span>
                                            By Month
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        onClick={() => {
                            setTimeFilter('By Date')
                            setShowSpecificTimeFilters('date')
                        }}
                    >
                        <SalesCalendar
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            getFullDay={true}
                        />
                    </div>
                </div>
            </div>

            {managerMode && (
                <div className='grid gap-[32px] mb-[32px]'>
                    <div>
                        <h2 className='dark:text-offwhite text-[24px] font-bold text-center mb-[16px]'>
                            Team Overview - {timeFilter}
                        </h2>
                        <div className='flex gap-[16px] border-b-[1px] dark:border-darkaccent pb-[32px]'>
                            <div
                                className={`${
                                    JSON.stringify(teamGoal) === JSON.stringify(defaultSalesGoal) ? 'hidden' : 'block'
                                } dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px] dark:border-darkgrey dark:border-[2px]`}
                            >
                                {teamGoal.start_date && (
                                    <div className='flex justify-between'>
                                        <h3 className='text-[20px] font-semibold'>Team Goal</h3>
                                        <span
                                            className={`text-[20px] font-semibold ${
                                                totalATO.total / teamGoal.total! > 1
                                                    ? 'text-[rgb(96,188,26)]'
                                                    : 'text-[orange]'
                                            }`}
                                        >
                                            {totalATO.total / teamGoal.total! > 1
                                                ? '+' + ((totalATO.total / teamGoal.total! - 1) * 100).toFixed(2)
                                                : ((totalATO.total / teamGoal.total!) * 100).toFixed(2)}
                                            %
                                        </span>
                                    </div>
                                )}
                                {teamGoal.start_date && (
                                    <div>
                                        <div className='flex gap-[16px] justify-center w-[254px]'>
                                            <span className='text-end text-[14px]'>
                                                {formatMoney(totalATO.total)} / {formatMoney(teamGoal.total!)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`flex dark:text-offwhite dark:bg-darkaccent p-[16px] rounded mb-[16px]`}
                                >
                                    <canvas style={{ maxHeight: '200px', border: 'none' }} ref={teamGoalChart}></canvas>
                                </div>
                                {teamGoal.start_date && (
                                    <div className='flex justify-center'>
                                        <span className='text-center m-auto'>
                                            {months[teamGoal.start_date.getMonth()].name}{' '}
                                            {teamGoal.start_date.getDate()}, {teamGoal.start_date.getFullYear()} -{' '}
                                            {months[teamGoal.end_date.getMonth()].name} {teamGoal.end_date.getDate()},{' '}
                                            {teamGoal.end_date.getFullYear()}
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className='grow'>
                                <div className='grid grid-cols-[160px_130px_75px_100px] justify-between bg-blue dark:bg-darkaccent text-[12px] font-bold text-white dark:text-offwhite border-[1px] border-darkgrey'>
                                    <div className='p-[8px]'>
                                        <span>Name</span>
                                    </div>
                                    <div className='p-[8px]'>
                                        <span>Total</span>
                                    </div>
                                    <div className='p-[8px]'>
                                        <span>Volume</span>
                                    </div>
                                    <div className='p-[8px]'>
                                        <span>Avg. Order</span>
                                    </div>
                                </div>

                                <div className='border-[1px] border-t-0 border-darkgrey'>
                                    {Object.keys(filteredATO)
                                        .filter((r) => filteredATO[r].volume > 0)
                                        .map((r) => (
                                            <div
                                                onClick={() => setRepName(r)}
                                                className='grid grid-cols-[160px_130px_75px_100px] justify-between text-[14px] hover:bg-[rgb(199,202,209)] [&:nth-child(even)]:bg-[rgb(224,228,235)] [&:nth-child(even)]:hover:bg-[rgb(199,202,209)] dark:[&:nth-child(even)]:bg-darkaccent dark:[&:nth-child(even)]:hover:bg-blue dark:bg-darkness dark:text-offwhite dark:hover:bg-blue cursor-pointer'
                                            >
                                                <div className='p-[8px]'>
                                                    <span>{filteredATO[r].name}</span>
                                                </div>
                                                <div className='p-[8px]'>
                                                    <span>{mFormat(filteredATO[r].total)}</span>
                                                </div>
                                                <div className='p-[8px]'>
                                                    <span>{filteredATO[r].volume}</span>
                                                </div>
                                                <div className='p-[8px]'>
                                                    <span>{mFormat(filteredATO[r].average_order)}</span>
                                                </div>
                                            </div>
                                        ))}
                                    {!loading &&
                                        Object.keys(filteredATO).filter((r) => filteredATO[r].volume > 0).length ===
                                            0 && (
                                            <p className='p-[8px] text-black dark:text-offwhite'>
                                                No sales found for {timeFilter}.
                                            </p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`${
                    repGoals.yearly ||
                    (repGoals.quarterly && Object.values(repGoals.quarterly).some((q) => q.goal)) ||
                    (repGoals.monthly && Object.values(repGoals.monthly).some((m) => m.goal))
                        ? 'block'
                        : 'hidden'
                } border-b-[1px] dark:border-darkaccent pb-[32px] mb-[32px]`}
            >
                <h2 className='dark:text-offwhite text-[24px] font-bold text-center mb-[16px]'>
                    {repName} Goals - {startDate.getFullYear()}
                </h2>
                <div className=''>
                    <div className='flex justify-between gap-[16px] mb-[16px] flex-wrap'>
                        <div
                            className={`${
                                repGoals.yearly ? 'flex' : 'hidden'
                            } dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px] ${
                                showRepYearlyGoalsDetails ? '' : 'pr-0'
                            } dark:border-darkgrey dark:border-[2px]`}
                        >
                            <div className='w-[254px]'>
                                {repGoals.yearly && (
                                    <div className='flex justify-between items-center'>
                                        <h3 className='dark:text-offwhite text-[20px] font-bold'>Yearly Goal</h3>
                                        <span
                                            className={`text-[20px] font-semibold ${
                                                repGoals.yearly.actual / repGoals.yearly.total > 1
                                                    ? 'text-[rgb(96,188,26)]'
                                                    : 'text-[orange]'
                                            }`}
                                        >
                                            {repGoals.yearly.actual / repGoals.yearly.total > 1
                                                ? '+' +
                                                  ((repGoals.yearly.actual / repGoals.yearly.total - 1) * 100).toFixed(
                                                      2
                                                  )
                                                : ((repGoals.yearly.actual / repGoals.yearly.total) * 100).toFixed(2)}
                                            %
                                        </span>
                                    </div>
                                )}
                                {repGoals.yearly && (
                                    <div className='flex justify-center'>
                                        <span className='text-end text-[14px]'>
                                            {mFormat(repGoals.yearly.actual)} / {mFormat(repGoals.yearly.total)}
                                        </span>
                                    </div>
                                )}
                                <div className='flex dark:text-offwhite dark:bg-darkaccent p-[16px] rounded mb-[16px]'>
                                    <canvas
                                        style={{ maxHeight: '200px', border: 'none' }}
                                        ref={repYearlyGoalChart}
                                    ></canvas>
                                </div>
                                <div className='flex justify-center'>
                                    <span className='text-center'>
                                        {repGoals.yearly && repGoals.yearly.start_date.getFullYear()}
                                    </span>
                                </div>
                            </div>
                            {repGoals.yearly && (
                                <div className='flex flex-col justify-center'>
                                    {showRepYearlyGoalsDetails ? (
                                        <div className='flex h-[100%]'>
                                            <div className='flex flex-col justify-center gap-[8px] text-[16px] rounded'>
                                                <div className='flex gap-[8px] p-[8px] justify-between'>
                                                    <h4 className='dark:text-offwhite font-semibold'>
                                                        Business days left:
                                                    </h4>
                                                    <span>
                                                        {getBusinessDays(
                                                            new Date(),
                                                            new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 999)
                                                        )}
                                                    </span>
                                                </div>
                                                <div className='flex gap-[8px] p-[8px] justify-between'>
                                                    <h4 className='dark:text-offwhite w-[100px] font-semibold'>
                                                        Avg. $/d to hit goal:
                                                    </h4>
                                                    <span>
                                                        {mFormat(
                                                            (repGoals.yearly.total - repGoals.yearly.actual) /
                                                                Math.ceil(
                                                                    (new Date(
                                                                        new Date().getFullYear(),
                                                                        11,
                                                                        31,
                                                                        23,
                                                                        59,
                                                                        59,
                                                                        999
                                                                    ).getTime() -
                                                                        new Date().getTime()) /
                                                                        86400000
                                                                )
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className='flex items-center h-[100%] hover:bg-offwhite/50 dark:hover:bg-darkness/20 cursor-pointer rounded duration-400'
                                                onClick={() => {
                                                    setShowRepYearlyGoalsDetails(false)
                                                    if (repQuarterlyGoalsChart)
                                                        repQuarterlyGoalsChart.current.style.width = '432px'
                                                    if (repMonthlyGoalsChart)
                                                        repMonthlyGoalsChart.current.style.width = '100%'
                                                }}
                                            >
                                                <AiFillCaretRight
                                                    className={`dark:fill-offwhite duration-200 ${
                                                        showRepYearlyGoalsDetails ? 'rotate-180' : ''
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className='flex items-center h-[100%] hover:bg-offwhite/50 dark:hover:bg-darkness/20 cursor-pointer rounded duration-400'
                                            onClick={() => {
                                                setShowRepYearlyGoalsDetails(true)
                                                if (repQuarterlyGoalsChart)
                                                    repQuarterlyGoalsChart.current.style.width = '432px'
                                                if (repMonthlyGoalsChart)
                                                    repMonthlyGoalsChart.current.style.width = '100%'
                                            }}
                                        >
                                            <AiFillCaretRight
                                                className={`dark:fill-offwhite duration-200 ${
                                                    showRepYearlyGoalsDetails ? 'rotate-90' : ''
                                                }`}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div
                            className={`${
                                repGoals.quarterly &&
                                (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].goal
                                    ? 'block'
                                    : 'hidden'
                            } grow shrink flex flex-col dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px] dark:border-darkgrey dark:border-[2px]`}
                        >
                            <div className='flex grow gap-[16px]'>
                                <div className={`flex items-center grow shrink border-r-darkgrey/50 border-r-[1px]`}>
                                    <div>
                                        {repGoals.quarterly &&
                                            (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].goal && (
                                                <div className='flex justify-between items-center'>
                                                    <h3 className='dark:text-offwhite text-[20px] font-bold'>
                                                        {startDate.getFullYear() === new Date().getFullYear() &&
                                                        Math.floor(startDate.getMonth() / 3) ===
                                                            Math.floor(new Date().getMonth() / 3)
                                                            ? 'This quarter'
                                                            : `Q${Math.floor(startDate.getMonth() / 3) + 1}`}
                                                    </h3>
                                                    <span
                                                        className={`text-[18px] font-semibold ${
                                                            (repGoals.quarterly as any)[
                                                                Math.floor(startDate.getMonth() / 3)
                                                            ].actual /
                                                                (repGoals.quarterly as any)[
                                                                    Math.floor(startDate.getMonth() / 3)
                                                                ].goal.total >
                                                            1
                                                                ? 'text-[rgb(96,188,26)]'
                                                                : 'text-[orange]'
                                                        }`}
                                                    >
                                                        {(repGoals.quarterly as any)[
                                                            Math.floor(startDate.getMonth() / 3)
                                                        ].actual /
                                                            (repGoals.quarterly as any)[
                                                                Math.floor(startDate.getMonth() / 3)
                                                            ].goal.total >
                                                        1
                                                            ? '+' +
                                                              (
                                                                  ((repGoals.quarterly as any)[
                                                                      Math.floor(startDate.getMonth() / 3)
                                                                  ].actual /
                                                                      (repGoals.quarterly as any)[
                                                                          Math.floor(startDate.getMonth() / 3)
                                                                      ].goal.total -
                                                                      1) *
                                                                  100
                                                              ).toFixed(2)
                                                            : (
                                                                  ((repGoals.quarterly as any)[
                                                                      Math.floor(startDate.getMonth() / 3)
                                                                  ].actual /
                                                                      (repGoals.quarterly as any)[
                                                                          Math.floor(startDate.getMonth() / 3)
                                                                      ].goal.total) *
                                                                  100
                                                              ).toFixed(2)}
                                                        %
                                                    </span>
                                                </div>
                                            )}
                                        {repGoals.quarterly &&
                                            (repGoals.quarterly as any)[Math.floor(startDate.getMonth() / 3)].goal && (
                                                <div className='flex justify-center'>
                                                    <span className='text-end text-[14px]'>
                                                        {mFormat(
                                                            (repGoals.quarterly as any)[
                                                                Math.floor(startDate.getMonth() / 3)
                                                            ].actual
                                                        )}{' '}
                                                        /{' '}
                                                        {mFormat(
                                                            (repGoals.quarterly as any)[
                                                                Math.floor(startDate.getMonth() / 3)
                                                            ].goal.total
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                        <div className='dark:text-offwhite dark:bg-darkaccent p-[16px] rounded w-[254px] mb-[16px]'>
                                            <canvas
                                                style={{ maxHeight: '200px', border: 'none' }}
                                                ref={repQuarterlyGoalsPie}
                                            ></canvas>
                                        </div>
                                        <div className='flex justify-center'>
                                            <span>
                                                {startDate.getFullYear()} Q{Math.floor(startDate.getMonth() / 3) + 1}
                                            </span>
                                        </div>
                                    </div>
                                    {new Date(
                                        endDate.getFullYear(),
                                        endDate.getMonth() + 1,
                                        0,
                                        23,
                                        59,
                                        59,
                                        999
                                    ).getTime() > new Date().getTime() ? (
                                        showRepQuarterlyGoalsDetails ? (
                                            <div className='flex h-[100%]'>
                                                <div className='flex flex-col justify-center gap-[8px] text-[16px] rounded'>
                                                    <div className='flex gap-[8px] p-[8px] justify-between'>
                                                        <h4 className='dark:text-offwhite font-semibold'>
                                                            Business days left:
                                                        </h4>
                                                        <span>
                                                            {getBusinessDays(
                                                                new Date(),
                                                                new Date(
                                                                    startDate.getFullYear(),
                                                                    Math.floor(startDate.getMonth() / 3 + 1) * 3,
                                                                    0,
                                                                    23,
                                                                    59,
                                                                    59,
                                                                    999
                                                                )
                                                            )}
                                                        </span>
                                                    </div>
                                                    {repGoals.quarterly &&
                                                        (repGoals.quarterly as any)[
                                                            Math.floor(startDate.getMonth() / 3)
                                                        ].goal && (
                                                            <div className='flex gap-[8px] p-[8px] justify-between'>
                                                                <h4 className='dark:text-offwhite w-[100px] font-semibold'>
                                                                    Avg. $/d to hit goal:
                                                                </h4>
                                                                <span>
                                                                    {mFormat(
                                                                        ((repGoals.quarterly as any)[
                                                                            Math.floor(startDate.getMonth() / 3)
                                                                        ].goal.total -
                                                                            (repGoals.quarterly as any)[
                                                                                Math.floor(startDate.getMonth() / 3)
                                                                            ].actual) /
                                                                            Math.ceil(
                                                                                (new Date(
                                                                                    new Date().getFullYear(),
                                                                                    Math.floor(
                                                                                        new Date().getMonth() / 3 + 1
                                                                                    ) * 3,
                                                                                    0,
                                                                                    23,
                                                                                    59,
                                                                                    59,
                                                                                    999
                                                                                ).getTime() -
                                                                                    new Date().getTime()) /
                                                                                    86400000
                                                                            )
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                </div>
                                                <div
                                                    className='flex items-center h-[100%] hover:bg-offwhite/50 dark:hover:bg-darkness/20 cursor-pointer rounded duration-400'
                                                    onClick={() => {
                                                        setShowRepQuarterlyGoalsDetails(false)
                                                        if (repQuarterlyGoalsChart)
                                                            repQuarterlyGoalsChart.current.style.width = '432px'
                                                        if (repMonthlyGoalsChart)
                                                            repMonthlyGoalsChart.current.style.width = '100%'
                                                    }}
                                                >
                                                    <AiFillCaretRight
                                                        className={`dark:fill-offwhite duration-200 ${
                                                            showRepQuarterlyGoalsDetails ? 'rotate-180' : ''
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className='flex items-center h-[100%] hover:bg-offwhite/50 dark:hover:bg-darkness/20 cursor-pointer rounded duration-400'
                                                onClick={() => {
                                                    setShowRepQuarterlyGoalsDetails(true)
                                                    if (repQuarterlyGoalsChart)
                                                        repQuarterlyGoalsChart.current.style.width = '432px'
                                                    if (repMonthlyGoalsChart)
                                                        repMonthlyGoalsChart.current.style.width = '100%'
                                                }}
                                            >
                                                <AiFillCaretRight className={`dark:fill-offwhite duration-200`} />
                                            </div>
                                        )
                                    ) : (
                                        <div className='w-[16px]' />
                                    )}
                                </div>
                                <div className='flex flex-col dark:text-offwhite rounded h-[100%] grow justify-between pb-[32px]'>
                                    <h3 className='dark:text-offwhite text-[20px] font-bold mb-[8px]'>
                                        Quarterly Goals - Completed
                                    </h3>
                                    <canvas
                                        style={{ maxHeight: '200px', border: 'none' }}
                                        ref={repQuarterlyGoalsChart}
                                    ></canvas>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${
                                repGoals.monthly && (repGoals.monthly as any)[startDate.getMonth()].goal
                                    ? 'flex'
                                    : 'hidden'
                            } grow shrink dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px] dark:border-darkgrey dark:border-[2px]`}
                        >
                            <div className='flex grow gap-[16px]'>
                                <div className='flex items-center grow border-r-darkgrey/50 border-r-[1px]'>
                                    <div>
                                        <div className='dark:text-offwhite dark:bg-darkaccent rounded w-[254px] mb-[16px]'>
                                            {repGoals.monthly &&
                                                (repGoals.monthly as any)[startDate.getMonth()].goal && (
                                                    <div className='flex justify-between items-center'>
                                                        <h3 className='dark:text-offwhite text-[20px] font-bold'>
                                                            {startDate.getFullYear() === new Date().getFullYear() &&
                                                            startDate.getMonth() === new Date().getMonth()
                                                                ? 'This Month'
                                                                : (repGoals.monthly as any)[startDate.getMonth()].name}
                                                        </h3>
                                                        <span
                                                            className={`text-[18px] font-semibold ${
                                                                (repGoals.monthly as any)[startDate.getMonth()].actual /
                                                                    (repGoals.monthly as any)[startDate.getMonth()].goal
                                                                        .total >
                                                                1
                                                                    ? 'text-[rgb(96,188,26)]'
                                                                    : 'text-[orange]'
                                                            }`}
                                                        >
                                                            {(repGoals.monthly as any)[startDate.getMonth()].actual /
                                                                (repGoals.monthly as any)[startDate.getMonth()].goal
                                                                    .total >
                                                            1
                                                                ? '+' +
                                                                  (
                                                                      ((repGoals.monthly as any)[startDate.getMonth()]
                                                                          .actual /
                                                                          (repGoals.monthly as any)[
                                                                              startDate.getMonth()
                                                                          ].goal.total -
                                                                          1) *
                                                                      100
                                                                  ).toFixed(2)
                                                                : (
                                                                      ((repGoals.monthly as any)[startDate.getMonth()]
                                                                          .actual /
                                                                          (repGoals.monthly as any)[
                                                                              startDate.getMonth()
                                                                          ].goal.total) *
                                                                      100
                                                                  ).toFixed(2)}
                                                            %
                                                        </span>
                                                    </div>
                                                )}
                                            {repGoals.monthly &&
                                                (repGoals.monthly as any)[startDate.getMonth()].goal && (
                                                    <div className='flex justify-center'>
                                                        <span className='text-end text-[14px]'>
                                                            {mFormat(
                                                                (repGoals.monthly as any)[startDate.getMonth()].actual
                                                            )}{' '}
                                                            /{' '}
                                                            {mFormat(
                                                                (repGoals.monthly as any)[startDate.getMonth()].goal
                                                                    .total
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                            <div className='relative'>
                                                <canvas
                                                    style={{ maxHeight: '200px', border: 'none', zIndex: '1' }}
                                                    ref={repMonthlyGoalsPie}
                                                ></canvas>
                                                {repGoals.monthly &&
                                                    (repGoals.monthly as any)[startDate.getMonth()].goal &&
                                                    (repGoals.monthly as any)[startDate.getMonth()].actual >
                                                        (repGoals.monthly as any)[startDate.getMonth()].goal.total && (
                                                        <div
                                                            className={`absolute bg-darkness/50 text-white dark:bg-darkness/90 rounded p-[8px] w-[80%] top-[35px] left-[50%] translate-x-[-50%]`}
                                                        >
                                                            <span>
                                                                Great job! You've completed your goal for this period.
                                                                All additional sales will earn you bonus compensation
                                                                for this period.
                                                            </span>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        {repGoals.monthly && (
                                            <div className='flex justify-center'>
                                                <span>
                                                    {new Date().getFullYear()}{' '}
                                                    {(repGoals.monthly as any)[startDate.getMonth()].name}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {new Date(
                                        endDate.getFullYear(),
                                        endDate.getMonth() + 1,
                                        0,
                                        23,
                                        59,
                                        59,
                                        999
                                    ).getTime() > new Date().getTime() ? (
                                        showRepMonthlyGoalsDetails ? (
                                            <div className='flex h-[100%]'>
                                                <div className='flex flex-col justify-center gap-[8px] text-[16px] rounded'>
                                                    <div className='flex gap-[8px] p-[8px] justify-between'>
                                                        <h4 className='dark:text-offwhite w-[80px] font-semibold'>
                                                            Days left:
                                                        </h4>
                                                        <span>
                                                            {getBusinessDays(
                                                                new Date(),
                                                                new Date(
                                                                    startDate.getFullYear(),
                                                                    startDate.getMonth() + 1,
                                                                    0,
                                                                    23,
                                                                    59,
                                                                    59,
                                                                    999
                                                                )
                                                            )}
                                                        </span>
                                                    </div>
                                                    {repGoals.monthly &&
                                                        (repGoals.monthly as any)[startDate.getMonth()].goal && (
                                                            <div className='flex gap-[8px] p-[8px] justify-between'>
                                                                <h4 className='dark:text-offwhite w-[100px] font-semibold'>
                                                                    Avg. $/d to hit goal:
                                                                </h4>
                                                                <span>
                                                                    {formatMoney(
                                                                        ((repGoals.monthly as any)[startDate.getMonth()]
                                                                            .goal.total -
                                                                            (repGoals.monthly as any)[
                                                                                startDate.getMonth()
                                                                            ].actual) /
                                                                            ((new Date(
                                                                                new Date().getFullYear(),
                                                                                new Date().getMonth() + 1,
                                                                                0,
                                                                                23,
                                                                                59,
                                                                                59,
                                                                                999
                                                                            ).getTime() -
                                                                                new Date().getTime()) /
                                                                                86400000)
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                </div>
                                                <div
                                                    className='flex items-center h-[100%] hover:bg-offwhite/50 dark:hover:bg-darkness/20 cursor-pointer rounded duration-400'
                                                    onClick={() => {
                                                        setShowRepMonthlyGoalsDetails(false)
                                                        if (repQuarterlyGoalsChart)
                                                            repQuarterlyGoalsChart.current.style.width = '432px'
                                                        if (repMonthlyGoalsChart)
                                                            repMonthlyGoalsChart.current.style.width = '100%'
                                                    }}
                                                >
                                                    <AiFillCaretRight
                                                        className={`dark:fill-offwhite duration-200 ${
                                                            showRepMonthlyGoalsDetails ? 'rotate-180' : ''
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className='flex items-center h-[100%] hover:bg-offwhite/50 dark:hover:bg-darkness/20 cursor-pointer rounded duration-400'
                                                onClick={() => {
                                                    setShowRepMonthlyGoalsDetails(true)
                                                    if (repQuarterlyGoalsChart)
                                                        repQuarterlyGoalsChart.current.style.width = '432px'
                                                    if (repMonthlyGoalsChart)
                                                        repMonthlyGoalsChart.current.style.width = '100%'
                                                }}
                                            >
                                                <AiFillCaretRight
                                                    className={`dark:fill-offwhite duration-200 ${
                                                        showRepMonthlyGoalsDetails ? 'rotate-180' : ''
                                                    }`}
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <div className='w-[16px]' />
                                    )}
                                </div>
                                <div className='flex flex-col justify-between dark:text-offwhite rounded h-[auto] pb-[32px] grow'>
                                    <h3 className='dark:text-offwhite text-[20px] font-bold mb-[8px]'>
                                        Monthly Goals - Completed
                                    </h3>
                                    <canvas
                                        style={{ maxHeight: '200px', border: 'none' }}
                                        ref={repMonthlyGoalsChart}
                                    ></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className='dark:text-offwhite text-[24px] font-bold text-center mb-[16px]'>
                {repName} Detailed - {timeFilter}
            </h1>

            <div className='dark:text-offwhite'>
                <div className='flex items-center justify-between dark:text-offwhite'>
                    <h3 className='text-[24px] font-light tracking-[2px] uppercase mb-[8px]'>
                        {timeFilter} Sales -{' '}
                        <span className='text-[#60bc1a] font-black tracking-[0px]'>{mFormat(total)}</span>
                    </h3>
                </div>
            </div>

            <div className='flex gap-[16px] mb-[16px]'>
                {!managerMode && (
                    <div
                        className={`${
                            teamGoal.start_date ? 'block' : 'hidden'
                        } dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px]`}
                    >
                        {teamGoal.start_date && (
                            <div className='flex justify-between w-[254px]'>
                                <h3 className='text-[20px] font-semibold'>Team Goal</h3>
                                <span
                                    className={`text-[20px] font-semibold ${
                                        totalATO.total / teamGoal.total! > 1 ? 'text-[rgb(96,188,26)]' : 'text-[orange]'
                                    }`}
                                >
                                    {totalATO.total / teamGoal.total! > 1
                                        ? '+' + ((totalATO.total / teamGoal.total! - 1) * 100).toFixed(2)
                                        : ((totalATO.total / teamGoal.total!) * 100).toFixed(2)}
                                    %
                                </span>
                            </div>
                        )}
                        {teamGoal.start_date && (
                            <div>
                                <div className='flex gap-[16px] justify-center'>
                                    <span className='text-end text-[14px]'>
                                        {formatMoney(totalATO.total)} / {formatMoney(teamGoal.total!)}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className='flex dark:text-offwhite dark:bg-darkaccent p-[16px] rounded mb-[16px]'>
                            <canvas style={{ maxHeight: '200px', border: 'none' }} ref={teamGoalChart}></canvas>
                        </div>
                        {teamGoal.start_date && (
                            <div className='flex justify-center'>
                                <span className='text-center m-auto'>
                                    {months[teamGoal.start_date.getMonth()].name} {teamGoal.start_date.getDate()},{' '}
                                    {teamGoal.start_date.getFullYear()} - {months[teamGoal.end_date.getMonth()].name}{' '}
                                    {teamGoal.end_date.getDate()}, {teamGoal.end_date.getFullYear()}
                                </span>
                            </div>
                        )}
                    </div>
                )}

                <div>
                    <div className='flex gap-[16px] flex-wrap'>
                        <div className='dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px]'>
                            <h3 className='text-[20px] font-semibold'>Rep Sales</h3>
                            <div className='flex gap-x-[16px]'>
                                <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                    <h3 className='text-[18px]'>Total</h3>
                                    <div className='flex gap-[16px]'>
                                        <span className='text-end'>{mFormat(total)}</span>
                                    </div>
                                </div>
                                <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                    <h3 className='text-[18px]'>Volume</h3>
                                    <div className='flex gap-[16px] justify-between'>
                                        <span className='text-end'>{filteredOrders.length}</span>
                                    </div>
                                </div>
                                <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                    <h3 className='text-[18px]'>Avg. Order</h3>
                                    <div className='flex gap-[16px]'>
                                        <span className='text-end'>{mFormat(total / filteredOrders.length)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px] grow'>
                            <h2 className='text-[20px] font-semibold'>vs. Team Average</h2>
                            <div className='flex gap-x-[16px]'>
                                <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                    <h3 className='text-[18px]'>Total</h3>
                                    <div className='flex gap-[16px] justify-between'>
                                        <span className='text-end'>{mFormat(totalATO.total / reps.length)}</span>
                                        <span
                                            style={{
                                                color: `${
                                                    totalATO.percentDifference > 0
                                                        ? `rgb(${
                                                              (settings.theme === 'dark' ? 200 : 100) -
                                                              (totalATO.percentDifference * 255) / 100
                                                          }, ${
                                                              (totalATO.percentDifference * 255) / 100 +
                                                              (settings.theme === 'dark' ? 200 : 100)
                                                          }, ${
                                                              (settings.theme === 'dark' ? 200 : 100) -
                                                              (totalATO.percentDifference * 255) / 100
                                                          })`
                                                        : `rgb(${
                                                              (-totalATO.percentDifference * 255) / 100 +
                                                              (settings.theme === 'dark' ? 200 : 100)
                                                          }, ${
                                                              (settings.theme === 'dark' ? 150 : 100) +
                                                              (totalATO.percentDifference * 255) / 100
                                                          }, ${
                                                              (settings.theme === 'dark' ? 150 : 100) +
                                                              (totalATO.percentDifference * 255) / 100
                                                          })`
                                                }`,
                                            }}
                                        >
                                            {totalATO.percentDifference > 0 && '+'}
                                            {totalATO.percentDifference}%
                                        </span>
                                    </div>
                                </div>
                                <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                    <h3 className='text-[18px]'>Volume</h3>
                                    <div className='flex gap-[16px] justify-between'>
                                        <span className='text-end'>
                                            {totalATO.averageVolume ? totalATO.averageVolume.toFixed(2) : ''}
                                        </span>
                                        <span
                                            style={{
                                                color: `${
                                                    totalATO.avgVolDif > 0
                                                        ? `rgb(${
                                                              (settings.theme === 'dark' ? 200 : 100) -
                                                              (totalATO.avgVolDif * 255) / 100
                                                          }, ${
                                                              (totalATO.avgVolDif * 255) / 100 +
                                                              (settings.theme === 'dark' ? 200 : 100)
                                                          }, ${
                                                              (settings.theme === 'dark' ? 200 : 100) -
                                                              (totalATO.avgVolDif * 255) / 100
                                                          })`
                                                        : `rgb(${
                                                              (-totalATO.avgVolDif * 255) / 100 +
                                                              (settings.theme === 'dark' ? 200 : 100)
                                                          }, ${
                                                              (settings.theme === 'dark' ? 150 : 100) +
                                                              (totalATO.avgVolDif * 255) / 100
                                                          }, ${
                                                              (settings.theme === 'dark' ? 150 : 100) +
                                                              (totalATO.avgVolDif * 255) / 100
                                                          })`
                                                }`,
                                            }}
                                        >
                                            {totalATO.avgVolDif > 0 && '+'}
                                            {totalATO.avgVolDif}%
                                        </span>
                                    </div>
                                </div>
                                <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                    <h3 className='text-[18px]'>Avg. Order</h3>
                                    <div className='flex gap-[16px] justify-between'>
                                        <span className='text-end'>
                                            {mFormat(totalATO.total / reps.length / totalATO.averageVolume)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {previousPeriod.orders.length > 0 && (
                            <div className='dark:text-offwhite bg-white dark:bg-darkaccent shadow-small rounded p-[16px] grow'>
                                <h2 className='text-[20px] font-semibold'>vs. Previous Sales</h2>
                                <div className='flex gap-x-[32px] gap-y-[16px] flex-wrap'>
                                    <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                        <h3 className='text-[18px]'>
                                            Previous{' '}
                                            {showSpecificTimeFilters === 'date'
                                                ? Math.ceil((endDate.getTime() - startDate.getTime()) / 86400000) +
                                                  ' days'
                                                : showSpecificTimeFilters}
                                        </h3>
                                        <div className='flex gap-x-[16px]'>
                                            <div className='bg-white/50 dark:bg-darkness p-[8px] rounded'>
                                                <h4>Total</h4>
                                                <div className='flex gap-[16px]'>
                                                    <span className='text-end'>{mFormat(previousPeriod.total)}</span>
                                                    <span
                                                        style={{
                                                            color: `${
                                                                previousPeriod.totalDif >= 0
                                                                    ? `rgb(${
                                                                          (settings.theme === 'dark' ? 200 : 100) -
                                                                          (previousPeriod.totalDif * 255) / 100
                                                                      }, ${
                                                                          (previousPeriod.totalDif * 255) / 100 +
                                                                          (settings.theme === 'dark' ? 200 : 100)
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 200 : 100) -
                                                                          (previousPeriod.totalDif * 255) / 100
                                                                      })`
                                                                    : `rgb(${
                                                                          (-previousPeriod.totalDif * 255) / 100 +
                                                                          (settings.theme === 'dark' ? 200 : 100)
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 150 : 100) +
                                                                          (previousPeriod.totalDif * 255) / 100
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 150 : 100) +
                                                                          (previousPeriod.totalDif * 255) / 100
                                                                      })`
                                                            }`,
                                                        }}
                                                    >
                                                        {previousPeriod.totalDif >= 0 &&
                                                            isFinite(previousPeriod.totalDif) &&
                                                            '+'}
                                                        {isFinite(previousPeriod.totalDif)
                                                            ? previousPeriod.totalDif + '%'
                                                            : ''}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='bg-white/50 dark:bg-darkness p-[8px] rounded'>
                                                <h4>Volume</h4>
                                                <div className='flex gap-[16px]'>
                                                    <span className='text-end'>{previousPeriod.volume}</span>
                                                    <span
                                                        style={{
                                                            color: `${
                                                                previousPeriod.volDif >= 0
                                                                    ? `rgb(${
                                                                          (settings.theme === 'dark' ? 200 : 100) -
                                                                          (previousPeriod.volDif * 255) / 100
                                                                      }, ${
                                                                          (previousPeriod.volDif * 255) / 100 +
                                                                          (settings.theme === 'dark' ? 200 : 100)
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 200 : 100) -
                                                                          (previousPeriod.volDif * 255) / 100
                                                                      })`
                                                                    : `rgb(${
                                                                          (-previousPeriod.volDif * 255) / 100 +
                                                                          (settings.theme === 'dark' ? 200 : 100)
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 150 : 100) +
                                                                          (previousPeriod.volDif * 255) / 100
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 150 : 100) +
                                                                          (previousPeriod.volDif * 255) / 100
                                                                      })`
                                                            }`,
                                                        }}
                                                    >
                                                        {previousPeriod.volDif >= 0 &&
                                                            isFinite(previousPeriod.volDif) &&
                                                            '+'}
                                                        {isFinite(previousPeriod.volDif)
                                                            ? previousPeriod.volDif + '%'
                                                            : ''}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='bg-white/50 dark:bg-darkness p-[8px] rounded'>
                                                <h3 className='text-[18px]'>Avg. Order</h3>
                                                <div className='flex gap-[16px] justify-between'>
                                                    <span className='text-end'>{mFormat(previousPeriod.avgTotal)}</span>
                                                    <span
                                                        style={{
                                                            color: `${
                                                                previousPeriod.avgTotDif > 0
                                                                    ? `rgb(${
                                                                          (settings.theme === 'dark' ? 200 : 100) -
                                                                          (previousPeriod.avgTotDif * 255) / 100
                                                                      }, ${
                                                                          (previousPeriod.avgTotDif * 255) / 100 +
                                                                          (settings.theme === 'dark' ? 200 : 100)
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 200 : 100) -
                                                                          (previousPeriod.avgTotDif * 255) / 100
                                                                      })`
                                                                    : `rgb(${
                                                                          (-previousPeriod.avgTotDif * 255) / 100 +
                                                                          (settings.theme === 'dark' ? 200 : 100)
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 150 : 100) +
                                                                          (previousPeriod.avgTotDif * 255) / 100
                                                                      }, ${
                                                                          (settings.theme === 'dark' ? 150 : 100) +
                                                                          (previousPeriod.avgTotDif * 255) / 100
                                                                      })`
                                                            }`,
                                                        }}
                                                    >
                                                        {previousPeriod.avgTotDif > 0 && '+'}
                                                        {previousPeriod.avgTotDif}%
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {lastYearPeriod.orders.length > 0 &&
                                        showSpecificTimeFilters !== 'year' &&
                                        showSpecificTimeFilters !== 'YTD' && (
                                            <div className='bg-[whitesmoke] dark:bg-darkness/50 p-[8px] rounded'>
                                                <h3 className='text-[18px]'>
                                                    {year - 1} {showSpecificTimeFilters === 'quarter' && 'Q' + quarter}{' '}
                                                    {showSpecificTimeFilters === 'MTD' &&
                                                        `${
                                                            months[startDate.getMonth()].name
                                                        } ${startDate.getDate()} - ${
                                                            months[endDate.getMonth()].name
                                                        } ${endDate.getDate()}`}{' '}
                                                    {lastXDays &&
                                                        `${months[prevYST.getMonth()].name} ${prevYST.getDate()} - ${
                                                            months[prevYET.getMonth()].name
                                                        } ${prevYET.getDate()}`}{' '}
                                                    {monthly && months[prevYET.getMonth()].name}
                                                </h3>
                                                <div className='flex gap-x-[16px]'>
                                                    <div className='bg-white/50 dark:bg-darkness p-[8px] rounded'>
                                                        <h4>Total</h4>
                                                        <div className='flex gap-[16px]'>
                                                            <span className='text-end'>
                                                                {mFormat(lastYearPeriod.total)}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    color: `${
                                                                        lastYearPeriod.totalDif >= 0
                                                                            ? `rgb(${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100) -
                                                                                  (lastYearPeriod.totalDif * 255) / 100
                                                                              }, ${
                                                                                  (lastYearPeriod.totalDif * 255) /
                                                                                      100 +
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100)
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100) -
                                                                                  (lastYearPeriod.totalDif * 255) / 100
                                                                              })`
                                                                            : `rgb(${
                                                                                  (-lastYearPeriod.totalDif * 255) /
                                                                                      100 +
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100)
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 150
                                                                                      : 100) +
                                                                                  (lastYearPeriod.totalDif * 255) / 100
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 150
                                                                                      : 100) +
                                                                                  (lastYearPeriod.totalDif * 255) / 100
                                                                              })`
                                                                    }`,
                                                                }}
                                                            >
                                                                {lastYearPeriod.totalDif >= 0 &&
                                                                    isFinite(lastYearPeriod.totalDif) &&
                                                                    '+'}
                                                                {isFinite(lastYearPeriod.totalDif)
                                                                    ? lastYearPeriod.totalDif + '%'
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='bg-white/50 dark:bg-darkness p-[8px] rounded'>
                                                        <h4>Volume</h4>
                                                        <div className='flex gap-[16px]'>
                                                            <span className='text-end'>{lastYearPeriod.volume}</span>
                                                            <span
                                                                style={{
                                                                    color: `${
                                                                        lastYearPeriod.volDif >= 0
                                                                            ? `rgb(${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100) -
                                                                                  (lastYearPeriod.volDif * 255) / 100
                                                                              }, ${
                                                                                  (lastYearPeriod.volDif * 255) / 100 +
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100)
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100) -
                                                                                  (lastYearPeriod.volDif * 255) / 100
                                                                              })`
                                                                            : `rgb(${
                                                                                  (-lastYearPeriod.volDif * 255) / 100 +
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100)
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 150
                                                                                      : 100) +
                                                                                  (lastYearPeriod.volDif * 255) / 100
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 150
                                                                                      : 100) +
                                                                                  (lastYearPeriod.volDif * 255) / 100
                                                                              })`
                                                                    }`,
                                                                }}
                                                            >
                                                                {lastYearPeriod.volDif >= 0 &&
                                                                    isFinite(lastYearPeriod.volDif) &&
                                                                    '+'}
                                                                {isFinite(lastYearPeriod.volDif)
                                                                    ? lastYearPeriod.volDif + '%'
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='bg-white/50 dark:bg-darkness p-[8px] rounded'>
                                                        <h3 className='text-[18px]'>Avg. Order</h3>
                                                        <div className='flex gap-[16px] justify-between'>
                                                            <span className='text-end'>
                                                                {mFormat(lastYearPeriod.avgTotal)}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    color: `${
                                                                        lastYearPeriod.avgTotDif > 0
                                                                            ? `rgb(${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100) -
                                                                                  (lastYearPeriod.avgTotDif * 255) / 100
                                                                              }, ${
                                                                                  (lastYearPeriod.avgTotDif * 255) /
                                                                                      100 +
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100)
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100) -
                                                                                  (lastYearPeriod.avgTotDif * 255) / 100
                                                                              })`
                                                                            : `rgb(${
                                                                                  (-lastYearPeriod.avgTotDif * 255) /
                                                                                      100 +
                                                                                  (settings.theme === 'dark'
                                                                                      ? 200
                                                                                      : 100)
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 150
                                                                                      : 100) +
                                                                                  (lastYearPeriod.avgTotDif * 255) / 100
                                                                              }, ${
                                                                                  (settings.theme === 'dark'
                                                                                      ? 150
                                                                                      : 100) +
                                                                                  (lastYearPeriod.avgTotDif * 255) / 100
                                                                              })`
                                                                    }`,
                                                                }}
                                                            >
                                                                {lastYearPeriod.avgTotDif > 0 && '+'}
                                                                {lastYearPeriod.avgTotDif}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='flex dark:text-offwhite dark:bg-darkaccent dark:border-darkgrey dark:border-[2px] p-[16px] rounded mb-[16px]'>
                <canvas style={{ maxHeight: '450px' }} ref={repSalesChart}></canvas>
            </div>

            <div className='grid gap-[16px]'>
                {months
                    .slice()
                    .reverse()
                    .map(
                        (month, m: number) =>
                            months[m].orders.length > 0 && (
                                <div className='dark:bg-darkaccent/50 shadow-small rounded duration-200'>
                                    <div
                                        className='flex cursor-pointer items-center gap-[16px] px-[16px] py-[8px] hover:bg-[whitesmoke] dark:hover:bg-darkaccent duration-100 rounded-t'
                                        onClick={() => {
                                            showMonths.includes(m)
                                                ? setShowMonths(showMonths.filter((selectedM) => m !== selectedM))
                                                : setShowMonths([...showMonths, m])
                                        }}
                                    >
                                        <span className='dark:text-offwhite text-[32px] w-[170px]'>
                                            {months[m].name}
                                        </span>
                                        <AiFillCaretRight
                                            className={`dark:fill-offwhite duration-200 ${
                                                showMonths.includes(m) ? 'rotate-90' : ''
                                            }`}
                                        />
                                    </div>
                                    <div
                                        className={`${
                                            showMonths.includes(m) ? 'max-h-[none] p-[16px]' : 'max-h-[0px] p-[0px]'
                                        } grid gap-[16px] duration-200`}
                                    >
                                        {months[m].orders.map((o: any) => (
                                            <div
                                                className={`${
                                                    showMonths.includes(m) ? 'max-h-[none] p-[16px]' : 'max-h-0 p-[0px]'
                                                } overflow-hidden grid grid-cols-[1fr_1fr_1fr_1fr] gap-[32px] rounded shadow-small dark:bg-darkaccent duration-200 hover:bg-[lightgreen]/30 dark:hover:bg-[lightgreen]/20 cursor-pointer`}
                                                onContextMenu={() => {
                                                    window.open(`/orders/${o.id}`)
                                                }}
                                                onClick={() => {
                                                    ;(window.event as any).ctrlKey
                                                        ? window.open(`/orders/${o.id}`)
                                                        : navigate(`/orders/${o.id}`)
                                                }}
                                            >
                                                <p className='font-light uppercase dark:text-offwhite'>
                                                    {new Date(o.created_at).toDateString()}
                                                </p>
                                                <p className='font-bold text-[#4a4a4a] dark:text-offwhite'>
                                                    {o.order_name}
                                                </p>
                                                <p className='font-bold text-[#4a4a4a] dark:text-offwhite'>
                                                    {o.draft_order_name}
                                                </p>
                                                <p className='justify-self-end text-[#60bc1a] font-black text-[18px]'>
                                                    {formatMoney(o.total_price)}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                    )}
            </div>
        </>
    )
}
