import { FaRegStar, FaStar, FaTrash, FaUserCircle } from 'react-icons/fa'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { projectStatusDisplayMap } from '../constants'
import { MdOutlineHexagon } from 'react-icons/md'
import { monthDateShortFormatter } from '../helpers'
import { useEffect, useRef, useState } from 'react'
import { useDeleteProject, useRoadmaps, useUpdateProject } from '../api/useQueries'
import { FetchOptionSelector } from './FetchOptionSelector'

export default function ProjectRow({ project, last }: { project: any; last?: boolean }) {
    /* REFS */
    const statusRef = useRef<HTMLDivElement>(null)
    const projectLeadRef = useRef<HTMLDivElement>(null)

    /* STATE | QUERIES */
    const [popover, setPopover] = useState<undefined | string>()
    const [dismountingPopover, setDismountingPopover] = useState(false)
    const usersQuery = useAllUsersQuery()
    const updateProjectMutation = useUpdateProject()
    const deleteProjectMutation = useDeleteProject()

    const users: UserInit[] = usersQuery?.data?.users || []
    const foundUser = users.find((user) => user.id === project.project_lead)
    const createdAt = monthDateShortFormatter.format(new Date(project.created_at))
    const updatedAt = monthDateShortFormatter.format(new Date(project.updated_at))
    const { icon: Status, className: statusClassName } = projectStatusDisplayMap[project.status] || {
        icon: MdOutlineHexagon,
        class: '',
    }

    /* FUNCTIONS */
    const handleUpdateProject = (projectUpdate: any) => {
        if (JSON.stringify(project) === JSON.stringify({ ...project, ...projectUpdate })) {
            return
        }
        updateProjectMutation.mutate(projectUpdate, {
            onSuccess: () => {
                setPopover(undefined)
            },
        })
    }

    const handleDeleteProject = async () => {
        deleteProjectMutation.mutate(project.id)
    }

    const handleTogglePopover = (target: string) => {
        if (target !== popover) {
            return setPopover(target)
        }
        setDismountingPopover(true)
        setTimeout(() => {
            setPopover(undefined)
            setDismountingPopover(false)
        }, 200)
    }

    /* USEEFFECTS */
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (
                !statusRef.current?.contains(e.target as HTMLElement) &&
                !projectLeadRef.current?.contains(e.target as HTMLElement)
            ) {
                setDismountingPopover(true)
                setTimeout(() => {
                    setPopover(undefined)
                    setDismountingPopover(false)
                }, 200)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            className={`${
                deleteProjectMutation.isPending ? 'opacity-50 pointer-events-none' : ''
            } hover:bg-lightgrey/50 dark:hover:bg-darkaccent/50 flex gap-4 items-center text-[14px] py-2 px-4 ${
                !last ? 'border-b border-lightgrey dark:border-darkaccent' : ''
            }`}
        >
            <p className='shrink-0 text-darkgrey w-4 text-[12px] font-medium font-robotomono'>{project.id}</p>
            <div ref={statusRef} className='relative cursor-pointer' title='Change the task status'>
                <Status onClick={() => handleTogglePopover('status')} className={`${statusClassName} shrink-0`} />
                {popover === 'status' && (
                    <FetchOptionSelector
                        options={Object.keys(projectStatusDisplayMap)}
                        selectedOption={project.status}
                        onClick={(option: string) => handleUpdateProject({ id: project.id, status: option })}
                        isPending={updateProjectMutation.isPending}
                        className='left-0 translate-x-0'
                        searchText='Update status...'
                        dismountingPopover={dismountingPopover}
                    />
                )}
            </div>
            <p className='font-semibold w-full'>{project.title}</p>
            <button
                className={`${
                    updateProjectMutation.isPending
                        ? 'opacity-50 pointer-events-none'
                        : 'dark:opacity-75 dark:hover:opacity-100 transition-all'
                }`}
                onClick={() => handleUpdateProject({ id: project.id, pinned: !project.pinned })}
            >
                {project.pinned ? <FaStar /> : <FaRegStar />}
            </button>
            <p className='shrink-0 text-[12px]'>{createdAt}</p>
            <p className='shrink-0 text-[12px]'>{updatedAt}</p>
            <div ref={projectLeadRef} className='relative w-4 shrink-0 cursor-pointer' title='Assign a project lead'>
                <button
                    className='flex items-center justify-center'
                    onClick={() => handleTogglePopover('project_lead')}
                >
                    {foundUser ? (
                        <div className='w-4 h-4 text-[9px] flex justify-center items-center bg-blue text-white dark:bg-accent rounded-full dark:text-darkaccent font-bold'>
                            {foundUser.first_name[0]}
                            {foundUser.last_name[0]}
                        </div>
                    ) : (
                        <FaUserCircle className='w-4 h-4 opacity-50' />
                    )}
                </button>

                {popover === 'project_lead' && (
                    <FetchOptionSelector
                        options={[
                            { label: 'Unassigned', value: null },
                            ...users
                                .filter((user) => user.roles.includes('developer'))
                                .map((user) => ({
                                    label: `${user.first_name} ${user.last_name}`,
                                    value: user.id.toString(),
                                })),
                        ]}
                        selectedOption={project.project_lead?.toString() || null}
                        onClick={(option: string) => handleUpdateProject({ id: project.id, project_lead: option })}
                        isPending={updateProjectMutation.isPending}
                        className='left-[unset] right-0 translate-x-0'
                        searchText='Set project lead...'
                        dismountingPopover={dismountingPopover}
                    />
                )}
            </div>
            <button
                className={`opacity-25 hover:opacity-100 transition-all hover:text-red hover:dark:text-lightred`}
                onClick={handleDeleteProject}
            >
                <FaTrash />
            </button>
        </div>
    )
}
