import { useContext } from 'react'
import { CgDanger, CgExport } from 'react-icons/cg'
import { FaBars, FaSyncAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import VendorInfoV2 from 'vendors/components/VendorInfoV2'
import { cn } from '../../helpers'
import MoreActions from '../../processingTimes/components/MoreActions'
import PrivateEvent from '../../tasks/components/PrivateEvent'
import { PrivateEventType } from '../../tasks/tasks.types'
import { useDuplicateCompany } from '../api/useQueries'
import { COMPANY_RESOURCE_VIEW_TABS } from '../constants'
import {
    CompanyContext,
    CompanyContextType,
    ProcurementContext,
    ProcurementContextType,
    ProductWebsocketProvider,
} from '../helpers'
import CompanyQuickActions from './CompanyQuickActions'
import ResourceViewButton from './ResourceViewButton'
import ActivityListV2 from './lists/ActivityListV2'
import ContactListV2 from './lists/ContactListV2'
import EmailListV2 from './lists/EmailListV2'
import FileListV2 from './lists/FileListV2'
import NoteListV2 from './lists/NoteListV2'
import ProductListV2 from './lists/ProductListV2'
import SalesInfoV2 from './lists/SalesInfoV2'
import TaskListV2Company from './lists/TaskListV2Company'
import CompanyQuickModal from './modals/CompanyQuickModal'

export default function CompanyResourcesV2({
    company,
    companyEventsPrivate,
    setShowEventsPrivate,
    selectedLifecycle,
    setShowDelete,
}: {
    company: any
    companyEventsPrivate: PrivateEventType[]
    setShowEventsPrivate: Function
    selectedLifecycle: string | undefined
    setShowDelete: any
}) {
    const { resourceView, setResourceView, showQuickModal, setShowQuickModal } =
        useContext<CompanyContextType>(CompanyContext)
    const { toggleModal } = useContext<ProcurementContextType>(ProcurementContext)
    const user = useSelector<any, any>((state) => state.user)

    const duplicateCompany = useDuplicateCompany()

    function handleDuplicateCompany() {
        duplicateCompany.mutate(
            { editableCompany: company },
            {
                onSuccess: () => {
                    toggleModal()
                },
            }
        )
    }

    const options = [
        {
            displayText: `Duplicate Company`,
            icon: FaSyncAlt,
            action: handleDuplicateCompany,
        },
        {
            displayText: `Export Product CSV`,
            icon: CgExport,
            action: () => setShowQuickModal('csv_export'),
        },

        {
            displayText: ``,
            icon: undefined,
            action: () => {},
            disabled: true,
        },
        {
            displayText: `Delete Company`,
            icon: CgDanger,
            action: () => setShowDelete(true),
            font: 'text-danger dark:text-darkdanger',
        },
    ]

    return (
        <div className='flex w-full h-fit overflow-auto  '>
            <div className={cn('w-full h-fit', resourceView === 'products' && 'overflow-auto h-full')}>
                <div className={cn('flex gap-4 sticky top-0 z-[41] bg-bg1 dark:bg-darkbg1 ')}>
                    {resourceView === 'products' && (
                        <div
                            className={cn(
                                'flex flex-col gap-[8px] overflow-clip pr-[16px] w-1/4 shrink-0',
                                resourceView === 'products' && 'pr-0'
                            )}
                        >
                            <div className=' flex justify-between items-start'>
                                <div>
                                    <h2 className='text-darkgrey dark:text-offwhite text-[18px] font-bold uppercase leading-[1]'>
                                        {company.name}
                                    </h2>
                                </div>
                                <CompanyQuickActions resourceView={resourceView} />
                            </div>
                        </div>
                    )}
                    <div className='flex w-full justify-between'>
                        <div className='flex'>
                            {COMPANY_RESOURCE_VIEW_TABS.map((tab: string) => (
                                <ResourceViewButton
                                    key={tab}
                                    setResourceView={setResourceView}
                                    resourceView={resourceView}
                                    viewName={tab}
                                />
                            ))}
                            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                <ResourceViewButton
                                    key={'sales'}
                                    setResourceView={setResourceView}
                                    resourceView={resourceView}
                                    viewName={'sales'}
                                />
                            )}
                        </div>

                        <div className='flex gap-2 mr-2'>
                            {user.type === 'Admin' ||
                                (user.type === 'SuperAdmin' && companyEventsPrivate.length > 0 && (
                                    <div className={cn('self-center', resourceView === 'products' && 'self-start')}>
                                        <PrivateEvent
                                            events={companyEventsPrivate}
                                            setViewModal={setShowEventsPrivate}
                                        />
                                    </div>
                                ))}
                            {resourceView === 'products' && <p className=' absolute top-5 right-[138px]'>Sort By:</p>}
                            <MoreActions options={options} displayContent={{ icon: FaBars }} />
                        </div>
                    </div>
                </div>
                <>
                    {resourceView === 'activity' && <ActivityListV2 company={company} />}
                    {resourceView === 'emails' && <EmailListV2 company={company} />}
                    {resourceView === 'notes' && <NoteListV2 />}
                    {resourceView === 'tasks' && (
                        <div className=''>
                            <TaskListV2Company />
                        </div>
                    )}
                    {resourceView === 'products' && (
                        <ProductWebsocketProvider>
                            <ProductListV2 />
                        </ProductWebsocketProvider>
                    )}
                    {resourceView === 'contacts' && <ContactListV2 />}
                    {resourceView === 'files' && <FileListV2 />}
                    {resourceView === 'info' && <VendorInfoV2 />}
                    {resourceView === 'sales' && <SalesInfoV2 />}
                </>
            </div>

            {showQuickModal && (
                <>
                    <CompanyQuickModal
                        showQuickModal={showQuickModal}
                        setShowQuickModal={setShowQuickModal}
                        selectedLifecycle={selectedLifecycle}
                    />
                </>
            )}
        </div>
    )
}
