import { OrderInit } from '../orders/orders.types'

export type SalesGoalInit = {
    assigned_to: string | null
    end_date: Date
    id: number
    start_date: Date
    team?: null
    total?: number
    total_delta?: number | null
    type: string
    volume?: number
    volume_delta?: number | null
}
export type SalesPeriod = {
    avgTotal: number
    avgTotDif: number
    orders: OrderInit[]
    total: number
    totalDif: number
    volDif: number
    volume: number
}

type MonthlyOrQuarterlyGoal = {
    actual?: number
    goal?: SalesGoalInit
    name: string
}
export type RepGoals = {
    all: SalesGoalInit[]
    monthly: {
        0: MonthlyOrQuarterlyGoal
        1: MonthlyOrQuarterlyGoal
        2: MonthlyOrQuarterlyGoal
        3: MonthlyOrQuarterlyGoal
        4: MonthlyOrQuarterlyGoal
        5: MonthlyOrQuarterlyGoal
        6: MonthlyOrQuarterlyGoal
        7: MonthlyOrQuarterlyGoal
        8: MonthlyOrQuarterlyGoal
        9: MonthlyOrQuarterlyGoal
        10: MonthlyOrQuarterlyGoal
        11: MonthlyOrQuarterlyGoal
    }
    quarterly: {
        0: MonthlyOrQuarterlyGoal
        1: MonthlyOrQuarterlyGoal
        2: MonthlyOrQuarterlyGoal
        3: MonthlyOrQuarterlyGoal
    }
    yearly: any
}

export const defaultRepGoals: RepGoals = {
    all: [],
    monthly: {
        0: {
            name: 'January',
        },
        1: {
            name: 'February',
        },
        2: {
            name: 'March',
        },
        3: {
            name: 'April',
        },
        4: {
            name: 'May',
        },
        5: {
            name: 'June',
        },
        6: {
            name: 'July',
        },
        7: {
            name: 'August',
        },
        8: {
            name: 'September',
        },
        9: {
            name: 'Octover',
        },
        10: {
            name: 'November',
        },
        11: {
            name: 'December',
        },
    },
    quarterly: {
        0: {
            name: 'Q1',
        },
        1: {
            name: 'Q2',
        },
        2: {
            name: 'Q3',
        },
        3: {
            name: 'Q4',
        },
    },
    yearly: undefined,
}

export const defaultSalesGoal: SalesGoalInit = {
    assigned_to: '',
    end_date: new Date(),
    id: 0,
    start_date: new Date(),
    team: null,
    total: undefined,
    total_delta: null,
    type: '',
    volume: undefined,
    volume_delta: null,
}
export const defaultSalesPeriod: SalesPeriod = {
    avgTotal: 0,
    avgTotDif: 0,
    orders: [],
    total: 0,
    totalDif: 0,
    volume: 0,
    volDif: 0,
}
