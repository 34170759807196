import { CheckIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { addDays } from 'date-fns'
import { cn, sendToast } from 'helpers'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { getDateToday } from 'procurement/constants'
import {
    useDeleteLeadTime,
    useGetLeadTimeDetail,
    useGetProductVariantsQuery,
    useUpdateLeadTime,
} from 'productSetupV2/api/useQueries'
import { useEffect, useRef, useState } from 'react'
import { FaExternalLinkAlt, FaTimes, FaTrash } from 'react-icons/fa'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { CalendarSelectorLeadTimes } from './CalendarSelectorLeadTimes'
import { LeadTime } from './InventoryLeadTimesList'
import RelatedLeadTimeList from './RelatedLeadTimeList'
import { IoMdCheckmarkCircle } from 'react-icons/io'

export default function LeadTimeDetailModal({
    leadTimeId,
    filters,
    search,
}: {
    leadTimeId: string
    filters?: any
    search?: string
}) {
    const [searchParams] = useSearchParams()
    const modalRef = useRef<HTMLDivElement>(null)
    const completeModalRef = useRef<HTMLDivElement>(null)
    const [showDelete, setShowDelete] = useState(false)
    const navigate = useNavigate()
    const [selectedDate, setSelectedDate] = useState<Date>(getDateToday())
    const [incrementQuantity, setIncrementQuantity] = useState(false)
    const [showComplete, setShowComplete] = useState(false)

    const [editableFields, setEditableFields] = useState<any>({})

    const deleteLeadTime = useDeleteLeadTime()
    const updateLeadTime = useUpdateLeadTime()

    const leadTimeDetailQuery = useGetLeadTimeDetail(leadTimeId, {
        filters,
        search,
    })

    const { leadTime }: { leadTime: LeadTime } = leadTimeDetailQuery?.data || {}
    const edited =
        Boolean(Object.keys(editableFields).length) ||
        (selectedDate &&
            selectedDate?.toISOString() !=
                (!leadTimeDetailQuery.isFetching && leadTime?.available_at
                    ? new Date(leadTime?.available_at.replace('Z', '')).toISOString()
                    : new Date().toISOString()))

    const relatedLeadTimes: { id: number; quantity: number; available_at: any; status: string }[] | [] =
        leadTime?.related_lead_times ? JSON.parse(leadTime?.related_lead_times) : []

    const getProductVariantsQuery = useGetProductVariantsQuery(leadTime?.product_id ? leadTime?.product_id : undefined)
    const { variants: productVariants }: { variants: any[] } = getProductVariantsQuery?.data || {}

    const selectedVariant = productVariants?.find((variant: any) => variant.id === leadTime?.variant_id)

    const inventory_management = editableFields?.inventory_management ?? selectedVariant?.inventory_management
    const inventory_policy = editableFields?.inventory_policy ?? leadTime?.inventory_policy

    const createdByArray = leadTime?.created_by.split(':') || []

    const emailId =
        createdByArray[0] === 'Inv. Email' ? createdByArray[1].slice(0, createdByArray[1].indexOf('(')) : undefined

    const tracked = inventory_management === 'shopify'
    const completed = leadTime?.status === 'Completed'

    const closeModal = () => {
        navigate(`/products/inventory-lead-times?${searchParams.toString()}`)
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditableFields((prev: any) => {
            const newState = structuredClone(prev)
            return {
                ...newState,
                [target.name]: target.value,
            }
        })
    }

    const handleSaveEdits = () => {
        if (updateLeadTime.isPending) {
            return
        }
        if (editableFields.quantity == '' || editableFields.quantity == 0) {
            return sendToast({ message: 'Must include quantity' })
        }
        if (editableFields.preorder_days == '') {
            return sendToast({ message: 'Must include lead time window' })
        }
        const dateEdited =
            selectedDate?.toISOString() != new Date(leadTime?.available_at.replace('Z', '')).toISOString()

        updateLeadTime.mutate(
            {
                leadTimeUpdates: {
                    id: leadTimeId,
                    ...editableFields,
                    available_at: dateEdited ? selectedDate.toISOString().split('T')[0] : undefined,
                },
                original: { ...leadTime, available_at: leadTime.available_at.split('T')[0] },
            },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }
    const handleComplete = () => {
        if (updateLeadTime.isPending) {
            return
        }
        if (edited) {
            return sendToast({ message: 'Save or cancel pending changes' })
        }
        updateLeadTime.mutate(
            { leadTimeUpdates: { id: leadTime.id, status: 'Completed', incrementQuantity }, original: { ...leadTime } },
            {
                onSuccess: () => {
                    setEditableFields({})
                    setShowComplete(false)
                },
            }
        )
    }
    const handleCancel = () => {
        setEditableFields({})
        setSelectedDate(new Date(leadTime?.available_at.replace('Z', '')))
    }
    const handleDelete = () => {
        if (deleteLeadTime.isPending) {
            return
        }
        deleteLeadTime.mutate(
            { leadTimeId, variantId: leadTime.variant_id },
            {
                onSuccess: () => {
                    closeModal()
                },
            }
        )
    }

    useEffect(() => {
        if (leadTime?.available_at) {
            setSelectedDate(new Date(leadTime?.available_at.replace('Z', '')))
        }
    }, [leadTime])

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                closeModal()
            }
            if (e.target === completeModalRef.current) {
                setShowComplete(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-50'
        >
            {showComplete && (
                <div
                    ref={completeModalRef}
                    className='fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-50'
                >
                    <div className='bg-bg1 dark:bg-darkbg1 flex flex-col justify-between items-center max-w-[30%] max-h-[30%] min-w-[20%] min-h-[20%] p-6 rounded shadow-md relative'>
                        <div className='flex flex-col gap-2'>
                            <div className='flex gap-2 items-center'>
                                <Input
                                    className={cn('cursor-default')}
                                    id='current_quantity'
                                    name='current_quantity'
                                    label='Current'
                                    type='number'
                                    readOnly={true}
                                    value={selectedVariant?.inventory_quantity}
                                    onChange={handleChange}
                                ></Input>
                                <p className='mt-3'>+</p>
                                <Input
                                    className={cn('cursor-default', !incrementQuantity && 'line-through')}
                                    id='incoming_quantity'
                                    name='incoming_quantity'
                                    label='Incoming'
                                    type='text'
                                    readOnly={true}
                                    value={leadTime?.quantity}
                                    onChange={handleChange}
                                ></Input>
                                <p className='mt-3'>=</p>
                                <Input
                                    className={cn('cursor-default')}
                                    id='final_quantity'
                                    name='final_quantity'
                                    label='Final'
                                    type='number'
                                    readOnly={true}
                                    value={
                                        incrementQuantity
                                            ? selectedVariant?.inventory_quantity + leadTime?.quantity
                                            : selectedVariant?.inventory_quantity
                                    }
                                    onChange={handleChange}
                                ></Input>
                            </div>
                            <div
                                className={cn(
                                    'flex gap-2 items-center mt-2 transition-none',
                                    edited && 'opacity-50 border-lightgrey transition-none'
                                )}
                            >
                                <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                    Increment Quantity
                                </p>
                                <div
                                    onClick={() => setIncrementQuantity(!incrementQuantity)}
                                    className={cn(
                                        ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                        incrementQuantity
                                            ? 'bg-primary text-primary-foreground'
                                            : 'opacity-50 [&_svg]:invisible'
                                    )}
                                >
                                    <CheckIcon className={cn('h-4 w-4')} />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 justify-between'>
                            <div className='flex flex-col gap-2 items-center '>
                                <Button
                                    onClick={handleComplete}
                                    variant={'outline'}
                                    className={cn(
                                        'border-success transition-none',
                                        edited && 'opacity-50 border-lightgrey transition-none',
                                        updateLeadTime.isPending &&
                                            updateLeadTime.variables.leadTimeUpdates.status === 'Completed' &&
                                            'opacity-50'
                                    )}
                                >
                                    Confirm
                                </Button>
                                {updateLeadTime.isPending &&
                                    updateLeadTime.variables.leadTimeUpdates.status === 'Completed' && (
                                        <FetchingSpinner isFetching={updateLeadTime.isPending} />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='bg-bg1 dark:bg-darkbg1 max-w-[90%] max-h-[90%] min-w-[65%] min-h-[50%] p-6 rounded shadow-md relative'>
                <button className='absolute top-1 right-1 text-lg' onClick={closeModal}>
                    <FaTimes className='text-red' />
                </button>
                <div className='flex justify-between pb-1 border-b border-lightrey dark:border-darkgrey mb-2 min-h-[42px]'>
                    <h2 className='font-bold text-lg leading-tight flex gap-4'>
                        <p>Lead Time: {leadTimeId}</p>
                        {completed && <IoMdCheckmarkCircle className=' text-lime w-[24px] h-[24px]' />}
                    </h2>
                </div>
                <div className='grid grid-cols-2 gap-4 min-h-[280px]'>
                    <div className=''>
                        <div className='flex gap-2 mb-2 items-center'>
                            <h2 className='font-bold'>Product Details </h2>
                            <Link
                                className='flex gap-2 items-center font-normal text-sm'
                                to={`/products/${leadTime?.product_id}`}
                            >
                                <FaExternalLinkAlt />
                            </Link>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div className='flex gap-2 items-center'>
                                <Input
                                    className='cursor-default'
                                    id='sku'
                                    name='sku'
                                    label='Sku'
                                    type='text'
                                    readOnly={true}
                                    value={editableFields.sku ?? leadTime?.sku}
                                    onChange={handleChange}
                                ></Input>
                                <Input
                                    className='cursor-default'
                                    id='product_id'
                                    name='product_id'
                                    label='Product Id'
                                    type='text'
                                    readOnly={true}
                                    value={editableFields.product_id ?? leadTime?.product_id}
                                    onChange={handleChange}
                                ></Input>
                            </div>
                            <div className='flex gap-2 items-center w-full'>
                                <div className='flex gap-2 items-center'>
                                    <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                        Inventory Tracked
                                    </p>
                                    <div
                                        className={cn(
                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey ',
                                            tracked
                                                ? 'bg-primary text-primary-foreground'
                                                : 'opacity-50 [&_svg]:invisible'
                                        )}
                                    >
                                        <CheckIcon className={cn('h-4 w-4')} />
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                        Continue Selling when out of stock
                                    </p>
                                    <div
                                        onClick={() => {}}
                                        className={cn(
                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey ',
                                            selectedVariant?.inventory_policy === 'continue'
                                                ? 'bg-primary text-primary-foreground'
                                                : 'opacity-50 [&_svg]:invisible'
                                        )}
                                    >
                                        <CheckIcon className={cn('h-4 w-4')} />
                                    </div>
                                </div>
                            </div>

                            <Select
                                className={cn('cursor-default pointer-events-none')}
                                label='Ignore Auto Updates'
                                id='custom_ignore_auto_updates'
                                name='custom_ignore_auto_updates'
                                onChange={handleChange}
                                value={
                                    editableFields.custom_ignore_auto_updates ??
                                    selectedVariant?.custom_ignore_auto_updates
                                }
                            >
                                <option value='1'>True</option>
                                <option value='0'>False</option>
                            </Select>
                        </div>
                    </div>
                    <div className='mb-2'>
                        <h2 className='font-bold mb-2'>Lead Time Details</h2>
                        <div className='flex flex-col gap-2'>
                            <div className='flex gap-2 mb-2'>
                                <Input
                                    className='cursor-default'
                                    id='status'
                                    name='status'
                                    label='Status'
                                    type='text'
                                    readOnly={true}
                                    value={
                                        leadTime?.available_at.split('T')[0] <=
                                            getDateToday().toISOString().split('T')[0] &&
                                        leadTime?.status !== 'Completed'
                                            ? 'Due'
                                            : leadTime?.status
                                    }
                                    onChange={handleChange}
                                ></Input>
                                <Input
                                    className={cn('cursor-default', emailId && 'cursor-pointer underline')}
                                    id='created_by'
                                    name='created_by'
                                    label='Source'
                                    type='text'
                                    readOnly={true}
                                    value={leadTime?.created_by}
                                    onChange={() => {}}
                                    onclick={() => {
                                        if (emailId) {
                                            navigate(`/products/inventory-emails/${emailId}`)
                                        }
                                    }}
                                ></Input>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <div className={cn('flex gap-2 items-center', completed && 'cursor-default')}>
                                    <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                        Continue Selling during lead time window
                                    </p>
                                    <div
                                        onClick={() => {
                                            if (leadTime.status === 'Completed') {
                                                return
                                            }
                                            const currentState = editableFields.inventory_policy
                                            if (typeof currentState != 'string') {
                                                return setEditableFields({
                                                    ...editableFields,
                                                    inventory_policy:
                                                        leadTime?.inventory_policy === 'continue' ? 'deny' : 'continue',
                                                })
                                            }

                                            if (
                                                typeof currentState === 'string' &&
                                                leadTime?.inventory_policy != currentState
                                            ) {
                                                const newFields = { ...editableFields }
                                                delete newFields.inventory_policy
                                                return setEditableFields(newFields)
                                            }
                                        }}
                                        className={cn(
                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                            inventory_policy === 'continue'
                                                ? 'bg-primary text-primary-foreground'
                                                : 'opacity-50 [&_svg]:invisible',
                                            completed && 'cursor-default'
                                        )}
                                    >
                                        <CheckIcon className={cn('h-4 w-4')} />
                                    </div>
                                    {leadTime?.custom_ignore_auto_updates === 1 && (
                                        <div className='relative group pt-[2px]'>
                                            <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                            <div className='hidden group-hover:flex border absolute top-[100%] right-[-40px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[100] '>
                                                <p>Variant is ignoring auto updates</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <Input
                                    className={cn(completed && 'cursor-default')}
                                    id='preorder_days'
                                    name='preorder_days'
                                    label='Lead Time Window (days)'
                                    type='number'
                                    readOnly={completed}
                                    value={editableFields.preorder_days ?? leadTime?.preorder_days}
                                    onChange={handleChange}
                                ></Input>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-[1fr_15px_1fr] gap-1 col-span-2 mb-2'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label
                                className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                htmlFor='available'
                            >
                                Current Available Quantity
                            </label>
                            <div className='flex gap-2 relative w-full'>
                                <input
                                    id='available'
                                    type='text'
                                    value={selectedVariant?.inventory_quantity}
                                    name='available'
                                    autoComplete='off'
                                    readOnly={true}
                                    className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-darkbg2 dark:text-white rounded-[4px] cursor-default'
                                    onChange={() => {}}
                                />
                            </div>
                        </div>
                        <div className='mt-[18px]'>{'=>'}</div>
                        <div className='flex flex-col gap-2'>
                            <Input
                                className={cn(completed && 'cursor-default')}
                                id='quantity'
                                name='quantity'
                                label='Incoming Quantity'
                                type='number'
                                readOnly={completed}
                                value={editableFields.quantity ?? leadTime?.quantity}
                                onChange={handleChange}
                            ></Input>
                            <CalendarSelectorLeadTimes
                                className={cn(completed && 'cursor-default')}
                                date={selectedDate}
                                setDate={setSelectedDate}
                                label='Available At'
                                readOnly={completed}
                            />
                        </div>
                    </div>
                    {leadTime?.status === 'Pending' && (
                        <div className='flex justify-end col-start-2'>
                            <div
                                className={cn(
                                    'w-full flex gap-3 justify-end',
                                    !leadTimeDetailQuery.isFetching && edited && 'justify-between'
                                )}
                            >
                                {!leadTimeDetailQuery.isFetching && edited && (
                                    <div className='flex gap-4 items-center'>
                                        <div
                                            className={cn(
                                                'flex gap-4',
                                                updateLeadTime.isPending && 'opacity-60 pointer-events-none'
                                            )}
                                        >
                                            <Button onClick={handleSaveEdits} variant={'outline'}>
                                                Save Changes
                                            </Button>
                                            <Button className='' onClick={handleCancel} variant={'outline'}>
                                                Cancel
                                            </Button>
                                        </div>
                                        <FetchingSpinner isFetching={updateLeadTime.isPending} />
                                    </div>
                                )}
                                <Button
                                    onClick={() => setShowComplete(true)}
                                    variant={'outline'}
                                    className={cn(
                                        'border-success transition-none justify-self-end',
                                        edited && 'opacity-50 border-lightgrey transition-none',
                                        updateLeadTime.isPending &&
                                            updateLeadTime.variables.leadTimeUpdates.status === 'Completed' &&
                                            'opacity-50'
                                    )}
                                >
                                    Complete
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <RelatedLeadTimeList relatedLeadTimes={relatedLeadTimes} />
                {showDelete ? (
                    <div className='flex gap-4 absolute bottom-1 right-6 text-sm items-center'>
                        <FetchingSpinner isFetching={updateLeadTime.isPending} />
                        <div className={cn('flex gap-2', updateLeadTime.isPending && 'opacity-60 pointer-events-none')}>
                            <Button
                                variant={'outline'}
                                size={'sm'}
                                className='text-sm text-danger p-1 h-fit'
                                onClick={handleDelete}
                            >
                                Delete Lead Time
                            </Button>
                            <Button
                                className='text-sm p-1 h-fit'
                                variant={'outline'}
                                size={'sm'}
                                onClick={() => setShowDelete(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <button className='absolute bottom-2 right-2 text-lg' onClick={() => setShowDelete(true)}>
                        <FaTrash className='text-red' />
                    </button>
                )}
            </div>
        </div>
    )
}
