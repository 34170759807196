import { vFetch } from 'helpers'

export const getUsers = () =>
    vFetch('/v2/users', {
        reThrowErr: true,
    })

export const setupNewUser = (userSetupOptions: {
    email: string
    type: 'Client' | 'Admin' | 'SuperAdmin'
    role_ids: number[]
    store_ids: number[]
}) =>
    vFetch('/v2/users/setup', {
        method: 'POST',
        body: JSON.stringify(userSetupOptions),
    })

export const updateUser = (userUpdates: { role_ids: number[]; store_ids: number[] }) =>
    vFetch('/v2/users', {
        method: 'PUT',
        body: JSON.stringify(userUpdates),
    })
