import { Button } from 'custom_components/component_Basics/Button'
import UserList from './lists/UserList'
import CreateUserModal from './modals/CreateUserModal'
import { useSearchParams } from 'react-router-dom'
import EditUserModal from './modals/EditUserModal'

export default function UsersScreenV2() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const selectedUserId = searchParams.get('user_id')

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateModal', 'true')
        return setSearchParams(searchParams)
    }

    const toggleEditModal = (id?: number) => {
        if (!id) {
            searchParams.delete('user_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('user_id', id.toString())
        return setSearchParams(searchParams)
    }

    return (
        <div>
            <div className='flex justify-between mb-4'>
                <h1 className='font-semibold text-2xl'>Users</h1>
                <Button onClick={toggleCreateModal} size='sm' variant='outline'>
                    + New User
                </Button>
            </div>
            {selectedUserId && <EditUserModal userId={parseInt(selectedUserId)} closeModal={toggleEditModal} />}
            {showCreateModal && <CreateUserModal closeModal={toggleCreateModal} />}
            <UserList />
        </div>
    )
}
