import { useEffect, useState } from 'react'
import Input from 'procurement/components/Input'
import Textarea from 'procurement/components/Textarea'
import { Button } from 'custom_components/component_Basics/Button'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { useGetRoles } from './api/useQueries'

export default function EditRoleModal({ roleId, closeModal }: { roleId: number; closeModal: () => void }) {
    const [showDelete, setShowDelete] = useState(false)
    const rolesQuery = useGetRoles({})
    const foundRole = rolesQuery.data?.roles.find((role: any) => role.id === roleId)
    const [role, setRole] = useState({
        title: '',
        permission_set: '',
    })
    const queryClient = useQueryClient()
    const createRoleMutation = useMutation({
        mutationFn: (role: { title: string; permission_set: string }) =>
            vFetch('/v2/roles', {
                method: 'PUT',
                body: JSON.stringify({
                    id: roleId,
                    title: role.title,
                    permission_set: JSON.parse(role.permission_set),
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
    })
    const deleteRoleMutation = useMutation({
        mutationFn: (roleId: number) =>
            vFetch('/v2/roles', {
                method: 'DELETE',
                body: JSON.stringify({
                    id: roleId,
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
    })
    const handleCreate = () => {
        createRoleMutation.mutate(role, {
            onSuccess: () => closeModal(),
        })
    }
    const handleDelete = () => {
        deleteRoleMutation.mutate(roleId, {
            onSuccess: () => closeModal(),
        })
    }
    const edited = JSON.stringify(role) !== JSON.stringify(foundRole)

    useEffect(() => {
        if (foundRole) {
            setRole({
                title: foundRole.title,
                permission_set: JSON.stringify(JSON.parse(foundRole.permission_set), null, 2),
            })
        }
    }, [foundRole])
    return (
        <div className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center'>
            <div className='p-6 flex min-h-0 flex-col rounded shadow-md relative bg-bg1 dark:bg-darkbg1 w-full h-full max-w-[90%] max-h-[90%]'>
                <button
                    className='text-red dark:text-lightred font-bold absolute text-lg top-1 right-1'
                    onClick={() => closeModal()}
                >
                    <FaTimes />
                </button>
                <div className='flex justify-between'>
                    <h2 className='font-bold'>Edit Role</h2>
                    <Button onClick={handleCreate} size='sm' variant='outline' disabled={!edited}>
                        + Update Role
                    </Button>
                </div>
                <Input
                    id='title'
                    name='title'
                    type='text'
                    label='Role Name'
                    value={role.title}
                    onChange={({ target }) => setRole({ ...role, title: target.value })}
                />
                <Textarea
                    outerClassName='mt-1'
                    className='h-full max-h-none'
                    id='permission_set'
                    name='permission_set'
                    label='Permissions'
                    value={role.permission_set}
                    onChange={({ target }) => setRole({ ...role, permission_set: target.value })}
                />
                <div className='flex ml-auto gap-2 mt-4'>
                    {!showDelete && (
                        <button onClick={() => setShowDelete(true)} className='text-red dark:text-lightred'>
                            <FaTrash />
                        </button>
                    )}
                    {showDelete && (
                        <>
                            <Button onClick={handleDelete} size='sm' variant='destructive'>
                                Delete
                            </Button>
                            <Button onClick={() => setShowDelete(false)} size='sm' variant='outline'>
                                Cancel
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
