import { SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultItemChecklist, defaultReturn, ReturnEditInit, DamageClaimEditInit } from './../returns.types'
import { closePopout, useDebounce, formatMoney, sendToast, vFetch, parseResObject } from '../../helpers'
import { OrderInit } from '../../orders/orders.types'
import { TaskEditInit } from '../../tasks/tasks.types'
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin'
import AreYouSureYouWantToSubmitReturnModal from './AreYouSureYouWantToSubmitReturnModal'
import AreYouSureYouWantToDeleteReturnModal from './AreYouSureYouWantToDeleteReturnModal'
import { CustomerInit } from '../../customers/customers.types'
import ReturnInfoSection from './ReturnInfoSection'
import { canSubmitReturn, getTaxTotal } from '../returns.helpers'
import { itemPriceAfterDiscounts, renderDiscount } from '../../orders/orders.helpers'
import SelectReturnItem from './SelectItem'
import AreYouSureYouWantToCloseReturnModal from './AreYouSureYouWantToCloseReturnModal'

type EditReturnModuleProps = {
    returnCustomer?: CustomerInit | null
    returnTask?: TaskEditInit | null
    returnOrder?: OrderInit | null
    selectedReturn?: ReturnEditInit | DamageClaimEditInit | null
    setShowReturnsModal?: any
    refreshReturns?: any
}

export default function EditReturnModule({
    selectedReturn,
    returnTask,
    returnOrder,
    returnCustomer,
    setShowReturnsModal,
    refreshReturns,
}: EditReturnModuleProps) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [showDiscount, setShowDiscount] = useState('')
    const [showAreYouSureYouWantToSubmitReturnModal, setShowAreYouSureYouWantToSubmitReturnModal] =
        useState<boolean>(false)
    const [showAreYouSureYouWantToDeleteReturnModal, setShowAreYouSureYouWantToDeleteReturnModal] =
        useState<boolean>(false)
    const [showAreYouSureYouWantToCloseReturnModal, setShowAreYouSureYouWantToCloseReturnModal] =
        useState<boolean>(false)
    const [order, setOrder] = useState<OrderInit | null>(returnOrder || null)
    const [customer, setCustomer] = useState<any>(returnCustomer || returnOrder?.customer || null)
    const [edited, setEdited] = useState(false)
    const [r, setR] = useState<ReturnEditInit | DamageClaimEditInit>(
        selectedReturn
            ? selectedReturn.type === 'return'
                ? {
                      ...selectedReturn,
                      // keep this here. It turns the ISO string back into a date.
                      task_id: returnTask ? (returnTask.id as number) : selectedReturn.task_id,
                      shipping_type: selectedReturn.shipping_type,
                      created_at: new Date(selectedReturn?.created_at || new Date()),
                      item_checklist: (selectedReturn as ReturnEditInit).item_checklist || defaultItemChecklist,
                      refund_to_customer: selectedReturn.refund_to_customer
                          ? Number(selectedReturn.refund_to_customer)
                          : undefined,
                      refund_from_manufacturer_actual: selectedReturn.refund_from_manufacturer_actual
                          ? Number(selectedReturn.refund_from_manufacturer_actual)
                          : undefined,
                      refund_from_manufacturer_expected: selectedReturn.refund_from_manufacturer_expected
                          ? Number(selectedReturn.refund_from_manufacturer_expected)
                          : undefined,
                  }
                : {
                      ...selectedReturn,
                      // keep this here. It turns the ISO string back into a date.
                      task_id: returnTask ? (returnTask.id as number) : selectedReturn.task_id,
                      shipping_type: selectedReturn.shipping_type,
                      created_at: new Date(selectedReturn?.created_at || new Date()),
                      damage_checklist:
                          (selectedReturn as DamageClaimEditInit).damage_checklist || defaultItemChecklist,
                      refund_to_customer: selectedReturn.refund_to_customer
                          ? Number(selectedReturn.refund_to_customer)
                          : undefined,
                      refund_from_manufacturer_actual: selectedReturn.refund_from_manufacturer_actual
                          ? Number(selectedReturn.refund_from_manufacturer_actual)
                          : undefined,
                      refund_from_manufacturer_expected: selectedReturn.refund_from_manufacturer_expected
                          ? Number(selectedReturn.refund_from_manufacturer_expected)
                          : undefined,
                  }
            : { ...defaultReturn, id: -1, updated_at: new Date(), created_at: new Date() }
    )
    const [originalR, setOriginalR] = useState(r)

    // order / task button stuff
    const [orderSearch, setOrderSearch] = useState<string>('')
    // for returns that aren't parsed correctly / if we allow returns to be created outside of tasks
    const debouncedSearch = useDebounce(orderSearch, 300)
    const abortController = new AbortController()

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!(e.target as HTMLElement).classList.contains('js-order__discount')) setShowDiscount('')
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    })
    useEffect(() => {
        if (JSON.stringify(r) !== JSON.stringify(originalR)) setEdited(true)
        else setEdited(false)
    }, [r])

    useEffect(() => {
        if (order?.id) {
            vFetch(`/v1/customers/multipleById?ids=${order.customer.id}`, {
                cb: (res: any) => {
                    if (res.success && res.customers.length > 0) {
                        setR({ ...r, po_number: order.order_number!, customer: order.customer.id! })
                        setCustomer(parseResObject(res.customers[0]))
                    } else sendToast({ message: 'Customer not found.' })
                },
            })
        }
    }, [order])
    useEffect(() => {
        if (debouncedSearch) {
            return () => abortController.abort()
        }
    }, [debouncedSearch])

    function handleNavigate(e: MouseEvent, poNumber: number | undefined, returnId?: number) {
        if (
            closePopout(
                e,
                ['js-returns-checkbox', 'js-items-dropdown', 'js-tags-dropdown', 'js-dont-navigate'],
                () => {}
            )
        ) {
            if (poNumber !== undefined && !returnId) {
                vFetch(`/v1/orders/all?filters=order_number=${poNumber}`, {
                    cb: (res: any) => {
                        if (res.success && res.orders?.length > 0) {
                            if (e.ctrlKey) window.open(`/orders/${res.orders[0].id}`)
                            else if (e.type === 'contextmenu') window.open(`/orders/${res.orders[0].id}`)
                            else navigate(`/orders/${res.orders[0].id}`)
                        } else sendToast({ message: 'Order page cannot be found. Please contact the developers.' })
                    },
                })
            }
            if (returnId !== undefined && closePopout(e, ['js-return-po-number', 'js-return-checkbox'], () => {})) {
                if (e.ctrlKey) window.open(`/orders/returns/edit?returns=${returnId}`)
                else if (e.type === 'contextmenu') window.open(`/orders/returns/edit?returns=${returnId}`)
                else navigate(`/orders/returns/edit?returns=${returnId}`)
            }
        }
    }

    function handleChangeReturn(name: string, value: any) {
        setR({ ...r, [name]: value ? value : null })
    }
    function handleChangeShippingAddress(e: SyntheticEvent) {
        const customerObject = {
            ...customer,
            default_address: {
                ...customer.default_address,
                [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value,
            },
        }
        setCustomer(customerObject)
        if (order && JSON.stringify(customerObject.default_address) === JSON.stringify(order.customer.default_address))
            setR({ ...r, customer: customer.id })
        else setR({ ...r, customer: customerObject })
    }

    // submit return handlers
    function handleSubmit(rArg: ReturnEditInit | DamageClaimEditInit = r) {
        // *** IMPORTANT ***
        // CAN HANDLE IMAGES, BUT BATCHING HAS NOT BEEN IMPLEMENTED YET

        const returnToSubmit: ReturnEditInit | DamageClaimEditInit = {
            ...rArg,
            // DO NOT REMOVE!! important for updated_at to be undefined.
            updated_at: undefined,
            refund_to_customer: rArg.refund_to_customer || undefined,
            refund_from_manufacturer_actual: rArg.refund_from_manufacturer_actual || undefined,
            refund_from_manufacturer_expected: rArg.refund_from_manufacturer_expected || undefined,
            old_status: originalR.status,
        }

        setLoading(true)
        const data = new FormData()
        data.append(
            'returns',
            JSON.stringify([
                {
                    ...returnToSubmit,
                    files:
                        returnToSubmit.files?.map((f) => {
                            return { ...f, base64: undefined }
                        }) || [],
                },
            ])
        )
        returnToSubmit.files?.filter((f) => !f.location).forEach((f) => data.append('0_files', f.data as File, f.name))

        try {
            if (returnToSubmit.files?.length) sendToast({ message: 'Uploading images...' })

            vFetch(`/v1/returns`, {
                method: 'PUT',
                body: data,
                contentType: 'skip',
                cb: (res: any) => {
                    if (res.success) {
                        setLoading(false)
                        setShowAreYouSureYouWantToSubmitReturnModal(false)
                        if (setShowReturnsModal) setShowReturnsModal(null)
                        if (refreshReturns) refreshReturns()
                        setEdited(false)
                        setOriginalR(r)
                    }
                },
            })
        } catch (err) {
            sendToast({
                message:
                    'Something went wrong and the server could not be reached. Please try again or contact the developers.',
            })
            setLoading(false)
        }
    }

    // styles
    const buttonStyle =
        'p-[8px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small'
    const labelStyle = 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
    const fieldContainerStyle = 'flex flex-col gap-[4px]'
    const inputStyle =
        'bg-lightgrey dark:bg-darkaccent p-[4px] border-[1px] dark:border-blue focus:dark:border-accent outline-0 rounded'

    return (
        <div className='text-[16px] font-normal grid gap-[16px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] pr-[16px] pt-[32px] rounded shadow-small'>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <div className='text-[16px] font-normal grid gap-[16px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] overflow-auto max-h-[calc(100vh-51px-64px-100px)] border-darkness/25 border-[1px] rounded'>
                <div className='grid gap-[16px]'>
                    {r.model?.id ? (
                        <div className='grid gap-[16px]'>
                            <div className=''>
                                <h2 className='text-[20px] font-bold'>
                                    Return {r.id}
                                    {r.submitted && ' - Submitted'}
                                </h2>
                            </div>
                            <div className='grow bg-white dark:bg-darkness py-[8px] p-[16px] rounded shadow-small'>
                                <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey'>
                                    Item from{' '}
                                    <span
                                        className='hover:underline dark:hover:text-accent cursor-pointer'
                                        onClick={(event: any) => handleNavigate(event, r.po_number || undefined)}
                                        onContextMenu={(event: any) => handleNavigate(event, r.po_number || undefined)}
                                    >
                                        Order {order?.order_number}
                                    </span>
                                </h3>
                                <div className='flex gap-[16px]'>
                                    {r.model.id ? (
                                        <div
                                            key={r.model.id}
                                            className='flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]'
                                        >
                                            <div className='flex gap-[16px]'>
                                                <div className='grid w-[100px] items-center relative'>
                                                    <img
                                                        className='object-contain min-w-[100px] min-h-[100px] rounded dark:bg-darkness'
                                                        src={
                                                            window.IMAGE_MAP[
                                                                `gid://shopify/Product/${r.model.product_id}`
                                                            ]
                                                        }
                                                        alt={r.model.name}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <a
                                                        href={`https://factorypure.com/search?q=${r.model.sku}`}
                                                        target='_blank'
                                                        className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                    >
                                                        {r.model.title}
                                                    </a>
                                                    {r.model.variant_title && (
                                                        <p className='pt-[4px]'>
                                                            <strong className='text-darkgrey dark:text-grey'>
                                                                Variant:
                                                            </strong>{' '}
                                                            {r.model.variant_title}
                                                        </p>
                                                    )}
                                                    <p className='pt-[4px]'>
                                                        <strong className='text-darkgrey dark:text-grey'>SKU:</strong>{' '}
                                                        {r.model.sku}
                                                    </p>
                                                    <ul className='list-disc ml-[16px] mt-[8px]'></ul>
                                                </div>
                                            </div>
                                            <div className='flex gap-[32px]'>
                                                <div>
                                                    <label className={labelStyle + ' text-right'}>QTY</label>
                                                    <span className='flex gap-[4px]'>{r.model.quantity}</span>
                                                </div>
                                                <div>
                                                    <label className={labelStyle + ' text-right'}>price</label>
                                                    <div className='relative grid text-right'>
                                                        <span>{formatMoney(itemPriceAfterDiscounts(r.model))}</span>
                                                        {r.model.discount_allocations.length > 0 && (
                                                            <s
                                                                onClick={() => setShowDiscount(r.model.sku)}
                                                                className='js-order__discount text-grey hover:underline cursor-pointer'
                                                            >
                                                                {formatMoney(Number(r.model.price))}
                                                            </s>
                                                        )}
                                                        {renderDiscount(order!, r.model.sku, r.model, showDiscount)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className={labelStyle + ' text-right'}>Tax</label>
                                                    <span className=''>
                                                        {formatMoney(getTaxTotal(r.model.tax_lines))}
                                                    </span>
                                                </div>
                                                <div>
                                                    <label className={labelStyle + ' text-right'}>Total</label>
                                                    <div className='grid text-right'>
                                                        <span className=''>
                                                            {formatMoney(
                                                                Number(itemPriceAfterDiscounts(r.model)) *
                                                                    r.model.quantity +
                                                                    getTaxTotal(r.model.tax_lines)
                                                            )}
                                                        </span>
                                                        {r.model.discount_allocations.length > 0 && (
                                                            <s
                                                                onClick={() => setShowDiscount(r.model.sku)}
                                                                className='js-order__discount text-grey hover:underline cursor-pointer'
                                                            >
                                                                {formatMoney(
                                                                    getTaxTotal(r.model.tax_lines) +
                                                                        Number(r.model.price) * r.model.quantity
                                                                )}
                                                            </s>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {!returnTask && !returnOrder && (
                                                <div className='flex gap-[16px] my-[16px]'>
                                                    <div>
                                                        <label className={labelStyle}>Manufacturer</label>
                                                        <input
                                                            value={r.manufacturer}
                                                            className={inputStyle}
                                                            onChange={({ target }) =>
                                                                handleChangeReturn('manufacturer', target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className={labelStyle}>Model</label>
                                                        <input value={r.model.unparsedModel} className={inputStyle} />
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='flex gap-[16px] bg-white dark:bg-darkness/50 rounded'>
                                {returnCustomer && (
                                    <div className='flex flex-col'>
                                        <div className='w-[500px] dark:bg-darkness p-[16px] rounded shadow-small sticky top-[0px]'>
                                            <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey mb-[16px]'>
                                                Customer
                                            </h3>
                                            <div className='flex flex-col justify-between gap-[8px] dark:bg-darkness rounded-[4px]'>
                                                <div className={fieldContainerStyle}>
                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                        Company
                                                    </label>
                                                    <input
                                                        className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                        type='text'
                                                        name='company'
                                                        value={customer?.default_address?.company}
                                                        onChange={handleChangeShippingAddress}
                                                    />
                                                </div>
                                                <div className='flex gap-[8px] grow'>
                                                    <div className='flex flex-col gap-[4px] w-full'>
                                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                            First Name
                                                        </label>
                                                        <input
                                                            className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                            type='text'
                                                            name='first_name'
                                                            value={customer?.default_address?.first_name}
                                                            onChange={handleChangeShippingAddress}
                                                        />
                                                    </div>
                                                    <div className='flex flex-col gap-[4px] w-full'>
                                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                            Last Name
                                                        </label>
                                                        <input
                                                            className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                            type='text'
                                                            name='last_name'
                                                            value={customer?.default_address?.last_name}
                                                            onChange={handleChangeShippingAddress}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={fieldContainerStyle}>
                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                        Address Line 1
                                                    </label>
                                                    <input
                                                        className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                        type='text'
                                                        name='address1'
                                                        value={customer?.default_address?.address1}
                                                        onChange={handleChangeShippingAddress}
                                                    />
                                                </div>
                                                <div className={fieldContainerStyle}>
                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                        Address Line 2
                                                    </label>
                                                    <input
                                                        className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                        type='text'
                                                        name='address2'
                                                        value={customer?.default_address?.address2}
                                                        onChange={handleChangeShippingAddress}
                                                    />
                                                </div>
                                                <div className='flex gap-[8px] w-full'>
                                                    <div className='flex flex-col gap-[4px] w-1/3'>
                                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                            City
                                                        </label>
                                                        <input
                                                            className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                            type='text'
                                                            name='city'
                                                            value={customer?.default_address?.city}
                                                            onChange={handleChangeShippingAddress}
                                                        />
                                                    </div>
                                                    <div className='flex flex-col gap-[4px] w-1/3'>
                                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                            State
                                                        </label>
                                                        <input
                                                            className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                            type='text'
                                                            name='province_code'
                                                            value={customer?.default_address?.province_code}
                                                            onChange={handleChangeShippingAddress}
                                                        />
                                                    </div>
                                                    <div className='flex flex-col gap-[4px] w-1/3'>
                                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                            Zip
                                                        </label>
                                                        <input
                                                            className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                            type='text'
                                                            name='zip'
                                                            value={customer?.default_address?.zip}
                                                            onChange={handleChangeShippingAddress}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={fieldContainerStyle}>
                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                        Country
                                                    </label>
                                                    <input
                                                        className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                        type='text'
                                                        name='country'
                                                        value={customer?.default_address?.country}
                                                        onChange={handleChangeShippingAddress}
                                                    />
                                                </div>
                                                <div className={fieldContainerStyle}>
                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                        Phone
                                                    </label>
                                                    <input
                                                        className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                        type='text'
                                                        name='phone'
                                                        value={customer?.default_address?.phone}
                                                        onChange={handleChangeShippingAddress}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {r.model && (
                                    <ReturnInfoSection
                                        buttonStyle={buttonStyle}
                                        inputStyle={inputStyle}
                                        fieldContainerStyle={fieldContainerStyle}
                                        labelStyle={labelStyle}
                                        order={order}
                                        r={r}
                                        setR={setR}
                                        handleChangeReturn={handleChangeReturn}
                                    />
                                )}
                            </div>
                            <div className='flex justify-end'>
                                <button
                                    className={buttonStyle + ' !font-bold !text-white !bg-red uppercase'}
                                    onClick={() => {
                                        setShowAreYouSureYouWantToDeleteReturnModal(true)
                                    }}
                                >
                                    DELETE {r.type}
                                </button>
                            </div>
                        </div>
                    ) : (
                        order?.id && [
                            <h2 className='font-bold text-[20px] text-red'>
                                Sku "{r.model?.unparsedModel}" could not be found. Please select the correct model from
                                this list.
                            </h2>,
                            <SelectReturnItem r={r} setR={setR} order={order} />,
                        ]
                    )}
                    {!order?.id && <span>Enter an order or task ID #</span>}
                    {showAreYouSureYouWantToSubmitReturnModal && (
                        <AreYouSureYouWantToSubmitReturnModal
                            r={r}
                            failingValues={canSubmitReturn(r)}
                            handleSubmit={() => handleSubmit({ ...r, submitted: 1 })}
                            setShowAreYouSureYouWantToSubmitReturnModal={setShowAreYouSureYouWantToSubmitReturnModal}
                        />
                    )}
                    {showAreYouSureYouWantToDeleteReturnModal && (
                        <AreYouSureYouWantToDeleteReturnModal
                            r={r as ReturnEditInit}
                            setShowAreYouSureYouWantToDeleteReturnModal={setShowAreYouSureYouWantToDeleteReturnModal}
                            setShowReturnsModal={setShowReturnsModal}
                            refreshReturns={refreshReturns}
                        />
                    )}
                    {showAreYouSureYouWantToCloseReturnModal && (
                        <AreYouSureYouWantToCloseReturnModal
                            r={r}
                            failingValues={canSubmitReturn(r)}
                            setShowAreYouSureYouWantToCloseReturnModal={setShowAreYouSureYouWantToCloseReturnModal}
                            handleSubmit={handleSubmit}
                        />
                    )}
                </div>
            </div>
            <div className='relative flex justify-center w-[100%] bg-white dark:bg-darkaccent min-h-[40px] gap-4'>
                {edited && (
                    <button className={buttonStyle} onClick={() => handleSubmit(r)}>
                        Save {(r as any).submitted ? 'Edits' : ''}
                    </button>
                )}
                {!(r as any).submitted && (
                    <>
                        {Object.values(canSubmitReturn(r)).every((v) => v === true) && (
                            <button
                                className={buttonStyle + ' capitalize'}
                                onClick={() => {
                                    handleSubmit({ ...r, submitted: 1 } as any)
                                }}
                            >
                                Submit {r.type}
                            </button>
                        )}
                        {!Object.values(canSubmitReturn(r)).every((v) => v === true) && (
                            <button
                                className={buttonStyle + ' capitalize'}
                                onClick={() => {
                                    setShowAreYouSureYouWantToSubmitReturnModal(true)
                                }}
                            >
                                Submit Incomplete {r.type}
                            </button>
                        )}
                    </>
                )}
                {r.status === 'ready for processing' && (
                    <button
                        className={buttonStyle + ' absolute right-0 capitalize'}
                        onClick={() => setShowAreYouSureYouWantToCloseReturnModal(true)}
                    >
                        Close {r.type}
                    </button>
                )}
            </div>
        </div>
    )
}
