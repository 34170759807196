import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn, parseResObject, sendToast } from 'helpers'
import Select from 'procurement/components/Select'
import TextareaV2 from 'procurement/components/TextareaV2'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useCreateTask, useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import DueAtCalendar from '../components/DueAtCalendar'
import DueAtSelector from '../components/DueAtSelector'
import ExistingTaskCheck from '../components/ExistingTaskCheck'
import TaskAlertStateEditor from '../components/TaskAlertStateEditor'
import TaskAssignmentsModal from './TaskAssignmentsModal'
import TaskAssociationsModal from './TaskAssociationsModal'
import CarefulCreateTaskButton from '../components/CarefulCreateTaskButton'

export default function CreateTaskModal({
    prePopulatedValues = {},
    allowedValues = {},
    closeModal,
}: {
    prePopulatedValues?: any
    allowedValues?: any
    closeModal: () => void
}) {
    prePopulatedValues.associations = prePopulatedValues?.associations?.map((association: any) => {
        return {
            ...association,
            locked: true,
        }
    })
    const user = useSelector<any, any>((state) => state.user)
    const defaultTask = {
        associations: [
            {
                resource_name: 'user',
                readable_name: `${user.firstName} ${user.lastName}`,
                resource_id: user.id,
            },
        ],
        alert_offset: 0,
        send_slack_alert: 1,
        HTMLDateString: HTMLDateInputFormatter(new Date()),
        HTMLTimeString: `08:00`,
        ...prePopulatedValues,
    }
    const titleRef = useRef<HTMLInputElement>(null)
    const [showAssignments, setShowAssignments] = useState(false)
    const [showAssociations, setShowAssociations] = useState(false)
    const [createAnotherTask, setCreateAnotherTask] = useState(false)
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const departments = departmentConfigQuery.data?.departments || []
    const createTaskMutation = useCreateTask()
    const modalRef = useRef<HTMLDivElement>(null)
    const [task, setTask] = useState<any>(defaultTask)

    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setTask((prev: any) => {
            const newState = structuredClone(prev)
            if (
                newState.associations?.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                return newState
            }
            if (!newState.associations) {
                newState.associations = []
            }
            newState.associations.push(association)
            return newState
        })
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setTask((prev: any) => {
            const newState = structuredClone(prev)
            if (target.name === 'department_id') {
                return {
                    ...defaultTask,
                    [target.name]: target.value,
                    associations: prev.associations,
                }
            }
            if (
                (target.name === 'send_slack_alert' || target.name === 'send_email_alert') &&
                (target as HTMLInputElement).checked === true &&
                !newState.alert_offset
            ) {
                newState.alert_offset = '0'
            }
            if (target.name === 'alert_offset' && !target.value) {
                newState.alert_offset = null
                return newState
            }
            return {
                ...newState,
                [target.name]:
                    target.type === 'checkbox' ? ((target as HTMLInputElement).checked ? 1 : 0) : target.value,
            }
        })
    }

    const handleCreate = (showWarning?: boolean) => {
        if (createTaskMutation.isPending) {
            return
        }
        if (!task.department_id) {
            return sendToast({ message: 'Select department' })
        }
        if (!task.HTMLDateString || !task.HTMLTimeString) {
            return sendToast({ message: 'Select a due date and time' })
        }
        if (task?.associations?.filter((association: any) => association.resource_name === 'user').length < 1) {
            return sendToast({ message: 'Task must be assigned to a user' })
        }
        const formattedTask = {
            ...task,
            title: task?.title ? task?.title.trim() : '',
            description: task?.description ? task.description.trim() : '',
            due_at: new Date(`${task.HTMLDateString}T${task.HTMLTimeString}:00.000Z`).toISOString(),
            is_duplicate: showWarning ? true : undefined,
        }
        createTaskMutation.mutate(formattedTask, {
            onSuccess: () => {
                setTask((prev: any) => ({
                    ...prev,
                    title: '',
                    description: '',
                }))
                if (createAnotherTask) {
                    setTimeout(() => {
                        titleRef.current?.querySelector('input')?.focus()
                    }, 100)
                } else {
                    closeModal()
                }
            },
        })
    }

    const setDate = (HTMLDateString: string) => {
        setTask({
            ...task,
            HTMLDateString: HTMLDateString,
        })
    }
    const setTime = (HTMLTimeString: string) => {
        setTask({
            ...task,
            HTMLTimeString: HTMLTimeString,
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    const selectedDepartment = departments.find((department: any) => department.id == task.department_id)
        ? parseResObject(departments.find((department: any) => department.id == task.department_id))
        : undefined
    const departmentTypes = selectedDepartment?.types || []
    const departmentEstimates = selectedDepartment?.estimates || []

    const departmentOptions = departments?.filter((department: any) => {
        if (allowedValues?.departments) {
            return allowedValues?.departments.includes(department.title)
        } else {
            return department
        }
    })
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className={cn('bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md flex gap-4')}>
                <div
                    className={cn(
                        'w-[500px] flex flex-col gap-1',
                        createTaskMutation.isPending && 'pointer-events-none opacity-60'
                    )}
                >
                    <button onClick={closeModal} className='absolute top-1 right-1'>
                        <FaTimes className='text-red dark:text-lightred text-lg' />
                    </button>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold mb-4'>Create New Task</h2>
                        <FetchingSpinner isFetching={createTaskMutation.isPending} />
                    </div>
                    <Select
                        label='Department'
                        id='department_id'
                        name='department_id'
                        onChange={handleChange}
                        value={task.department_id}
                    >
                        <option value=''>Select a department</option>
                        {departmentOptions?.map((department: any) => (
                            <option value={department.id}>{department.title}</option>
                        ))}
                    </Select>
                    <div
                        className={`${
                            !task.department_id ? 'pointer-events-none opacity-50' : ''
                        } flex flex-col gap-2 mt-2`}
                    >
                        <div className='flex flex-col gap-2'>
                            <div className='grid grid-cols-2 gap-1'>
                                {departmentTypes.length > 0 && (
                                    <Select
                                        label='Type'
                                        id='type_id'
                                        name='type_id'
                                        onChange={handleChange}
                                        value={task.type_id ?? ''}
                                    >
                                        <option value=''></option>
                                        {departmentTypes.map((type: any) => (
                                            <option value={type.task_type_id}>{type.value}</option>
                                        ))}
                                    </Select>
                                )}
                                {departmentEstimates.length > 0 && (
                                    <Select
                                        label='Estimate'
                                        id='estimate_id'
                                        name='estimate_id'
                                        onChange={handleChange}
                                        value={task.estimate_id ?? ''}
                                    >
                                        <option value=''></option>
                                        {departmentEstimates.map((type: any) => (
                                            <option value={type.task_estimate_id}>{type.value}</option>
                                        ))}
                                    </Select>
                                )}
                                <Select
                                    label='Priority'
                                    id='priority'
                                    name='priority'
                                    onChange={handleChange}
                                    value={task.priority ?? 'Low'}
                                >
                                    {TASK_PRIORITIES.map((priority: any) => (
                                        <option value={priority}>{priority}</option>
                                    ))}
                                </Select>
                                <Select
                                    label='Status'
                                    id='status'
                                    name='status'
                                    onChange={handleChange}
                                    value={task.status ?? 'To Do'}
                                >
                                    {TASK_STATUSES.map((status: any) => (
                                        <option value={status}>{status}</option>
                                    ))}
                                </Select>
                            </div>
                            <TextareaV2
                                className={cn(
                                    ' w-full max-h-[60px]',
                                    selectedDepartment?.allow_descriptions === 0 && 'max-h-[160px]'
                                )}
                                label={'Task'}
                                id='title'
                                name='title'
                                value={task.title ?? ''}
                                onChange={handleChange}
                            ></TextareaV2>

                            {selectedDepartment?.allow_descriptions === 1 && (
                                <TextareaV2
                                    className='max-h-[80px]'
                                    id='description'
                                    name='description'
                                    onChange={handleChange}
                                    value={task.description ?? ''}
                                    label='Additional Details'
                                />
                            )}
                        </div>
                        <div className='flex gap-1'>
                            <div className='flex flex-col gap-1 w-1/2'>
                                <label className='text-xs font-bold uppercase leading-none'>Assigned To</label>
                                <div className='flex gap-1 flex-wrap'>
                                    {task.associations
                                        ?.filter((association: any) => association.resource_name === 'user')
                                        .map((association: any) => (
                                            <div className='flex items-center gap-2 p-1 rounded border border-lightgrey dark:border-darkgrey'>
                                                <span>{association.readable_name}</span>
                                                {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                                    <button onClick={() => toggleAssociations(association)}>
                                                        <FaTimes />
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                </div>
                                {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                    <div className='flex flex-wrap'>
                                        <Button
                                            className='dark:border-darkgrey'
                                            variant={'outline'}
                                            onClick={() => setShowAssignments(true)}
                                        >
                                            + Add / Edit Assignments
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-col gap-1 w-1/2'>
                                <label className='text-xs font-bold uppercase leading-none'>Associated With</label>
                                <div className='flex gap-1 flex-wrap'>
                                    {task.associations
                                        ?.filter((association: any) => association.resource_name !== 'user')
                                        .map((association: any) => (
                                            <div className='flex items-center gap-2 p-1 rounded border border-lightgrey dark:border-darkgrey'>
                                                <span>
                                                    {association.readable_name} (
                                                    {association.resource_name.replaceAll('_', ' ')})
                                                </span>
                                                <ExistingTaskCheck
                                                    resource_name={association.resource_name}
                                                    resource_id={association.resource_id}
                                                    department_id={task.department_id}
                                                    type_id={task.type_id}
                                                />
                                                <button onClick={() => toggleAssociations(association)}>
                                                    <FaTimes />
                                                </button>
                                            </div>
                                        ))}
                                </div>
                                <div className='flex flex-wrap'>
                                    <Button
                                        className='dark:border-darkgrey'
                                        variant={'outline'}
                                        onClick={() => setShowAssociations(true)}
                                    >
                                        + Add / Edit Associations
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CarefulCreateTaskButton
                        task={task}
                        createTaskMutation={createTaskMutation}
                        handleCreate={handleCreate}
                    />
                    <div className='flex items-center gap-2 mx-auto text-sm'>
                        <input
                            type='checkbox'
                            id='create_another_task'
                            name='create_another_task'
                            checked={createAnotherTask}
                            onChange={({ target }) => setCreateAnotherTask(target.checked)}
                        />
                        <label htmlFor='create_another_task'>Create Another Task</label>
                    </div>
                </div>
                <div
                    className={cn(
                        'flex flex-col gap-2 w-[350px]',
                        createTaskMutation.isPending && 'pointer-events-none opacity-60',
                        !task.department_id && 'pointer-events-none opacity-50'
                    )}
                >
                    <DueAtSelector
                        selectedDate={task.HTMLDateString}
                        setSelectedDate={setDate}
                        selectedTime={task.HTMLTimeString}
                        setSelectedTime={setTime}
                    />
                    <TaskAlertStateEditor
                        alertOffset={task.alert_offset}
                        sendEmailAlert={task.send_email_alert}
                        sendSlackAlert={task.send_slack_alert}
                        handleChange={handleChange}
                    />
                    <DueAtCalendar selectedDate={task.HTMLDateString} setSelectedDate={setDate} />
                </div>
            </div>
            {showAssignments && (
                <TaskAssignmentsModal
                    closeModal={() => setShowAssignments(false)}
                    associations={task.associations}
                    toggleAssociation={toggleAssociations}
                    handleSave={() => setShowAssignments(false)}
                    handleReset={() => {
                        setTask((prev: any) => {
                            const newState = structuredClone(prev)
                            newState.associations = [
                                ...newState.associations.filter(
                                    (association: any) => association.resource_name !== 'user'
                                ),
                                {
                                    resource_name: 'user',
                                    readable_name: `${user.firstName} ${user.lastName}`,
                                    resource_id: user.id,
                                },
                            ]
                            return newState
                        })
                    }}
                />
            )}
            {showAssociations && (
                <TaskAssociationsModal
                    closeModal={() => setShowAssociations(false)}
                    associations={task.associations}
                    toggleAssociation={toggleAssociations}
                    handleSave={() => setShowAssociations(false)}
                    handleReset={() => {
                        setTask((prev: any) => {
                            const newState = structuredClone(prev)
                            newState.associations = newState.associations.filter(
                                (association: any) => association.resource_name === 'user'
                            )
                            return newState
                        })
                    }}
                />
            )}
        </div>
    )
}
