import { FaMap, FaRegStar, FaStar, FaTrash } from 'react-icons/fa'
import { monthDateShortFormatter } from '../helpers'
import { useDeleteRoadmap, useUpdateRoadmap } from '../api/useQueries'
import { useState } from 'react'

export default function RoadmapRow({ roadmap, last }: { roadmap: any; last?: boolean }) {
    const [_popover, setPopover] = useState<undefined | string>()
    const createdAt = monthDateShortFormatter.format(new Date(roadmap.created_at))
    const updatedAt = monthDateShortFormatter.format(new Date(roadmap.updated_at))

    const updateRoadmapMutation = useUpdateRoadmap()
    const deleteRoadmapMutation = useDeleteRoadmap()

    const handleUpdateRoadmap = async (roadmapUpdate: any) => {
        if (JSON.stringify(roadmap) === JSON.stringify({ ...roadmap, ...roadmapUpdate })) {
            return
        }
        updateRoadmapMutation.mutate(roadmapUpdate, {
            onSuccess: () => {
                setPopover(undefined)
            },
        })
    }

    const handleDeleteRoadmap = async () => {
        deleteRoadmapMutation.mutate(roadmap.id)
    }

    return (
        <div
            className={`${
                deleteRoadmapMutation.isPending ? 'opacity-50 pointer-events-none' : ''
            } hover:bg-lightgrey/50 dark:hover:bg-darkaccent/50 flex gap-4 items-center text-[14px] py-2 px-4 ${
                !last ? 'border-b border-lightgrey dark:border-darkaccent' : ''
            }`}
        >
            <p className='shrink-0 text-darkgrey w-4 text-[12px] font-medium font-robotomono'>{roadmap.id}</p>
            <FaMap className='shrink-0' />
            <p className='font-semibold w-full'>{roadmap.title}</p>
            <button
                className={`${
                    updateRoadmapMutation.isPending
                        ? 'opacity-50 pointer-events-none'
                        : 'dark:opacity-75 dark:hover:opacity-100 transition-all'
                }`}
                onClick={() => handleUpdateRoadmap({ id: roadmap.id, pinned: !roadmap.pinned })}
            >
                {roadmap.pinned ? <FaStar /> : <FaRegStar />}
            </button>
            <p className='shrink-0 text-[12px]'>{createdAt}</p>
            <p className='shrink-0 text-[12px]'>{updatedAt}</p>
            <button
                className={`opacity-25 hover:opacity-100 transition-all hover:text-red hover:dark:text-lightred`}
                onClick={handleDeleteRoadmap}
            >
                <FaTrash />
            </button>
        </div>
    )
}
