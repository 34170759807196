import { FileNameOptionsType } from 'procurement/components/lists/FileListV2'
import { parseResObject, signatureHTMLString, sortByAlphanumeric, vFetch } from '../../helpers'
import { UserInit } from '../../users/users.types'
import { CompanyFilters, CompanyTaskFilters } from '../Procurements.types'
import { CompanyProductType } from '../components/CompanyProduct'
import { CompanyTaskType } from 'procurement/Procurements.types'
import { createdAtSort } from '../helpers'

// queries/companies.ts
const COMPANIES_BASE_URL = '/v1/procurement/companies'

export const getCompany = async ({ companyId }: { companyId: any }) => {
    const filters = {
        id: companyId,
        lead_type: [],
        lifecycle_status: [],
        assigned_to: [],
        lastCursor: [],
    }
    const data = await vFetch(`${COMPANIES_BASE_URL}/V2?filters=${JSON.stringify(filters)}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load company',
    })
    delete data.companies[0].updated_at
    return {
        ...data,
        company: data.companies[0],
    }
}
export const getUnfulfilledLineItems = async (vendor: string | undefined) => {
    const params = vendor ? `?vendor=${vendor}` : ''
    return await vFetch(`${COMPANIES_BASE_URL}/unfulfilledLineItems${params}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load unfulfilled items',
    })
}

export const getCompanies = async ({ filters }: { filters: CompanyFilters }) => {
    const data = await vFetch(`${COMPANIES_BASE_URL}/V2?filters=${JSON.stringify(filters)}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load companies',
    })
    return data
}
export const getSearchCompanies = async ({ filters, search }: { filters: CompanyFilters; search: any }) => {
    const newFilters = {
        ...filters,
        search: encodeURIComponent(search),
    }
    const data = await vFetch(`${COMPANIES_BASE_URL}/V2/search?filters=${JSON.stringify(newFilters)}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load companies search',
    })
    return data
}

//company mutations

export const createCompany = async (company: any, scoreOptionSelections: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify({
            ...company,
            scoreOptionSelections,
        }),
        reThrowErr: true,
    })
}
export const createCompanyVendorMap = async (company: any, scoreOptionSelections: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}/vendorMap`, {
        method: 'POST',
        body: JSON.stringify({
            ...company,
            scoreOptionSelections,
        }),
        reThrowErr: true,
        reThrowMessage: 'Failed to create company',
    })
}
export const duplicateCompany = async (company: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}/duplicate`, {
        method: 'POST',
        body: JSON.stringify({
            ...company,
        }),
        reThrowErr: true,
        reThrowMessage: 'Failed to create company',
    })
}

export const updateCompany = async (company: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}`, {
        method: 'PUT',
        body: JSON.stringify({
            ...company,
        }),
        reThrowErr: true,
    })
}
export const restoreCompany = async (id: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}/restore`, {
        method: 'PUT',
        body: JSON.stringify({
            id,
        }),
        reThrowErr: true,
    })
}
export const deleteCompany = async (company: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}`, {
        method: 'DELETE',
        body: JSON.stringify({
            id: company.id,
        }),
        reThrowErr: true,
        reThrowMessage: 'Failed to delete company',
    })
}
export const archiveCompany = async (company: any) => {
    return await vFetch(`${COMPANIES_BASE_URL}`, {
        method: 'PUT',
        body: JSON.stringify({
            id: company.id,
            is_archived: 1,
        }),
        reThrowErr: true,
        reThrowMessage: 'Failed to update company',
    })
}

//company tasks
const TASKS_BASE_URL = '/v1/procurement/tasks'

export const getTasks = async ({ filters }: { filters: CompanyTaskFilters }) => {
    const data = await vFetch(`${TASKS_BASE_URL}/testing?filters=${JSON.stringify(filters)}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load tasks',
    })
    return data
}
export const getTasksCalendar = async ({ filters }: { filters: CompanyTaskFilters }) => {
    const data = await vFetch(`${TASKS_BASE_URL}/testing?filters=${JSON.stringify(filters)}`, {
        reThrowErr: true,
        reThrowMessage: '',
    })
    return data
}
export const getSearchTasks = async ({ filters, search }: { filters: CompanyTaskFilters; search: any }) => {
    const newFilters = {
        ...filters,
        search: search,
    }
    const data = await vFetch(`${TASKS_BASE_URL}/testing?filters=${JSON.stringify(newFilters)}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load tasks search',
    })
    return data
}

// task mutations

export const updateTask = async (task: CompanyTaskType) => {
    return await vFetch(`${TASKS_BASE_URL}`, {
        method: 'PUT',
        body: JSON.stringify({
            ...task,
        }),
        reThrowErr: true,
        reThrowMessage: 'Failed to update task',
    })
}
export const deleteTask = async (task: CompanyTaskType) => {
    return await vFetch(`${TASKS_BASE_URL}`, {
        method: 'DELETE',
        body: JSON.stringify({
            id: task.id,
        }),
        reThrowErr: true,
        reThrowMessage: 'Failed to delete task',
    })
}

//company detail

export const getEvents = async ({
    companyId,
    users,
    search,
}: {
    companyId: number | undefined
    users: UserInit[]
    search?: string
}) => {
    const queryParams = [
        companyId ? `company_id=${companyId}` : null,
        search ? `search=${encodeURIComponent(search)}` : null,
    ]
        .filter((v) => v)
        .join('&')

    // const data = await vFetch(`/v1/procurement/events?company_id=${companyId}${search ? `&search=${search}` : ''}`, {
    const data = await vFetch(`/v1/procurement/events?${queryParams}`, {
        reThrowErr: true,
        reThrowMessage: 'Failed to load company activity',
        cb: (res: any) => {
            const sortedEvents = (res.events || [])
                .sort((a: any, b: any) => {
                    if (new Date(a.created_at).getTime() < new Date(b.created_at).getTime()) {
                        return -1
                    }
                    return 1
                })
                .filter((e: any) => e.type !== 'hidden')
            const parsedEvents: any[] = []
            let currentEventTime
            let currentEventGroup = []
            for (const event of sortedEvents) {
                if (event.message.includes('task')) {
                    continue
                }
                if (event.type !== 'basic') {
                    parsedEvents.push(event)
                    continue
                }
                if (!currentEventTime) {
                    currentEventTime = new Date(event.created_at).getTime()
                    currentEventGroup = [event]
                } else if (Math.abs(new Date(event.created_at).getTime() - currentEventTime) < 5000) {
                    currentEventGroup.push(event)
                } else {
                    if (currentEventGroup.length > 1) {
                        const foundUser = users.find((u) => u.id === event.user_id)
                        parsedEvents.push({
                            type: 'basic-event-group',
                            message: `${foundUser?.first_name} ${foundUser?.last_name} (${foundUser?.user_id}) made multiple updates.`,
                            created_at: event.created_at,
                            events: currentEventGroup,
                        })
                    } else if (currentEventGroup.length === 1) {
                        parsedEvents.push(currentEventGroup[0])
                    }
                    currentEventGroup = [event]
                    currentEventTime = new Date(event.created_at).getTime()
                }
            }
            if (currentEventGroup.length > 1) {
                const firstEvent = currentEventGroup[0]
                const foundUser = users.find((u) => u.id === firstEvent.user_id)
                parsedEvents.push({
                    type: 'basic-event-group',
                    message: `${foundUser?.first_name} ${foundUser?.last_name} (${foundUser?.user_id}) made multiple updates.`,
                    created_at: firstEvent.created_at,
                    events: currentEventGroup,
                })
            } else if (currentEventGroup.length === 1) {
                parsedEvents.push(currentEventGroup[0])
            }
            const parsedData = { success: res.success, events: parsedEvents }
            return parsedData
        },
    })
    return data
}

export const getIncomingEmails = async ({
    companyEmail,
    contactList,
    search,
}: {
    companyEmail: string
    contactList: any[]
    search?: string
}) => {
    const emailList = [companyEmail, ...contactList.map((c) => c.email)].filter((v) => v).join(',')
    if (emailList.length) {
        const queryParams = [
            emailList.length ? `from=${emailList}` : null,
            emailList ? `to=${emailList}` : null,
            search ? `search=${encodeURIComponent(search)}` : null,
        ]
            .filter((v) => v)
            .join('&')
        return await vFetch(`/v1/procurement/emails?${queryParams}`, {
            reThrowErr: true,
            reThrowMessage: 'Failed to load company emails',
            cb: (res: any) => {
                res.emails.forEach((email: any) => {
                    return parseResObject(email)
                })
                const sortedIncomingEmails = res.emails.sort(createdAtSort)
                const threadIds = new Set(res.emails.map((email: any) => email.thread_id))
                let uniqueIncomingEmails: any[] = []
                sortedIncomingEmails.forEach((email: any) => {
                    if (threadIds.has(email.thread_id)) {
                        uniqueIncomingEmails.push(email)
                        threadIds.delete(email.thread_id)
                    }
                })
                res.emails = uniqueIncomingEmails
                return res
            },
        })
    }
    return []
}

// variant option setup

export const createOption = async ({ option }: { option: any }) => {
    return await vFetch('/v1/procurement/variants/option', {
        method: 'POST',
        body: JSON.stringify({
            option,
        }),
        reThrowErr: true,
    })
}
export const getOptions = async (id: string | number) => {
    const queryParams = [id ? `product_id=${id}` : null].filter((v) => v).join('&')
    return await vFetch(`/v1/procurement/variants/option?${queryParams}`, {
        reThrowErr: true,
    })
}
export const updateOption = async ({ options, product_id }: { options: any[]; product_id: string | number }) => {
    return await vFetch('/v1/procurement/variants/option', {
        method: 'PUT',
        body: JSON.stringify({
            options,
            product_id,
        }),
        reThrowErr: true,
    })
}
export const deleteOption = async ({ optionId }: { optionId: string | number }) => {
    return await vFetch('/v1/procurement/variants/option', {
        method: 'DELETE',
        body: JSON.stringify({ id: optionId }),
        reThrowErr: true,
    })
}
export const createOptionValue = async ({ optionValue }: { optionValue: any }) => {
    return await vFetch('/v1/procurement/variants/optionValue', {
        method: 'POST',
        body: JSON.stringify({
            optionValue,
        }),
        reThrowErr: true,
    })
}
export const getOptionValues = async (id: string | number) => {
    const queryParams = [id ? `product_id=${id}` : null].filter((v) => v).join('&')
    return await vFetch(`/v1/procurement/variants/optionValue?${queryParams}`, {
        reThrowErr: true,
    })
}

export const updateOptionValue = async ({
    optionValues,
    product_id,
}: {
    optionValues: CompanyProductType[]
    product_id: string | number
}) => {
    return await vFetch('/v1/procurement/variants/optionValue', {
        method: 'PUT',
        body: JSON.stringify({
            optionValues,
            product_id,
        }),
        reThrowErr: true,
    })
}
export const deleteOptionValue = async ({
    optionValueId,
    productId,
}: {
    optionValueId: string | number
    productId: string | number
}) => {
    return await vFetch('/v1/procurement/variants/optionValue', {
        method: 'DELETE',
        body: JSON.stringify({ id: optionValueId, productId }),
        reThrowErr: true,
    })
}

// product

export const getProductTypes = async () => {
    return await vFetch('/v2/products/types', {
        reThrowErr: true,
    })
}
export const getShopifyVendors = async () => {
    return await vFetch('/v2/products/vendors', {
        reThrowErr: true,
    })
}
export const getCompanyNames = async () => {
    return await vFetch(
        '/v1/procurement/companies/v2?filters={"fields":"c.id,c.name,c.shopify_vendor_name","limit":"10000"}',
        {
            reThrowErr: true,
        }
    )
}
export const getCompanySales = async (vendorName: string) => {
    return await vFetch(`${COMPANIES_BASE_URL}/sales?vendorName=${vendorName}`, {
        reThrowErr: true,
    })
}
export const getCompanySalesList = async (selectedStartDate: Date | undefined) => {
    return await vFetch(
        `${COMPANIES_BASE_URL}/salesList${selectedStartDate ? '?startDate=' + JSON.stringify(selectedStartDate) : ''}`,
        {
            reThrowErr: true,
        }
    )
}
export const getShopifyGroupNames = async () => {
    return await vFetch('/v2/products/groupNames', {
        reThrowErr: true,
    })
}
export const getShopifyGroupOptions = async (customGroup: string) => {
    return await vFetch(`/v2/products/groupOptions?custom_group=${customGroup}`, {
        cb: (res: any) => {
            return res
        },
        reThrowErr: true,
    })
}
export const getContactTypes = async () => {
    return await vFetch('/v1/vendors/contacts/types', {
        cb: (res: any) => {
            const types = res.types.sort((a: string, b: string) => sortByAlphanumeric(a, b))
            res.types = types
            return res
        },
        reThrowErr: true,
    })
}
export const getShopifySkus = async () => {
    return await vFetch('/v1/procurement/products/skus', {
        reThrowErr: true,
    })
}
export const getProducts = async ({
    statuses,
    related_variants_group_id,
    search,
    companyId,
    lastCursor,
    limit,
    sortBy,
    fields,
    filterVariants,
}: {
    statuses: string[]
    related_variants_group_id: number | string
    search: string
    companyId: number | undefined
    lastCursor: number | undefined
    limit: number
    sortBy: { field: string; direction: string }
    fields: string
    filterVariants: boolean
}) => {
    const queryParams = [
        statuses ? `status=${statuses.join(',')}` : null,
        related_variants_group_id ? `related_variants_group_id=${related_variants_group_id}` : null,
        search ? `search=${encodeURIComponent(search)}` : null,
        lastCursor ? `lastCursor=${lastCursor}` : null,
        limit ? `limit=${limit}` : null,
        fields ? `fields=${fields}` : null,
        filterVariants ? `filterVariants=${filterVariants}` : null,
        sortBy ? `sortBy=${sortBy.field},${sortBy.direction}` : null,
        `company_id=${companyId}`,
    ]
        .filter((v) => v)
        .join('&')
    return await vFetch(`/v1/procurement/products?${queryParams}`, {
        reThrowErr: true,
    }).then((res) => ({
        products: (res.products || [])
            .map((p: any) => ({
                ...p,
                cost: p.cost || 0,
                list_price: p.list_price || 0,
                shipping_fee: p.shipping_fee || 0,
            }))
            .map(parseResObject),
        lastCursor: res.lastCursor,
    }))
}

export const getAllProducts = async ({ companyId, limit }: { companyId: number | undefined; limit: number }) => {
    const queryParams = [limit ? `limit=${limit}` : null, `company_id=${companyId}`].filter((v) => v).join('&')
    return await vFetch(`/v1/procurement/products/all?${queryParams}`, {
        reThrowErr: true,
    }).then((res) => {
        return {
            products: (res.allProducts || [])
                .map((p: any) => ({
                    ...p,
                    cost: p.cost || 0,
                    list_price: p.list_price || 0,
                    shipping_fee: p.shipping_fee || 0,
                }))
                .map(parseResObject),
        }
    })
}
export const getProduct = async (id: string | number) => {
    const queryParams = [id ? `id=${id}` : null].filter((v) => v).join('&')
    return await vFetch(`/v1/procurement/products/detail?${queryParams}`, {
        reThrowErr: true,
    }).then((res) => ({
        product: (res.product || [])
            .map((p: any) => ({
                ...p,
                cost: p.cost || 0,
                list_price: p.list_price || 0,
                shipping_fee: p.shipping_fee || 0,
            }))
            .map(parseResObject)[0],
    }))
}

export const createProduct = async ({
    companyId,
    related_variants_group_id,
}: {
    companyId: number | undefined
    related_variants_group_id: string | null
}) => {
    return await vFetch('/v1/procurement/products', {
        method: 'POST',
        body: JSON.stringify({
            sku: Date.now().toString(),
            company_id: companyId,
            status: 'calculating-margins',
            related_variants_group_id,
            weblinks: [{ link: '', title: 'Manufacturer Website' }],
        }),
        reThrowErr: true,
    })
}
export const createShopifyProduct = async ({ product, info }: { product: number | undefined; info: any }) => {
    return await vFetch('/v1/procurement/products/createShopify', {
        method: 'POST',
        body: JSON.stringify({
            product,
            info,
        }),
        reThrowErr: true,
    })
}
export const createBulkShopifyVariants = async ({ products, info }: { products: number | undefined; info: any }) => {
    return await vFetch('/v1/procurement/products/createShopifyVariants', {
        method: 'POST',
        body: JSON.stringify({
            products,
            info,
        }),
        reThrowErr: true,
    })
}
export const createImportedProducts = async (products: any) => {
    return await vFetch('/v1/procurement/products/import', {
        method: 'POST',
        body: JSON.stringify({ products }),
        reThrowErr: true,
    })
}
export const duplicateProduct = async (product: any) => {
    return await vFetch(`/v1/procurement/products/duplicate`, {
        method: 'POST',
        body: JSON.stringify({
            ...product,
        }),
        reThrowErr: true,
    })
}

export const upsertProducts = async ({
    products,
    company_id,
}: {
    products: CompanyProductType[]
    company_id: string | number
}) => {
    return await vFetch('/v1/procurement/products', {
        method: 'PUT',
        body: JSON.stringify({
            products,
            company_id,
        }),
        reThrowErr: true,
        skipToast: true,
    })
}
export const updateProductPricingValues = async ({
    products,
    company_id,
}: {
    products: { id: number; cost: number; shipping_fee: number; list_price: number }[]
    company_id: string | number
}) => {
    return await vFetch('/v1/procurement/products/pricing', {
        method: 'PUT',
        body: JSON.stringify({
            products,
            company_id,
        }),
        skipToast: true,
        reThrowErr: true,
    })
}
export const setPrimaryVariant = async ({
    products,
    company_id,
}: {
    products: CompanyProductType[]
    company_id: string | number
}) => {
    return await vFetch('/v1/procurement/products/variantGroupings', {
        method: 'PUT',
        body: JSON.stringify({
            products,
            company_id,
        }),
        reThrowErr: true,
    })
}

export const deleteProduct = async ({ productId }: { productId: string | number }) => {
    return await vFetch('/v1/procurement/products', {
        method: 'DELETE',
        body: JSON.stringify({ id: productId }),
        reThrowErr: true,
    })
}

export const getContacts = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/v1/procurement/contacts?company_id=${companyId}`, {
        reThrowErr: true,
    })
}
export const getVendorContacts = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/v1/procurement/contacts/vendorContacts?company_id=${companyId}`, {
        reThrowErr: true,
    })
}
export const getInfoBlocks = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/v1/procurement/infoBlocks?company_id=${companyId}`, {
        reThrowErr: true,
    })
}
export const getVendorInfo = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/v1/vendors/info?company_id=${companyId}`, {
        reThrowErr: true,
    })
}

export const getAndReturnContacts = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/v1/procurement/contacts?company_id=${companyId}`, {
        reThrowErr: true,
    })
}

export const getFiles = async ({
    companyId,
    filters,
    search,
}: {
    companyId: number | undefined
    filters?: { fileTypes: FileNameOptionsType[] }
    search?: string
}) => {
    const queryParams = [
        companyId ? `company_id=${companyId}` : null,
        filters ? `filters=${encodeURIComponent(JSON.stringify(filters))}` : null,
        search ? `search=${encodeURIComponent(search)}` : null,
    ]
        .filter((v) => v)
        .join('&')

    return await vFetch(`/v1/procurement/files?${queryParams}`, {
        reThrowErr: true,
    })
}
export const getEmails = async ({ companyId, search }: { companyId: number | undefined; search?: string }) => {
    return await vFetch(`/v1/email?table_id=companies=${companyId}${search ? `&search=${search}` : ''}`, {
        reThrowErr: true,
        cb: (res: any) => {
            res.emails.forEach((email: any) => {
                return parseResObject(email)
            })
            return res
        },
    })
}

// COMPANY SCORES

export const updatePositionMap = async (positionMap: any) => {
    return await vFetch('/v1/procurement/scores/position', {
        method: 'PUT',
        body: JSON.stringify(positionMap),
        reThrowErr: true,
    })
}

// SCORE GROUP SETUP
export const createScoreSetupGroup = async (group: any) => {
    return await vFetch('/v1/procurement/scores/groups', {
        method: 'POST',
        body: JSON.stringify(group),
        reThrowErr: true,
    })
}
export const getScoreSetupGroups = async () => {
    return await vFetch('/v1/procurement/scores/groups', {
        reThrowErr: true,
        cb: (res: any) => {
            res.groups.forEach((group: any) => {
                return parseResObject(group)
            })
            return res
        },
    })
}
export const updateScoreSetupGroup = async (group: any) => {
    return await vFetch('/v1/procurement/scores/groups', {
        method: 'PUT',
        body: JSON.stringify({
            group: {
                id: group.id,
                name: group.name,
                is_required: group.is_required,
            },
        }),
        reThrowErr: true,
    })
}
export const deleteScoreSetupGroup = async ({ group }: any) => {
    return await vFetch('/v1/procurement/scores/groups', {
        method: 'DELETE',
        body: JSON.stringify(group),
        reThrowErr: true,
    })
}

// SCORE OPTION SETUP
export const createScoreSetupOption = async (option: any) => {
    return await vFetch('/v1/procurement/scores/options', {
        method: 'POST',
        body: JSON.stringify(option),
        reThrowErr: true,
    })
}
export const getScoreSetupOptions = async () => {
    return await vFetch('/v1/procurement/scores/options', {
        reThrowErr: true,
    })
}
export const updateScoreSetupOption = async (scoreOption: any) => {
    return await vFetch('/v1/procurement/scores/options', {
        method: 'PUT',
        body: JSON.stringify(scoreOption),
        reThrowErr: true,
    })
}
export const deleteScoreSetupOption = async ({ option }: any) => {
    return await vFetch('/v1/procurement/scores/options', {
        method: 'DELETE',
        body: JSON.stringify({
            id: option.id,
        }),
        reThrowErr: true,
    })
}

export const getScores = async ({ companyId }: { companyId: number | undefined }) => {
    return await vFetch(`/v1/procurement/scores?company_id=${companyId}`, {
        reThrowErr: true,
    })
}

export const updateScore = async ({ companyId, option }: any) => {
    return await vFetch('/v1/procurement/scores', {
        method: 'POST',
        body: JSON.stringify({
            company_id: companyId,
            procurement_score_group_id: option.group_id,
            procurement_score_option_id: option.option_id,
        }),
        reThrowErr: true,
    })
}
export const deleteScore = async ({ companyId, option }: any) => {
    return await vFetch('/v1/procurement/scores', {
        method: 'DELETE',
        body: JSON.stringify({
            company_id: companyId,
            procurement_score_group_id: option.group_id,
            procurement_score_option_id: option.option_id,
        }),
        reThrowErr: true,
    })
}

// company details mutations

export const createTaskModal = async ({ task }: any) => {
    return await vFetch('/v1/procurement/tasks', {
        method: 'POST',
        body: JSON.stringify(task),
        reThrowErr: true,
    })
}

//notes

export const createNote = async ({
    user,
    note,
    companyId,
    task,
    createTask,
}: {
    user: any
    note: any
    companyId: number
    task?: any
    createTask: boolean
}) => {
    await vFetch('/v1/procurement/events', {
        method: 'POST',
        body: JSON.stringify({
            type: 'note',
            message: `${user.firstName} ${user.lastName} (${user.user_id}) Added a new note.`,
            value: note,
            raw_data: note,
            company_id: companyId,
        }),
        reThrowErr: true,
    })
    if (createTask) {
        return await vFetch('/v1/procurement/tasks', {
            method: 'POST',
            body: JSON.stringify({
                ...task,
                title: note,
            }),
            reThrowErr: true,
        })
    }
}

export const deleteNote = async (id: number | string) => {
    await vFetch('/v1/procurement/events', {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        reThrowErr: true,
    })
}

export const updateNote = async (note: any) => {
    delete note.company_name
    await vFetch('/v1/procurement/events', {
        method: 'PUT',
        body: JSON.stringify({ id: note.id, value: note }),
        reThrowErr: true,
    })
}

//info blocks

export const importInfoBlock = async (vendorName: any, companyId: any) => {
    return await vFetch('/v1/procurement/infoBlocks/import', {
        method: 'POST',
        body: JSON.stringify({ vendorName, companyId }),
        reThrowErr: true,
    })
}
export const createInfoBlock = async (infoBlock: any) => {
    return await vFetch('/v1/procurement/infoBlocks', {
        method: 'POST',
        body: JSON.stringify(infoBlock),
        reThrowErr: true,
    })
}

export const deleteInfoBlock = async (id: number | string) => {
    await vFetch('/v1/procurement/infoBlocks', {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        reThrowErr: true,
    })
}

export const updateInfoBlock = async (infoBlock: any) => {
    await vFetch('/v1/procurement/infoBlocks', {
        method: 'PUT',
        body: JSON.stringify({ ...infoBlock }),
        reThrowErr: true,
    })
}
//contacts

export const createContact = async (contact: any) => {
    return await vFetch('/v1/procurement/contacts', {
        method: 'POST',
        body: JSON.stringify(contact),
        reThrowErr: true,
    })
}

export const deleteContact = async (id: number | string) => {
    await vFetch('/v1/procurement/contacts', {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        reThrowErr: true,
    })
}
export const archiveVendorContact = async (id: number | string) => {
    await vFetch('/v1/procurement/contacts/vendorContacts', {
        method: 'PUT',
        body: JSON.stringify({ id }),
        reThrowErr: true,
    })
}

export const updateContact = async (contact: any) => {
    await vFetch('/v1/procurement/contacts', {
        method: 'PUT',
        body: JSON.stringify({ ...contact }),
        reThrowErr: true,
    })
}

//files

export const createFile = async ({
    files,
    types,
    companyId,
    user,
}: {
    files: FileList | null
    types: string[]
    companyId: number
    user: UserInit
}) => {
    if (!files) {
        return
    }
    for (let i = 0; i < Array.from(files).length; i++) {
        const file = Array.from(files)[i]
        const type = types[i]
        const data = new FormData()
        data.append(type, file, file.name)
        await vFetch(`/v1/procurement/files?company_id=${companyId}&user_id=${user.id}`, {
            method: 'POST',
            body: data,
            contentType: 'skip',
            reThrowErr: true,
        })
    }
}

export const deleteFile = async (file: any) => {
    await vFetch('/v1/procurement/files', {
        method: 'DELETE',
        body: JSON.stringify({ id: file.id, key: file.src.replace('https://fpdash-bucket.s3.amazonaws.com', '') }),
        reThrowErr: true,
    })
}

export const updateFile = async (file: any) => {
    return vFetch('/v1/procurement/files', {
        method: 'PUT',
        body: JSON.stringify(file),
        reThrowErr: true,
    })
}

export const updateLineItems = async ({ lineItemIds }: { lineItemIds: any }) => {
    await vFetch(`/v1/procurement/products/lineItems`, {
        method: 'POST',
        body: JSON.stringify({
            lineItemIds,
        }),
        reThrowErr: true,
    })
}
//emails

export const createEmail = async ({ email, companyId, user }: { email: any; companyId: number; user: UserInit }) => {
    await vFetch(`/v1/email`, {
        method: 'POST',
        body: JSON.stringify({
            ...email,
            emailText: email.body.trim(),
            user_id: user.id,
            foreign_id: companyId,
            foreign_table: 'companies',
            body: `
            <style>
                pre {
                    font-family: arial, sans-serif;
                    word-wrap: break-word;
                    white-space: pre-wrap
                }
            </style>
            <pre style="font-family: arial, sans-serif; word-wrap: break-word; white-space: pre-wrap;">${email.greeting.trim()}\n\n${email.body.trim()}</pre>
            ${signatureHTMLString(user)}`,
        }),
        reThrowErr: true,
    })
}

//assignments

export const addAssignment = async ({
    company_id,
    user_id,
}: {
    company_id: string | number
    user_id: string | number
}) => {
    await vFetch('/v1/procurement/assignments', {
        method: 'POST',
        body: JSON.stringify({ assignments: [{ company_id, user_id }] }),
        reThrowErr: true,
    })
}

export const removeAssignment = async ({
    company_id,
    user_id,
}: {
    company_id: string | number
    user_id: string | number
}) => {
    await vFetch('/v1/procurement/assignments', {
        method: 'DELETE',
        body: JSON.stringify({ company_id, user_id }),
        reThrowErr: true,
    })
}

export const getAlerts = async ({ companyId }: { companyId?: number | string }) => {
    vFetch(`/v1/procurement/alerts?affects=products&company_id=${companyId}`)
    return await vFetch(`/v1/procurement/alerts?affects=products&company_id=${companyId}`, {
        reThrowErr: true,
    })
}

export const createAlert = async (body: any) => {
    return await vFetch('/v1/procurement/alerts', {
        method: 'POST',
        body: JSON.stringify(body),
        reThrowErr: true,
    })
}
export const updateAlert = async (body: any) => {
    return await vFetch('/v1/procurement/alerts', {
        method: 'PUT',
        body: JSON.stringify(body),
        reThrowErr: true,
    })
}

export const deleteAlert = async (alertId: string | number) => {
    return await vFetch('/v1/procurement/alerts', {
        method: 'DELETE',
        body: JSON.stringify({
            id: alertId,
        }),
        reThrowErr: true,
    })
}

export const addComment = async ({ value, product_id }: { value: string; product_id: string | number }) => {
    return await vFetch('/v1/procurement/products/comments', {
        method: 'POST',
        body: JSON.stringify({
            value,
            product_id,
        }),
        skipToast: true,
        reThrowErr: true,
    })
}
export const addVariantGrouping = async ({
    sku,
    product,
    company_id,
}: {
    sku: string | number
    product: any
    company_id: number | string
}) => {
    return await vFetch('/v1/procurement/products/variantGroupings', {
        method: 'POST',
        body: JSON.stringify({
            sku,
            product,
            company_id,
        }),
        reThrowErr: true,
    })
}
export const deleteVariantGrouping = async ({
    related_variants_group_id,
}: {
    related_variants_group_id: string | number
}) => {
    return await vFetch('/v1/procurement/products/variantGroupings', {
        method: 'DELETE',
        body: JSON.stringify({
            related_variants_group_id,
        }),
        reThrowErr: true,
    })
}

//PARENT COMPANIES

export const getParentCompany = async (parentCompany: string) => {
    const data = await vFetch(`/v1/procurement/companies/parent?parent_company=${encodeURIComponent(parentCompany)}`, {
        reThrowErr: true,
    })
    return data
}

export const getParentCompanyContacts = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/v1/procurement/contacts/parent?${query}`, {
        reThrowErr: true,
    })
    data.contacts = data.contacts?.map((contact: any) => ({
        ...contact,
        company_name: companyNameMap[contact.company_id as keyof typeof companyNameMap],
    }))
    return data
}

export const getParentCompanyEmails = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/v1/procurement/emails/parent?${query}`, {
        reThrowErr: true,
    })
    data.emails?.map(parseResObject)
    data.emails = data.emails?.map((email: any) => ({
        ...email,
        company_name: companyNameMap[email.company_id as keyof typeof companyNameMap],
    }))
    return data
}

export const getParentCompanyGmails = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/v1/procurement/emails/parent/gmail?${query}`, {
        reThrowErr: true,
    })
    return data
}

export const getParentCompanyEvents = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/v1/procurement/events/parent?${query}`, {
        reThrowErr: true,
    })
    data.events = data.events?.map((event: any) => ({
        ...event,
        company_name: companyNameMap[event.company_id as keyof typeof companyNameMap],
    }))
    return data
}

export const getIncomingParentCompanyEmails = async (contactList: any[], companyNameMap: any) => {
    const emailList = contactList
        .map((c) => c.email)
        .filter((v) => v)
        .join(',')
    if (emailList.length) {
        const data = await vFetch(`/v1/procurement/emails?from=${emailList}&to=${emailList}`, {
            reThrowErr: true,
        })
        data.incomingEmails?.map(parseResObject)

        const sortedIncomingEmails = data.emails.sort(createdAtSort)
        const threadIds = new Set(data.emails.map((email: any) => email.thread_id))
        let uniqueIncomingEmails: any[] = []
        sortedIncomingEmails.forEach((email: any) => {
            if (threadIds.has(email.thread_id)) {
                uniqueIncomingEmails.push(email)
                threadIds.delete(email.thread_id)
            }
        })
        data.emails = uniqueIncomingEmails
        data.emails = data.emails?.map((gmail: any) => ({
            ...gmail,
            company_name: companyNameMap[gmail.company_id],
        }))
        return data
    }
    return []
}

export const getParentCompanyFiles = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/v1/procurement/files/parent?${query}`, {
        reThrowErr: true,
    })
    data.files = data.files?.map((file: any) => ({ ...file, company_name: companyNameMap[file.company_id] }))
    return data
}

export const getParentCompanyTasks = async (parentCompany: string, companyNameMap: any, search?: string) => {
    const query = [
        `parent_company=${encodeURIComponent(parentCompany)}`,
        search ? `search=${encodeURIComponent(search)}` : undefined,
    ]
        .filter((v) => v)
        .join('&')
    const data = await vFetch(`/v1/procurement/tasks/parent?${query}`, {
        reThrowErr: true,
    })
    data.tasks = data.tasks?.map((task: any) => ({ ...task, name: companyNameMap[task.company_id] }))
    return data
}

//PARENT COMPANIES MUTATIONS

export const createParentCompanyNote = async ({ note, user, company_ids }: any) => {
    await vFetch('/v1/procurement/events/parent', {
        method: 'POST',
        body: JSON.stringify({
            event: {
                type: 'note',
                message: `${user.firstName} ${user.lastName} (${user.user_id}) Added a new note.`,
                value: note,
                raw_data: note,
            },
            company_ids,
        }),
        reThrowErr: true,
    })
}
export const createParentCompanyContact = async ({ contact, company_ids }: any) => {
    await vFetch('/v1/procurement/contacts/parent', {
        method: 'POST',
        body: JSON.stringify({ contact, company_ids }),
        reThrowErr: true,
    })
}
