import {
    CA_MULTI_REGIONAL_TAX_EXEMPTION_CODES,
    CA_REGIONAL_TAX_EXEMPTION_CODES,
    US_TAX_EXEMPTION_CODES,
} from 'customersV2/constants'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { formatReadableExemption } from 'taxExemptions/helpers'

export const usTaxExemptionOptions = US_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const state = codeArray[1]
    const exemptionName = codeArray
        .slice(2)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country}-${state} ${exemptionName}`,
        value: code,
    }
})

export const caRegionalTaxExemptionOptions = CA_REGIONAL_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const state = codeArray[1]
    const exemptionName = codeArray
        .slice(2)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country}-${state} ${exemptionName}`,
        value: code,
    }
})

export const caTaxExemptionOptions = CA_MULTI_REGIONAL_TAX_EXEMPTION_CODES.map((code) => {
    const codeArray = code.split('_')
    const country = codeArray[0]
    const exemptionName = codeArray
        .slice(1)
        .map((word) => word[0] + word.slice(1).toLowerCase())
        .join(' ')
    return {
        label: `${country} ${exemptionName}`,
        value: code,
    }
})

export default function TaxExemptionSelector({
    exemptionTypes,
    setExemptionTypes,
}: {
    exemptionTypes: string
    setExemptionTypes: (types: string) => void
}) {
    const taxExemptionsRef = useRef<HTMLDivElement>(null)
    const [showTaxExemptions, setShowTaxExemptions] = useState(false)
    const [search, setSearch] = useState('')
    const exemptionTypesArray = exemptionTypes.split(',').filter((v) => v)
    const handleToggleTaxExemption = (code: string) => {
        if (exemptionTypesArray.includes(code)) {
            exemptionTypesArray.splice(
                exemptionTypesArray.findIndex((exemption) => code === exemption),
                1
            )
            setExemptionTypes(exemptionTypesArray.join(','))
            return
        }
        setExemptionTypes([...exemptionTypesArray, code].join(','))
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!taxExemptionsRef.current?.contains(e.target as HTMLElement)) {
                setShowTaxExemptions(false)
                setSearch('')
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div className='flex flex-col gap-1 min-w-[350px]'>
            <div
                ref={taxExemptionsRef}
                onClick={() => {
                    setShowTaxExemptions(true)
                    setSearch('')
                }}
                className='relative'
            >
                {showTaxExemptions && (
                    <button
                        className='absolute top-0 right-0 z-10'
                        onClick={() =>
                            setTimeout(() => {
                                setShowTaxExemptions(false)
                            }, 10)
                        }
                    >
                        <FaTimes />
                    </button>
                )}
                <Input
                    label='Exemption Types'
                    id='search'
                    name='search'
                    type='text'
                    placeholder='Search...'
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                />
                {showTaxExemptions && (
                    <div className='z-50 w-full max-h-[300px] overflow-auto absolute top-full left-0 bg-bg1 dark:bg-darkbg1 p-2 rounded-b shadow-md border border-lightgrey dark:border-darkgrey border-t-0 flex flex-col gap-1'>
                        <p className='font-bold border-b border-lightgrey dark:border-darkgrey pb-1 mb-1'>
                            US Tax Exemptions
                        </p>
                        {usTaxExemptionOptions
                            .filter((exemption) => exemption.label.toLowerCase().includes(search.toLowerCase()))
                            .map((option: any) => (
                                <button
                                    onClick={() => handleToggleTaxExemption(option.value)}
                                    className='flex gap-2 items-center whitespace-nowrap'
                                >
                                    <span
                                        className={`rounded-full w-4 h-4 ${
                                            exemptionTypes?.includes(option.value)
                                                ? 'border-4 border-blue dark:border-darkaccent1'
                                                : 'border border-grey/50 dark:darkgrey'
                                        }`}
                                    >
                                        &nbsp;
                                    </span>
                                    <span>{option.label}</span>
                                </button>
                            ))}
                        <p className='font-bold border-b border-lightgrey dark:border-darkgrey pb-1 my-1'>
                            CA Tax Exemptions
                        </p>
                        {caRegionalTaxExemptionOptions
                            .filter((exemption) => exemption.value.toLowerCase().includes(search.toLowerCase()))
                            .map((option: any) => (
                                <button
                                    onClick={() => handleToggleTaxExemption(option.value)}
                                    className='flex gap-2 items-center whitespace-nowrap'
                                >
                                    <span
                                        className={`rounded-full w-4 h-4 ${
                                            exemptionTypes?.includes(option.value)
                                                ? 'border-4 border-blue dark:border-darkaccent1'
                                                : 'border border-grey/50 dark:darkgrey'
                                        }`}
                                    >
                                        &nbsp;
                                    </span>
                                    <span>{option.label}</span>
                                </button>
                            ))}
                        {caTaxExemptionOptions
                            .filter((exemption) => exemption.value.toLowerCase().includes(search.toLowerCase()))
                            .map((option: any) => (
                                <button
                                    onClick={() => handleToggleTaxExemption(option.value)}
                                    className='flex gap-2 items-center whitespace-nowrap'
                                >
                                    <span
                                        className={`rounded-full w-4 h-4 ${
                                            exemptionTypes?.includes(option.value)
                                                ? 'border-4 border-blue dark:border-darkaccent1'
                                                : 'border border-grey/50 dark:darkgrey'
                                        }`}
                                    >
                                        &nbsp;
                                    </span>
                                    <span>{option.label}</span>
                                </button>
                            ))}
                    </div>
                )}
            </div>
            {/* <div className='mb-4 overflow-auto h-[160px]'> */}
            <div className='mb-4 overflow-auto'>
                {exemptionTypesArray?.map((exemption) => (
                    <div className='flex justify-between items-center p-2 border-b border-lightgrey dark:border-darkgrey text-sm '>
                        <p className='captialize'>{formatReadableExemption(exemption)}</p>
                        <button onClick={() => handleToggleTaxExemption(exemption)}>
                            <FaTimes />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}
