import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import CreateRoleModal from './CreateRoleModal'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'custom_components/component_Basics/Button'
import EditRoleModal from './EditRoleModal'
import { useGetRoles } from './api/useQueries'

export default function RolesScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const selectedRoleId = searchParams.get('role_id')
    const rolesQuery = useGetRoles({})
    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateModal', 'true')
        return setSearchParams(searchParams)
    }
    const toggleEditModal = (id?: number) => {
        if (!id) {
            searchParams.delete('role_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('role_id', id?.toString())
        return setSearchParams(searchParams)
    }
    const roles: any[] = rolesQuery.data?.roles || []
    return (
        <div>
            <div className='flex justify-between mb-4'>
                <h1 className='font-semibold text-2xl'>Roles</h1>
                <Button onClick={toggleCreateModal} size='sm' variant='outline'>
                    + Create Role
                </Button>
            </div>
            <div className='bg-bg1 dark:bg-darkbg1 shadow-md border border-lightgrey dark:border-darkgrey rounded text-sm leading-none'>
                <div className='grid grid-cols-4 p-2 gap-2 bg-accent1 text-white dark:bg-darkbg2 text-offwhite font-bold rounded-t'>
                    <p>ID</p>
                    <p>Name</p>
                    <p>Created</p>
                    <p>Last Updated</p>
                </div>
                {roles.map((role) => (
                    <div
                        key={`role-${role.id}`}
                        onClick={() => toggleEditModal(role.id)}
                        className='cursor-pointer grid grid-cols-4 p-2 gap-2 border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark"hover:bg-darkbg2'
                    >
                        <p>{role.id}</p>
                        <p>{role.title}</p>
                        <p>{role.created_at}</p>
                        <p>{role.updated_at}</p>
                    </div>
                ))}
            </div>
            {selectedRoleId && <EditRoleModal roleId={parseInt(selectedRoleId)} closeModal={toggleEditModal} />}
            {showCreateModal && <CreateRoleModal closeModal={toggleCreateModal} />}
        </div>
    )
}
