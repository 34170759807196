'use client'

import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import { FilterBarSelectorButton } from './FilterBarSelectorButton'
import { useEffect } from 'react'
import { useGetPathname } from 'productSetupV2/api/useQueries'

export function TaskListCaptionLabel({ ...props }: any) {
    const user = useSelector<any, any>((state) => state.user)
    const [searchParams, setSearchParams] = useSearchParams()
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const departments = departmentConfigQuery.data?.departments || []
    const previewTypeString = searchParams.get('calendarPreviewType') || ''
    const previewUserString = searchParams.get('calendarPreviewUser') || ''
    const typeValue = decodeURIComponent(previewTypeString)
        .split(',')
        .filter((v) => v)
    const userValue = decodeURIComponent(previewUserString)
        .split(',')
        .filter((v) => v)

    const types = departments
        ?.map((department: any) => {
            return department.types.flat()
        })
        .flat()
    const typeOptions = types?.map((type: any) => {
        return {
            label: type.value,
            value: type.id.toString(),
            icon: undefined,
        }
    })

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => user.type !== 'Archived')
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })
    const pathname = useGetPathname()
    return (
        <div {...props} className='flex flex-col gap-1 items-center'>
            {format(props.displayMonth, 'LLLL yyyy').toString()}
            {pathname != '/orders' && pathname != '/ordersV2' && (
                <div className='flex gap-3 items-center'>
                    <FilterBarSelectorButton
                        variant='outline'
                        align='end'
                        setFilterValues={() => {}}
                        options={typeOptions}
                        title={'Type'}
                        field={'calendarPreviewType'}
                        filterValues={typeValue}
                        searchToggle={true}
                        editSearchParams={true}
                        preview={false}
                        selectType='single'
                    />
                    <FilterBarSelectorButton
                        variant='outline'
                        align='end'
                        setFilterValues={() => {}}
                        options={userOptions}
                        title={'User'}
                        field={'calendarPreviewUser'}
                        filterValues={userValue}
                        searchToggle={true}
                        editSearchParams={true}
                        preview={false}
                        selectType='single'
                    />
                </div>
            )}
        </div>
    )
}
