import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { dateFormatter } from 'customersV2/helpers'
import { addBusinessDays } from 'date-fns'
import EmailModal from 'emailModal/EmailModal'
import Email from 'emails/Email'
import { cn, sendToast, vFetch } from 'helpers'
import Select from 'procurement/components/Select'
import React, { useEffect, useRef, useState } from 'react'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import { TbAlertTriangleFilled } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import { useCreateTask, useGetAllDepartmentConfigs, useGetTasks, useUpdateTask } from 'tasksV2/api/useQueries'
import { CREATE_TASK_BUTTON_CONTENT } from 'tasksV2/constants/tasks'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'
import { taxExemptionQueries } from 'taxExemptions/api/taxExemptionQueryKeys'
import {
    useDeleteTaxExemption,
    useGetExemptionEmailAttachmentsQuery,
    useGetTaxExemptionAuditQuery,
    useGetTaxExemptionDetail,
    useGetTaxExemptionEmailsQuery,
    useGetTaxForms,
    useUpdateTaxExemption,
} from 'taxExemptions/api/useQueries'
import TaxExemptionAudit from 'taxExemptions/components/TaxExemptionAudit'
import TaxExemptionSelector from 'taxExemptions/components/TaxExemptionSelector'
import TaxFormList from 'taxExemptions/lists/TaxFormList'

export default function EditTaxExemptionModal({ taxExemptionId }: { taxExemptionId: string }) {
    const user = useSelector((state: any) => state.user)
    const departmentsQuery = useGetAllDepartmentConfigs(user.id)
    const departments = departmentsQuery.data?.departments || []
    const accountingTaskDepartment = departments.find((d: any) => d.title === 'Accounting')
    const [searchParams, setSearchParams] = useSearchParams()
    const modalRef = useRef<HTMLDivElement>(null)
    const [editedFields, setEditedFields] = useState<any>({})
    const [showDelete, setShowDelete] = useState(false)
    const [showAuditHistory, setShowAuditHistory] = useState(false)
    const edited = Object.keys(editedFields).length > 0
    const navigate = useNavigate()
    const showCreateTaskModal = searchParams.get('showCreateTaskModal') === 'true'
    const tasksQuery = useGetTasks({ resourceTable: 'customer_tax_exemption_tasks', resourceIds: [taxExemptionId] })
    const tasks = tasksQuery.data?.tasks || []
    const taxExemptionQuery = useGetTaxExemptionDetail({ taxExemptionId })
    const exemption = (taxExemptionQuery.data?.taxExemptions || [])[0] || {}
    const exemptionTypes = editedFields.exemption_type ?? exemption.exemption_type ?? ''
    const [emailMode, setEmailMode] = useState<string | null>(null)
    const createTaskMutation = useCreateTask()
    const queryClient = useQueryClient()
    const setExemptionTypes = (types: string) => {
        setEditedFields(() => ({
            exemption_type: types,
        }))
    }
    const firstName = exemption?.calculated_full_name?.split(' ').shift()
    exemption.first_name = firstName

    // QUERIES //
    const taxFormsQuery = useGetTaxForms({ taxExemptionId })

    const taxExemptionAuditQuery = useGetTaxExemptionAuditQuery({
        table: 'customer_tax_exemptions',
        taxExemptionId,
        parent_id: 1,
    })
    const taxExemptionAudit = taxExemptionAuditQuery.data?.auditHistory || []

    const taxExemptionEmailQuery = useGetTaxExemptionEmailsQuery({
        table: 'tax_exemptions', // foreign table
        taxExemptionId,
    })
    const taxExemptionEmails = taxExemptionEmailQuery?.data || []
    const filteredExemptionEmails = (structuredClone(taxExemptionEmails.emails) || []).reverse().filter((a: any) => a)

    filteredExemptionEmails?.forEach(
        (e: any, i: number) => (e.normalized_event_index = filteredExemptionEmails.length - i)
    )

    const emailAttachmentsQuery = useGetExemptionEmailAttachmentsQuery({
        table: 'tax_exemptions', // foreign table
        taxExemptionId: taxExemptionId.toString(),
    })
    const { attachments: emailAttachments } = emailAttachmentsQuery?.data || {}

    const deleteTaxExemption = useDeleteTaxExemption()
    const updateTaxExemption = useUpdateTaxExemption()

    const closeModal = () => {
        navigate(`/customers/tax-exemptions?${searchParams.toString()}`)
    }

    const eventSource: {
        foreign_id: number | undefined | null
        foreign_table: string
    } = {
        foreign_id: exemption.id,
        foreign_table: 'tax_exemptions',
    }

    // FOLLOW UP TASKS
    const types = departments
        ?.map((department: any) => {
            return department.types.flat()
        })
        .flat()

    const prePopulatedValues = {
        department_id: accountingTaskDepartment?.id,
        type_id: types?.find((type: any) => type.value === 'TE Follow-up')?.id,
        associations: [
            {
                resource_id: user.id,
                resource_name: 'user',
                readable_name: `${user.firstName} ${user.lastName}`,
            },
            {
                resource_id: exemption.id,
                resource_name: 'customer_tax_exemption',
                readable_name: `${exemption.calculated_full_name || 'Customer'} TE`,
            },
        ],
    }
    const defaultTask = {
        alert_offset: 0,
        send_slack_alert: 1,
        HTMLDateString: HTMLDateInputFormatter(new Date()),
        HTMLTimeString: `08:00`,
        ...prePopulatedValues,
    }

    const handleCreateFollowUpTask = () => {
        const formattedTask = {
            ...defaultTask,
            title: `Follow up reminder for tax exemption #${exemption.id}`,
            description: `${
                exemption.calculated_full_name
                    ? `Customer: ${exemption.calculated_full_name}, Exemption created: ${dateFormatter(
                          new Date(exemption.created_at)
                      )}`
                    : `Exemption created: ${dateFormatter(new Date(exemption.created_at))}`
            }`,
            due_at: addBusinessDays(new Date(), 2).toISOString(),
            is_duplicate: undefined,
        }

        createTaskMutation.mutate(formattedTask, {
            onSuccess: () => {
                sendToast({ message: 'Follow up task created!' })
            },
            onError: (e) => {
                sendToast({ message: 'Something went wrong! Unable to create follow up task' })
            },
        })
    }

    function onEmailSubmit(): any {
        queryClient.invalidateQueries({ queryKey: taxExemptionQueries.taxExemptions.emails._def })
        queryClient.invalidateQueries({ queryKey: taxExemptionQueries.taxExemptions.attachments._def })
    }

    // HANDLERS
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            return {
                ...newState,
                [target.name]: target.value,
            }
        })
    }
    const handleSave = () => {
        updateTaxExemption.mutate(
            { taxExemption: { id: taxExemptionId, ...editedFields } },
            {
                onSuccess: () => {
                    setEditedFields({})
                    onEmailSubmit()
                },
            }
        )
    }
    const handleCancel = () => {
        setEditedFields({})
    }
    const handleDelete = () => {
        deleteTaxExemption.mutate(
            { taxExemptionId },
            {
                onSuccess: () => {
                    closeModal()
                },
            }
        )
    }

    const toggleAuditHistory = () => {
        setShowAuditHistory((prev) => !prev)
    }
    const toggleCreateTaskModal = () => {
        if (showCreateTaskModal) {
            searchParams.delete('showCreateTaskModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateTaskModal', 'true')
        setSearchParams(searchParams)
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                closeModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-50'
        >
            <div className='h-[95%] w-[95%] bg-bg1 dark:bg-darkbg1 max-w-[95%] max-h-[95%] p-6 rounded shadow-md relative'>
                {/* header section */}
                <div>
                    <button className='absolute top-1 right-1 text-lg' onClick={closeModal}>
                        <FaTimes className='text-red' />
                    </button>
                    <div className='flex gap-[10px] pb-1 border-b border-lightrey dark:border-darkgrey mb-2 min-h-[42px]'>
                        <div className='flex gap-[30px]'>
                            <h2 className='font-bold text-lg leading-tight flex flex-col'>
                                <button onClick={() => setShowAuditHistory(false)}>
                                    Tax Exemption: {taxExemptionId}
                                </button>
                            </h2>
                        </div>
                        {edited && (
                            <div className='flex gap-4 items-center'>
                                <FetchingSpinner isFetching={updateTaxExemption.isPending} />
                                <div
                                    className={cn(
                                        'flex gap-4',
                                        updateTaxExemption.isPending && 'opacity-60 pointer-events-none'
                                    )}
                                >
                                    <Button onClick={handleSave} variant={'outline'}>
                                        Save Changes
                                    </Button>
                                    <Button className='' onClick={handleCancel} variant={'outline'}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div className='flex'>
                            <div className='flex bg-white dark:bg-darkbg1 rounded dark:text-offwhite [&>*]:px-2 pb-2 [&>*]:text-sm [&>*]:border-r-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                                {taxExemptionQuery.isSuccess && (
                                    <>
                                        {!exemption.found_customer_id && (
                                            <div className='mt-[2px] text-red text-xs font-bold text-wrap dark:text-rgb(180 0 0 / var(--tw-text-opacity))'>
                                                <div className='flex gap-2 justify-center items-center'>
                                                    <div className=''>
                                                        <TbAlertTriangleFilled size={'30'} />
                                                    </div>
                                                    <div className='text-xs'>
                                                        <p>{'Customer information not populated!'}</p>
                                                        <p className=''>{'Refresh the page to try again.'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                                {exemption.calculated_full_name && (
                                    <div className='flex flex-col'>
                                        <h2 className='font-bold'>Customer</h2>
                                        <div className='flex justify-between gap-1'>
                                            <p
                                                className='text-blue dark:text-accent hover:underline cursor-pointer'
                                                onClick={() => {
                                                    window.open(`/customers?customer_id=${exemption.customer_id}`)
                                                }}
                                            >
                                                {exemption.calculated_full_name}
                                            </p>

                                            <button
                                                onClick={() => {
                                                    if (exemption.calculated_full_name) {
                                                        navigator.clipboard.writeText(exemption.calculated_full_name)
                                                        sendToast({
                                                            message: `Copied: ${exemption.calculated_full_name}`,
                                                        })
                                                    }
                                                }}
                                            >
                                                {' '}
                                                <MdContentCopy
                                                    title={`Copy name`}
                                                    className='cursor-pointer opacity-50 hover:opacity-100 duration-300'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {exemption.email && (
                                    <div className='flex flex-col'>
                                        <h2 className='font-bold'>Email</h2>
                                        <div className='flex justify-between gap-1'>
                                            <p className='text-blue dark:text-accent hover:underline cursor-pointer'>
                                                <a href={`mailto:${exemption.email}`}>{exemption.email}</a>
                                            </p>
                                            <button
                                                onClick={() => {
                                                    if (exemption.email) {
                                                        navigator.clipboard.writeText(exemption.email)
                                                        sendToast({ message: `Copied: ${exemption.email}` })
                                                    }
                                                }}
                                            >
                                                <MdContentCopy
                                                    title={`Copy email`}
                                                    className='cursor-pointer opacity-50 hover:opacity-100 duration-300'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {exemption.phone && (
                                    <div>
                                        <h2 className='font-bold'>Phone</h2>
                                        <div className='flex gap-1'>
                                            <button
                                                className='flex'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(exemption.phone)
                                                    sendToast({ message: `Copied: ${exemption.phone}` })
                                                }}
                                            >
                                                <p className='cursor-pointer'>{exemption.phone}</p>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    navigator.clipboard.writeText(exemption.phone)
                                                    sendToast({ message: `Copied: ${exemption.phone}` })
                                                }}
                                            >
                                                <MdContentCopy
                                                    title={`Copy phone number`}
                                                    className='cursor-pointer opacity-50 hover:opacity-100 duration-300'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='ml-4 w-fit gap-4 pr-4'>
                                <Button
                                    variant={'outline'}
                                    size={'sm'}
                                    type='button'
                                    onClick={() => setEmailMode('taxExemption')}
                                >
                                    Email Customer
                                </Button>
                                <div>
                                    <EmailModal
                                        eventSource={eventSource}
                                        mode={emailMode}
                                        setMode={setEmailMode}
                                        handleSubmitEmailEvent={onEmailSubmit}
                                        customer={exemption}
                                        order={exemption}
                                        handleCreateFollowUpTask={handleCreateFollowUpTask}
                                    />
                                </div>
                            </div>
                            <Button variant={'outline'} size={'sm'} onClick={toggleCreateTaskModal}>
                                {CREATE_TASK_BUTTON_CONTENT}
                            </Button>
                        </div>
                    </div>
                </div>

                <div>
                    {/* top three columns */}
                    <div className='gap-4 flex flex-col h-[50vh]'>
                        <div className='flex gap-4 min-h-1 h-full'>
                            <div className='w-2/3 overflow-y-auto'>
                                {/* left column */}
                                <div className='flex gap-4'>
                                    <div className='w-1/2'>
                                        <div className='mb-2 relative'>
                                            <h2 className='font-bold mb-2'>Exemption Details</h2>
                                            <div className='flex flex-col gap-4'>
                                                <Select
                                                    id='status'
                                                    name='status'
                                                    label='Status'
                                                    value={editedFields.status ?? exemption.status}
                                                    onChange={handleChange}
                                                >
                                                    <option value='UNDER_REVIEW'>Under Review</option>
                                                    <option value='WAITING_FOR_CUSTOMER'>Waiting For Customer</option>
                                                    <option value='REJECTED'>Rejected</option>
                                                    <option value='ACCEPTED'>Accepted</option>
                                                </Select>
                                                <TaxExemptionSelector
                                                    exemptionTypes={exemptionTypes}
                                                    setExemptionTypes={setExemptionTypes}
                                                />
                                            </div>
                                        </div>

                                        {showDelete ? (
                                            <div className='flex gap-4 absolute bottom-1 right-6 text-sm items-center'>
                                                <FetchingSpinner isFetching={deleteTaxExemption.isPending} />
                                                <div
                                                    className={cn(
                                                        'flex gap-2',
                                                        deleteTaxExemption.isPending && 'opacity-60 pointer-events-none'
                                                    )}
                                                >
                                                    <Button
                                                        variant={'outline'}
                                                        size={'sm'}
                                                        className='text-sm text-danger p-1 h-fit'
                                                        onClick={handleDelete}
                                                    >
                                                        Delete Tax Exemption
                                                    </Button>
                                                    <Button
                                                        className='text-sm p-1 h-fit'
                                                        variant={'outline'}
                                                        size={'sm'}
                                                        onClick={() => setShowDelete(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <button
                                                className='absolute bottom-2 right-8 text-lg'
                                                onClick={() => setShowDelete(true)}
                                            >
                                                <FaTrash className='text-red' />
                                            </button>
                                        )}
                                    </div>
                                    {/* middle column */}
                                    <div className='w-1/2'>
                                        {!taxFormsQuery.isSuccess && (
                                            <div>
                                                <div className='font-bold'>Files</div>
                                                <div className='opacity-50 mt-2'>
                                                    <SkeletonBasic className='w-full h-[120px]' />
                                                </div>
                                            </div>
                                        )}
                                        {taxFormsQuery.isSuccess && (
                                            <div>
                                                <TaxFormList
                                                    taxFormsQuery={taxFormsQuery}
                                                    taxExemptionId={taxExemptionId}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {taxExemptionAudit.length > 0 && (
                                    <div className='flex flex-col'>
                                        <Button
                                            className='w-fit mb-3'
                                            variant={'ghost'}
                                            onClick={() => toggleAuditHistory()}
                                        >
                                            <u>Exemption History</u>
                                        </Button>
                                        {showAuditHistory && (
                                            <TaxExemptionAudit taxExemptionAudit={taxExemptionAudit} />
                                        )}
                                    </div>
                                )}
                            </div>
                            {/* right column */}
                            <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] pl-1 pr-2 pb-2 grow shrink overflow-scroll col-span-1 lg:col-span-1 w-1/3'>
                                <h2 className='font-bold'>Sent Emails</h2>
                                {!taxExemptionEmailQuery.isSuccess && (
                                    <div className='opacity-50'>
                                        <SkeletonBasic className='w-full h-[320px]' />
                                    </div>
                                )}
                                {taxExemptionEmailQuery?.isSuccess && taxExemptionEmails?.emails?.length === 0 && (
                                    <div>
                                        <p>There are no sent emails related to this exemption</p>
                                    </div>
                                )}

                                {taxExemptionEmailQuery?.isSuccess && taxExemptionEmails?.emails?.length > 0 && (
                                    <div>
                                        <div
                                            className={cn(
                                                'mx-2 grid gap-[8px] border-l-[1px] border-grey dark:border-darkgrey'
                                            )}
                                        >
                                            {filteredExemptionEmails?.map((e: any, i: number) => {
                                                return (
                                                    <div
                                                        key={exemption.id + e.id}
                                                        className='flex gap-[0] items-center'
                                                    >
                                                        <div className='relative left-[-14px] flex items-center justify-center text-[12px] min-w-[28px] min-h-[28px] border-[4px] border-white dark:border-darkaccent bg-white dark:bg-darkaccent basis-[1]'>
                                                            <div className='absolute min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent z-index-1'>
                                                                <span
                                                                    key={e.id + e.normalized_event_index}
                                                                    className='block text-center font-semibold min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent border-[1px] border-grey dark:border-accent rounded-full px-[2px]'
                                                                >
                                                                    {e.normalized_event_index}
                                                                </span>
                                                            </div>
                                                            <div className='absolute left-[20px] bg-grey dark:bg-accent h-[1px] w-[12px]' />
                                                        </div>
                                                        <Email
                                                            email={e}
                                                            attachments={emailAttachments}
                                                            isPending={taxExemptionEmailQuery.isLoading}
                                                        />
                                                        {/* <TaskLatestActivity index={e.normalized_event_index} event={e} /> */}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* task section */}
                    <div className='min-h-0'>
                        {/* <div className='flex gap-2'>
                            <Button
                                onClick={toggleCreateTaskModal}
                                size='sm'
                                variant='outline'
                                className='h-fit p-1 leading-1'
                            >
                                {CREATE_TASK_BUTTON_CONTENT}
                            </Button>
                        </div> */}
                        <h2 className='font-bold'>Tasks</h2>
                        {tasksQuery.isSuccess && tasks.length === 0 && (
                            <p>There are no tasks related to this exemption</p>
                        )}
                        <div className='overflow-auto h-[25vh]'>
                            <div className='flex flex-col p-2'>
                                {tasks
                                    ?.filter((task: any) => task.status === 'To Do')
                                    .map((task: any) => {
                                        return <TaskEditCreateModalV2 createEvent={false} taskId={task.id} />
                                    })}
                            </div>
                            <div className='flex flex-col p-2'>
                                {tasks.map((task: any) => {
                                    return <TaskEditCreateModalV2 createEvent={false} taskId={task.id} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showCreateTaskModal && (
                <CreateTaskModal
                    closeModal={toggleCreateTaskModal}
                    prePopulatedValues={{
                        department_id: accountingTaskDepartment?.id,
                        associations: [
                            {
                                resource_id: user.id,
                                resource_name: 'user',
                                readable_name: `${user.firstName} ${user.lastName}`,
                            },
                            {
                                resource_id: taxExemptionId,
                                resource_name: 'customer_tax_exemption',
                                readable_name: `${exemption.calculated_full_name || 'Customer'} TE`,
                            },
                        ],
                    }}
                />
            )}
        </div>
    )
}
