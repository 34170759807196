import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const users = createQueryKeys('users', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getUsers(),
    }),
})

export const usersQueries = mergeQueryKeys(users)
