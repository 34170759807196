import { useState } from 'react'
import { DEFAULT_PERMISSION_SET } from './constants'
import Input from 'procurement/components/Input'
import Textarea from 'procurement/components/Textarea'
import { Button } from 'custom_components/component_Basics/Button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import { FaTimes } from 'react-icons/fa'

export default function CreateRoleModal({ closeModal }: { closeModal: () => void }) {
    const [role, setRole] = useState({
        title: '',
        permission_set: JSON.stringify(DEFAULT_PERMISSION_SET, null, 2),
    })
    const queryClient = useQueryClient()
    const createRoleMutation = useMutation({
        mutationFn: (role: { title: string; permission_set: string }) =>
            vFetch('/v2/roles', {
                method: 'POST',
                body: JSON.stringify({
                    title: role.title,
                    permission_set: JSON.parse(role.permission_set),
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
    })
    const handleCreate = () => {
        createRoleMutation.mutate(role, {
            onSuccess: () => closeModal(),
        })
    }
    const edited = role.title !== '' && role.permission_set !== JSON.stringify(DEFAULT_PERMISSION_SET, null, 2)
    return (
        <div className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center'>
            <div className='p-6 flex min-h-0 flex-col rounded shadow-md relative bg-bg1 dark:bg-darkbg1 w-full h-full max-w-[90%] max-h-[90%]'>
                <button
                    className='text-red dark:text-lightred font-bold absolute text-lg top-1 right-1'
                    onClick={closeModal}
                >
                    <FaTimes />
                </button>
                <div className='flex justify-between'>
                    <h2 className='font-bold'>Create New Role</h2>
                    <Button onClick={handleCreate} size='sm' variant='outline' disabled={!edited}>
                        + Create Role
                    </Button>
                </div>
                <Input
                    id='title'
                    name='title'
                    type='text'
                    label='Role Name'
                    value={role.title}
                    onChange={({ target }) => setRole({ ...role, title: target.value })}
                />
                <Textarea
                    outerClassName='mt-1'
                    className='h-full max-h-none'
                    id='permission_set'
                    name='permission_set'
                    label='Permissions'
                    value={role.permission_set}
                    onChange={({ target }) => setRole({ ...role, permission_set: target.value })}
                />
            </div>
        </div>
    )
}
