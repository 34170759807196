import { ExclamationTriangleIcon, ExternalLinkIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import RefreshButton from 'custom_components/RefreshButton'
import { getDateToday } from 'procurement/constants'
import { formatDate, formatDateSimple } from 'procurement/helpers'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'
import { useGetLeadTimes } from 'productSetupV2/api/useQueries'
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { BsClockHistory } from 'react-icons/bs'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { cn } from '../../helpers'
import InventoryLeadTimesSearchBarContainer from './InventoryLeadTimesSearchBarContainer'
import LeadTimeDetailCreateModal from './LeadTimeDetailCreateModal'
import LeadTimeDetailModal from './LeadTimeDetailModal'
import LeadTimeTable from './LeadTimeTable'
import { differenceInCalendarDays } from 'date-fns'

export type LeadTime = {
    id: number
    title: string
    sku: string
    product_id: number
    variant_id: number
    status: string
    quantity: number
    created_by: string
    available_at: any
    created_at: any
    store_id: number
    related_lead_times: any
    preorder_days: number
    custom_ignore_auto_updates: number
    inventory_policy: 'deny' | 'continue'
}

export default function InventoryLeadTimes() {
    const params = useParams()
    const leadTimeId = params.leadTimeId
    const [searchParams, setSearchParams] = useSearchParams()
    const [search, setSearch] = useState('')
    const location = useLocation()
    const [initRender, setInitRender] = useState(true)

    const after = searchParams.get('after')
    const before = searchParams.get('before')
    const searchParam = searchParams.get('search') || ''
    const page = searchParams.get('page') || 1
    const vendorsString = searchParams.get('vendors') || ''
    const statusesString = searchParams.get('status') || ''

    const vendors = decodeURIComponent(vendorsString)
        .split(',')
        .filter((v) => v)

    const statuses = decodeURIComponent(statusesString)
        .split(',')
        .filter((v) => v)

    const leadTimesQuery = useGetLeadTimes({
        filters: { vendors, statuses, after, before, page },
        search,
    })
    const { leadTimes }: { leadTimes: LeadTime[] } = leadTimesQuery?.data || {}

    function sortSoonestFirst(a: any, b: any) {
        return new Date(a.available_at).getTime() - new Date(b.available_at).getTime()
    }

    const sortedLeadTimes = leadTimes?.length > 1 ? [...leadTimes]?.sort(sortSoonestFirst) : leadTimes
    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            search ? searchParams.set('search', search) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
        }
    }, [search])
    useEffect(() => {
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    function handlePageIncrease() {
        if (leadTimes?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.set('after', leadTimes[49].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: any) => {
                prev.delete('after')
                prev.set('before', leadTimes[0].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('before')
                prev.set('page', '1')
                return prev
            })
        }
    }

    const tableData = leadTimes?.length > 0 ? sortedLeadTimes : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<LeadTime>()

    const leadTimeColumns = [
        {
            header: ' ',

            cell: ({ row }: { row: any }) => {
                let status = row.original.status
                const available_at = row.original.available_at
                if (
                    available_at.split('T')[0] <= getDateToday().toISOString().split('T')[0] &&
                    status !== 'Completed'
                ) {
                    status = 'Due'
                }
                return (
                    <div className='flex justify-center'>
                        {status === 'Due' ? (
                            <ExclamationTriangleIcon className=' text-lightred w-[24px] h-[24px]' />
                        ) : status == 'Completed' ? (
                            <IoMdCheckmarkCircle className=' text-lime w-[24px] h-[24px]' />
                        ) : (
                            <BsClockHistory className=' text-fire w-[24px] h-[24px]' />
                        )}
                    </div>
                )
            },
            size: 10,
        },

        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => {
                let status = info.row.original.status
                const available_at = info.row.original.available_at
                const acceptingOrders =
                    info.row.original.status === 'Pending' &&
                    info.row.original.inventory_policy === 'continue' &&
                    differenceInCalendarDays(info.row.original.available_at, new Date()) <
                        info.row.original.preorder_days

                const ignoreAutoUpdates = info.row.original.custom_ignore_auto_updates === 0

                if (
                    available_at.split('T')[0] <= getDateToday().toISOString().split('T')[0] &&
                    status !== 'Completed'
                ) {
                    status = 'Due'
                }
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn('flex flex-col gap-1 justify-center')}
                    >
                        <p>{status}</p>
                        <p>
                            {acceptingOrders
                                ? !ignoreAutoUpdates
                                    ? `(Ignoring auto updates)`
                                    : '(Pre-orders allowed)'
                                : ''}
                        </p>
                    </Link>
                )
            },
            size: 200,
        }),

        columnHelper.accessor('title', {
            header: 'Title',
            cell: (info) => {
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn('flex gap-1 items-center')}
                    >
                        {info.getValue()}
                    </Link>
                )
            },
            size: 200,
        }),

        columnHelper.accessor('sku', {
            header: 'SKU',
            cell: (info) => {
                return (
                    <div className='flex gap-2'>
                        <Link
                            draggable={false}
                            to={`/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`}
                            state={{ previousLocationPathname: location.pathname }}
                            className={cn('flex gap-1 items-center')}
                        >
                            {info.getValue()}
                        </Link>
                        <Link
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            target='_blank'
                            rel='noopener noreferrer'
                            draggable={false}
                            to={`/products/${info.row.original.product_id}`}
                            state={{ previousLocationPathname: location.pathname }}
                            className={cn('flex gap-1 items-center')}
                        >
                            <ExternalLinkIcon />
                        </Link>
                    </div>
                )
            },
            size: 175,
        }),
        columnHelper.accessor('product_id', {
            header: 'Product Id',
            cell: (info) => {
                const variantId = info.row.original.variant_id
                const productId = info.row.original.product_id
                return (
                    <div className='flex gap-2'>
                        <Link
                            draggable={false}
                            to={`/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`}
                            state={{ previousLocationPathname: location.pathname }}
                            className={cn('flex gap-1 items-center')}
                        >
                            {productId}
                        </Link>
                        <Link
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            draggable={false}
                            target='_blank'
                            rel='noopener noreferrer'
                            to={`https://admin.shopify.com/store/factorypure/products/${productId}/variants/${variantId}`}
                            state={{ previousLocationPathname: location.pathname }}
                            className={cn('flex gap-1 items-center')}
                        >
                            <ExternalLinkIcon />
                        </Link>
                    </div>
                )
            },
            size: 250,
        }),

        columnHelper.accessor('quantity', {
            header: 'Quantity',
            cell: (info) => {
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn(' flex gap-1 items-center')}
                    >
                        {info.getValue()}
                    </Link>
                )
            },
            size: 160,
        }),
        columnHelper.accessor('available_at', {
            header: 'Available',
            cell: (info) => {
                const daysToRestock = differenceInCalendarDays(info.row.original.available_at, new Date())
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn(' flex flex-col gap-1 justify-center')}
                    >
                        <p>{formatDateSimple(new Date(info.getValue().replace('Z', '')))}</p>
                        <p>{`(${daysToRestock} days)`}</p>
                    </Link>
                )
            },
            size: 160,
        }),
        columnHelper.accessor('created_by', {
            header: 'Source',
            cell: (info) => {
                const createdByArray = info.row.original.created_by.split(':')

                const emailId =
                    createdByArray[0] === 'Inv. Email'
                        ? createdByArray[1].slice(0, createdByArray[1].indexOf('('))
                        : undefined
                return (
                    <Link
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        draggable={false}
                        to={
                            emailId
                                ? `/products/inventory-emails/${emailId}`
                                : `/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`
                        }
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn('w-full flex gap-1 items-center')}
                    >
                        <Button
                            className={cn(
                                'w-full px-2 transition-none',
                                emailId && 'hover:bg-neutral-300 transition-colors'
                            )}
                            variant={'ghost'}
                            size={'default'}
                        >
                            {info.getValue()}
                        </Button>
                    </Link>
                )
            },
            size: 160,
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: (info) => {
                const formattedDate = formatDate(new Date(info.getValue()))
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-lead-times/${info.row.original.id}?${searchParams.toString()}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn(' flex flex-col gap-1 justify-center')}
                    >
                        <p>
                            {formattedDate.split(',')[0]},{formattedDate.split(',')[1]},
                        </p>
                        <p>{formattedDate.split(',')[2]}</p>
                    </Link>
                )
            },
            size: 160,
        }),
    ]
    const columnCount = leadTimeColumns?.length.toString() || 10

    return (
        <div>
            {leadTimeId && leadTimeId == '0' && <LeadTimeDetailCreateModal leadTimeId={leadTimeId} />}
            {leadTimeId && leadTimeId != '0' && (
                <LeadTimeDetailModal
                    leadTimeId={leadTimeId}
                    filters={{ vendors, statuses, after, before, page }}
                    search={search}
                />
            )}
            <div className='flex justify-between mb-2'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Lead Times</h1>
                <div className='flex gap-4 items-center'>
                    <Link
                        draggable={false}
                        to={`/products/inventory-lead-times/${0}?${searchParams.toString()}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn(' flex gap-1 items-center')}
                    >
                        <Button variant={'outline'} size={'sm'}>
                            New Lead Time
                        </Button>
                    </Link>
                    <RefreshButton
                        queryKeys={[productSetupQueries.leadTimes._def.toString()]}
                        isFetching={leadTimesQuery.isFetching}
                    />
                </div>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <InventoryLeadTimesSearchBarContainer
                    setDebouncedSearch={setSearch}
                    isFetching={leadTimesQuery.isFetching}
                />
                <>
                    <div className='w-full'>
                        {tableDataMemo && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <LeadTimeTable
                                    columns={leadTimeColumns}
                                    tableData={tableDataMemo}
                                    columnCount={columnCount}
                                    width={'w-full'}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={leadTimesQuery.isLoading || leadTimesQuery.isFetching || page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={leadTimesQuery.isLoading || leadTimesQuery.isFetching || page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={leadTimesQuery.isLoading || leadTimesQuery.isFetching || leadTimes?.length < 50}
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            </div>
        </div>
    )
}
